.partner-form{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0rem 0rem;
}

.partner-growth{
    height:auto;
    width: 60rem;
    background-color:#fff;
    padding: 2.6rem 0rem ;
    border-radius: 0.5rem;
    box-shadow: 0px 15px 38px rgba(0, 0, 0, 0.1);
 
}

.partner-growth h1{
    font-size: 1.5rem;
    color: #1c2045;
    padding: 0rem 1.8rem 1.5rem;
}

.form-data1{
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 1rem;
    padding: 1.6rem 2rem 0rem;
    flex-direction: column;
}


.form-data1 input{
  height: 2.9rem;
  width: 100%;
  padding: 0rem 0rem 0rem 0.5rem;
  border-radius: 0.3rem;
  border: 1px solid rgba(0, 0, 0, .2);
  font-size: 14px;
}

.address-bar{
    flex-direction: column;
  display: flex;
  justify-content: center;
  padding: 1.6rem 2rem;
  font-size: 14px;
}

.address-bar textarea{
    margin-bottom: 1rem;
    padding: 0.5rem 0rem 0rem 0.5rem;
    border-radius: 0.3rem;
    border: 1px solid rgba(0, 0, 0, .2);

}
.in-fields{
    flex-direction: column;
    display: flex;
}
.sub-button button{
    height: 3.3rem;
    margin-top: 1rem;
    width: 9rem;
    border-radius: 0.3rem;
    background-image: linear-gradient(to right, rgb(16, 175, 16),rgb(165, 235, 122));
    border: none;
    font-size: 1.2rem;
    color: #ffffff;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0px 15px 38px rgba(0, 0, 0, 0.1);
}

.formline{
  margin: auto;
   width: 57rem;

}

.sub-button{
    padding: 0rem 2rem;
}

.success-animation {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    animation: fade-in-out 5s ease-in-out; /* Adjust the animation duration as needed */
  }
  
  .success-box {
    background-color: #4CAF50;
    color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  @keyframes fade-in-out {
    0%, 100% {
      opacity: 0;
    }
    25%, 75% {
      opacity: 1;
    }
  }
  

@media(min-width:200px) and (max-width:349px){
    .partner-form{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.8rem 1rem 0rem;
        
    }
    
    .partner-growth{
        height:auto;
        width:100%;
        background-color:#fff;
        padding: 1.5rem 1rem ;
        border-radius: 0.5rem;
        box-shadow: 0px 15px 38px rgba(0, 0, 0, 0.1);
     
    }
    
    .partner-growth h1{
        font-size: 1.5rem;
        color: #1c2045;
        padding: 0rem 1.8rem 1rem;
    }
    
    .form-data1{
       
        flex-direction: column;
        padding: 1rem 0rem 0rem;
    }
    
    
    .form-data1 input{
      height: 2.9rem;
      width: 100%;
      padding: 0rem 0rem 0rem 0.5rem;
      border-radius: 0.3rem;
      border: 1px solid rgba(0, 0, 0, .2);
      font-size: 14px;
    }
    
    .address-bar{
      display: flex;
      justify-content: center;
      padding: 1rem 0rem;
      font-size: 14px;
    }
    
    .address-bar textarea{
        padding: 0.5rem 0rem 0rem 0.5rem;
        border-radius: 0.3rem;
        border: 1px solid rgba(0, 0, 0, .2);
    
    }
    
    .sub-button button{
        height: 3.3rem;
        width: 9rem;
        border-radius: 0.3rem;
        background-image: linear-gradient(to right, rgb(16, 175, 16),rgb(165, 235, 122));
        border: none;
        font-size: 14px !important;
        color: #ffffff;
        font-weight: 600;
        cursor: pointer;
        box-shadow: 0px 15px 38px rgba(0, 0, 0, 0.1);
    }
    
    .formline{
      margin: auto;
       width: 100%;
     
    }
    
    .sub-button{
        display: flex;
        justify-content: start;
        align-items: start;
        padding: 0rem 0rem;
    }
}

@media(min-width:349px) and (max-width:499px){
    .partner-form{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.8rem 1rem 0rem;
        
    }
    
    .partner-growth{
        height:auto;
        width:100%;
        background-color:#fff;
        padding: 1.5rem 1rem ;
        border-radius: 0.5rem;
        box-shadow: 0px 15px 38px rgba(0, 0, 0, 0.1);
     
    }
    
    .partner-growth h1{
        font-size: 1.5rem;
        color: #1c2045;
        padding: 0rem 1.8rem 1rem;
    }
    
    .form-data1{
       
        flex-direction: column;
        padding: 1rem 0rem 0rem;
    }
    
    
    .form-data1 input{
      height: 2.9rem;
      width: 100%;
      padding: 0rem 0rem 0rem 0.5rem;
      border-radius: 0.3rem;
      border: 1px solid rgba(0, 0, 0, .2);
      font-size: 14px;
    }
    
    .address-bar{
      display: flex;
      justify-content: center;
      padding: 1rem 0rem;
      font-size: 14px;
    }
    
    .address-bar textarea{
        width: 100%;
        padding: 0.5rem 0rem 0rem 0.5rem;
        border-radius: 0.3rem;
        border: 1px solid rgba(0, 0, 0, .2);
    
    }
    /* #mobile{
        width: 19rem !important;
    } */
    
    .sub-button button{
        height: 3.3rem;
        width: 9rem;
        border-radius: 0.3rem;
        background-image: linear-gradient(to right, rgb(16, 175, 16),rgb(165, 235, 122));
        border: none;
        font-size: 1.2rem;
        color: #ffffff;
        font-weight: 600;
        cursor: pointer;
        box-shadow: 0px 15px 38px rgba(0, 0, 0, 0.1);
    }
    
    .formline{
      margin: auto;
       width: 100%;
     
    }
    
    .sub-button{
        display: flex;
        justify-content: start;
        align-items: start;
        padding: 0rem 0rem;
    }
}


@media(min-width:499px) and (max-width:768px){
    .partner-form{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.8rem 1rem 0rem;
        
    }
    
    .partner-growth{
        height:auto;
        width:100%;
        background-color:#fff;
        padding: 1.5rem 1rem ;
        border-radius: 0.5rem;
        box-shadow: 0px 15px 38px rgba(0, 0, 0, 0.1);
     
    }
    
    .partner-growth h1{
        font-size: 1.5rem;
        color: #1c2045;
        padding: 0rem 1.8rem 1rem;
    }
    
    .form-data1{
       
        flex-direction: column;
        padding: 1rem 0rem 0rem;
    }
    
    
    .form-data1 input{
      height: 2.9rem;
      width: 100%;
      padding: 0rem 0rem 0rem 0.5rem;
      border-radius: 0.3rem;
      border: 1px solid rgba(0, 0, 0, .2);
      font-size: 14px;
    }
    
    .address-bar{
      display: flex;
      justify-content: center;
      padding: 1rem 0rem;
      font-size: 14px;
    }
    
    .address-bar textarea{
        padding: 0.5rem 0rem 0rem 0.5rem;
        border-radius: 0.3rem;
        border: 1px solid rgba(0, 0, 0, .2);
    
    }
    
    .sub-button button{
        height: 3.3rem;
        width: 9rem;
        border-radius: 0.3rem;
        background-image: linear-gradient(to right, rgb(16, 175, 16),rgb(165, 235, 122));
        border: none;
        font-size: 1.2rem;
        color: #ffffff;
        font-weight: 600;
        cursor: pointer;
        box-shadow: 0px 15px 38px rgba(0, 0, 0, 0.1);
    }
    
    .formline{
      margin: auto;
       width: 100%;
     
    }
    
    .sub-button{
        display: flex;
        justify-content: start;
        align-items: start;
        padding: 0rem 0rem;
    }
}

@media(min-width:769px) and (max-width:1200px){
    .partner-form{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4rem 7rem 0rem;
    }
    
    .partner-growth{
        height:auto;
        width: 100%;
        background-color:#fff;
        padding: 2.6rem 1rem ;
        border-radius: 0.5rem;
        box-shadow: 0px 15px 38px rgba(0, 0, 0, 0.1);
     
    }
    
    .partner-growth h1{
        font-size: 1.5rem;
        color: #1c2045;
        padding: 0rem 1.8rem 1.5rem;
    }
    
    .form-data1{
       
        gap: 1rem;
        padding: 1.6rem 0rem 0rem;
    }
    
    
    .form-data1 input{
      height: 2.9rem;
      width: 100%;
      padding: 0rem 0rem 0rem 0.5rem;
      border-radius: 0.3rem;
      border: 1px solid rgba(0, 0, 0, .2);
      font-size: 14px;
    }
    
    .address-bar{
      display: flex;
      justify-content: center;
      padding: 1.6rem 0rem;
      font-size: 14px;
    }
    
    .address-bar textarea{
        width: 100%;
        padding: 0.5rem 0rem 0rem 0.5rem;
        margin: 0rem 2rem 0rem 0rem;
        border-radius: 0.3rem;
        border: 1px solid rgba(0, 0, 0, .2);
        margin-bottom: 1rem;
    
    }
    
    .sub-button button{
        height: 3.3rem;
        width: 9rem;
        border-radius: 0.3rem;
        background-image: linear-gradient(to right, rgb(16, 175, 16),rgb(165, 235, 122));
        border: none;
        font-size: 1.2rem;
        color: #ffffff;
        font-weight: 600;
        cursor: pointer;
        box-shadow: 0px 15px 38px rgba(0, 0, 0, 0.1);
    }
    
    .formline{
      margin: auto;
       width: 100%;
    }
    
    .sub-button{
        display: flex;
        justify-content:start;
        padding: 0rem 0rem;
    }
     
}