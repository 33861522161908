* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Global Styles */
.modal-form-main {
  padding: 1rem;
}

.modal-api {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: auto;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px black;
  padding: 1rem;
}

.modal-fields-d {
  height: 43px !important;
}

#modal-content-1 {
  display: flex !important;
  gap: 1rem !important;
  padding: 1rem;
}

.modal-apply {
  font-size: 30px;
}

.modal {
  --bs-modal-width: 75% !important;
  padding-left: 0px !important;
  top: 100px !important;
}

.error-message {
  color: red;
  font-size: 12px;
}

.error-text {
  font-size: 12px;
}

.invalid-feedback {
  margin: -10px 5px 5px 0px !important;
}

.error-border {
  border: 1px solid red;
}

/* Main Container Styles */
.SuperMainContainer {
  display: flex;
  background-color: pink;
}

/* Modal Styles */
.modal-content {
  border-radius: 10px;
}

.modal-header {
  background-color: #2dc961;
  color: white;
  border-radius: 10px 10px 0 0;
}

.modal-body {
  padding: 40px 20px;
  
}
.modal-body2{
  padding: 40px 20px;
    display: flex !important;
    align-items: center !important ;
    justify-content: center !important;
    margin:-6rem auto !important;
}
.video{
  border: none;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  border-radius: 5px;
}

#formMobile {
  margin: 15px 0px;
  height: 45px !important;
}

.applybtn {
  border: none;
  width: 10rem !important;
  height: 3rem !important;
  border-radius: 5px !important;
  font-weight: 500;
  color: #ffffff;
  font-size: 1.05rem !important;
  line-height: 1.65rem !important;
  letter-spacing: 0px;
  margin-top: 1rem;
  word-spacing: -0.5px;
  background: linear-gradient(90deg, rgba(0, 187, 57, 1) 0%, rgba(103, 198, 43, 1) 90%);
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
      animation: shake 0.5s ease-in-out infinite;
  }
}

@keyframes shake {
  0%, 100% {
      transform: translateX(0);
  }
  25%, 75% {
      transform: translateX(-5px);
  }
  50% {
      transform: translateX(5px);
  }
}

/* Media Queries */
@media (min-width: 250px) and (max-width: 349px) {
  .modal-title {
      font-size: 12px;
  }
  .modal-body2 {
    padding: 40px 0px;
  }

  .modal-header .btn-close {
      font-size: 10px;
  }

  .invalid-feedback {
      margin: -12px 1px -4px 0px !important;
      font-size: 10px;
  }

  #formMobile {
      font-size: 11px !important;
      margin: 10px 0px !important;
  }

  .modal-body {
      padding: 0px 10px 12px;
  }
}

@media (min-width: 349px) and (max-width: 499px) {
  .modal {
      --bs-modal-width: 75% !important;
      padding-left: 0px !important;
      top: 40px !important;
  }

  .modal-title {
      font-size: 18px;
  }
  .modal-body2 {
    padding: 40px 0px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: -6rem auto !important;
}
}

@media (min-width: 499px) and (max-width: 768px) {
  .modal {
      --bs-modal-width: 75% !important;
      padding-left: 0px !important;
      top: 60px !important;
  }
  .modal-body2{
    padding: 40px 0px !important;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .modal {
      --bs-modal-width: 75% !important;
      padding-left: 0px !important;
      top: 96px !important;
  }
}

/* Add more media queries as needed */
