*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html,body{
    height: 100%;
    width: 100%;
    font-family: 'Open Sans', Helvetica, sans-serif;
}
.mainsection{
    background-image: url('../components//Images//webback\ \(1\).webp');
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    gap: 5rem;
    
}
.sub-section1{
    height: 30rem;
    width: 29rem;
   
}
.carousel-control-next, .carousel-control-prev {
    width: 15%;
}
.botimg{
    height: 26rem;
    width: 33rem;
}
.whatapi{
    height: 26rem;
    width:33rem; 
}


.time-headeing h2{
    font-size: 2.9rem;
    padding: 1.3rem 0rem 0rem 1rem;
    width: 35vw;
    font-weight: 700;
    line-height: 50px;
    color: #303030;
    margin-bottom: 1.5rem;
    letter-spacing: 1px;
}
.time-headeing span{
    color: #42B860;
}
.facingtext span{
    font-size: 21px;
    font-weight: 700;
    line-height: 20px;
    color: #303030;
    padding: 1rem 0rem 0rem 1rem;
}
.Email-text {
    margin-top: 1rem;
}
.Email-text p{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0rem;
    padding: 0rem 0.2rem 0rem;
    width: 35vw;
}
.free-text p{
    font-size: 18px;
color: #3d3d3d;
    padding: 1rem 1rem 1rem;
}
.free-text span{
    font-size: 16px;
    font-weight: 550;
    color: #3d3d3d;
}
.dot{
    font-size: 25px;
    color: green;
    margin: 0rem 0rem .3rem 0rem;
}
.start-btn button{
    animation: pulse 1s infinite;
    margin: 1rem 0rem 0rem  1rem;
    height: 3rem;
    width: 16rem;
    font-size: 15px;
    background-color: #03cf65;
    color: aliceblue;
    border: none;
    border-radius: .3rem;
    box-shadow: 0 2px 4px 0 rgba(136,144,195,0.2), 0 5px 15px 0 rgba(37,44,97,0.15);
    font-weight: 600;
    cursor: pointer;
}
.apiheading h2{
    font-size: 2.2rem;
    margin-bottom: 0.5em;
    margin-top: 3.5em;
    font-weight: 700;
    line-height: 1.3;
    color: #303030;
    text-align: center;
    padding: 0rem 28rem;
    letter-spacing: 1px;
    animation: slide 1s ease, beast 2s infinite;
}
.message-text{

    padding: 0rem 17rem;
    display: flex;
    justify-content:center;
    align-items: start;
    flex-direction: column;
   
}
.message-text span{
    text-align:start;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: .5px;
    margin-bottom: 1rem;
    width: 57vw;
    
}
.api-buisness{
    padding-bottom: 4rem;
}
.message-text p{
    font-size: 18px;
    color: #3d3d3d;
    font-weight: 700;
}
.api-buisness h2{
    font-size: 2.3rem;
    font-weight: 700;
    text-align: center;
    margin-top: 3rem;

}
.api-smalltext span{
   
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 2rem;
}
.three-boxe{
  display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 0rem 1rem 0rem;
    
}
.api-box{
    margin: auto;
    height: auto;
    padding: 1rem 0rem;
    width: 720px;
    background-color: rgb(254, 254, 254);
    box-shadow: 0 2px 4px 0 rgba(136,144,195,0.2), 0 5px 20px 0 rgba(37,44,97,0.15);

}
.api-boxs{
    width: 30rem;
    margin-top: 8rem;
}
.api-box1{
    width: 30rem;
    margin-bottom: 2rem;
    margin-top: 0.5rem;
}
.api-box1 h3{
    font-size: 16px;
    color: black;
    font-weight: 500;
}
.api-box1 p{
    font-size: 18px;
}

.three-text{
    display: flex;
    justify-content: center;
    gap: 10rem;
    margin: 1rem 1rem;
   
}
.three-boxes{
    /* flex-direction: column; */
}
.t1{
   margin: 1rem; 
   display: flex; 
   justify-content:start; 
  
}
.t1 span{
    margin: 0rem .5rem;
    font-size: 14px;
}
.t2{
    margin: .8rem;
    display: flex;
}
.t2 span{
    margin: 0rem .5rem;
    font-size: 14px;
}
.t0{
    margin: 1rem;
}
.t0 span{
letter-spacing: .4px;
font-size: 14px;
}
.cr1{
    font-size: 27px;
   color: red;
}
.cr{
font-size: 17px;
color: red;
}

.cr2{
    font-size: 22px;
    color: red;
    }
.ri{
    font-size: 17px;
    color: green;
}

.ri1{
    font-size: 21px;
    color: green;
}
.apply-btn{
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}
.apply-btn button{

    height: 2.5rem;
    width: 9rem;
    color: #03cf65;
    border: 2px solid #03cf65;
    border-color: #03cf65;
    font-size: 15px;
    font-weight: 700;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(136,144,195,0.2), 0 5px 15px 0 rgba(37,44,97,0.15);
    
}

.kick{
    display: flex;
    justify-content: center;
    padding: 0rem 0rem .5rem;
}
.whats-business{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;
}

.rocket-img{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 0rem .5rem;
}

.rocket{
    height: 3rem;
    width: 3rem;
}

.dot-text{
    display: flex;
}


#dot{
    font-size: 2rem;
    color: #03cf65;
}

@media screen and (min-width:250px) and (max-width:349px) {
    .botwala-slide{
        display: none;
    }
    .three-boxe{
        flex-direction: column;
        gap: 0rem;
    }
   
    .sub-section1{
        height: 30rem;
        width: 100%;
       
    }
    .api-boxs {
        width: 100%;
        margin: 0rem;
    }
    .cr1{
        font-size: 18px;
    }
    .message-text  span{
        width: 80vw;
        color: black;
        font-size: 13px;
        line-height: 23px;
        font-weight: 400;
    }
    .message-text {
        padding: 0rem 1rem;
    }
    .apiheading h2 {
        padding: 0rem 10px;
        margin-top: .5em;
        font-size: 23px;
    }
    .facingtext span {
        display: flex;
        justify-content: center;
        width: 100vw;
        text-align: center;
        padding: 1rem 1rem 0rem 1rem;
    }
    .time-headeing h2 {
        margin-bottom: .5rem;
        font-size: 1.5rem;
        padding: 1rem 0rem 0rem 0rem;
        width: 100vw;
        text-align: center;
        line-height: 25px
    }
    .message-text p {
        text-align: center;
        font-size: 13px;
    }
    .api-buisness {
        padding: 4rem 1rem 1rem;
    }
    .Email-text p {
        width: 99vw;
        text-align: center;
        font-size: 13px;
    }
    .carousel slide{
        display: none;
    }
    .kick {
        display: flex;
        justify-content: center;
    }
    .rocket-img {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .whats-business {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sub-section1 {
        width: 100%;
        height: auto;
    }
    .sub-section2 iframe {
        width: 100%;
        height: 15rem;
    }
    .free-text p {
        width: 100vw;
        text-align: center;
        padding: 1rem 1rem 0rem;
    }
    .api-buisness h2 {
        margin-top: -3rem;
        font-size: 23px;
    }
    .api-box1 p {
        font-size: 16px;
        line-height: 18px;
    }
    .threebox {
        margin-top: -0.5rem;
    }
    .t1 {
        margin: .5rem 0rem;
    }
    .t1 span{
        padding: 0rem 1rem 0rem 0rem;
        width: 80vw;
        font-size: 14px;
    }
    .api-box1 h3 {
        font-size: 18px;
        line-height: 17px;
    }
    .api-box{
        margin: auto;
        height: auto;
        width: 100%;
    }
    .t2 span {
        font-size: 14px;
    }
    .three-box {
        flex-direction: column;
    }
    
    .start-btn{
        display: flex;
        justify-content: center;
        padding: 0rem 2rem;
    }
    .start-btn button {
        margin: 1rem 0rem 0rem;
        width: 100%;
        padding: 0rem .5rem;
        font-size: 14px;
    }
    .mainsection{
        /* display: flex;
        justify-content: center;
        align-items: center; */
        flex-direction: column;
        margin: 0rem 0rem;
        gap: 0rem;
        padding-bottom: 0rem;
    }
    .whatapi {
        height: auto;
        width: 100%;
        padding: 0rem 1rem;
    }
    .api-box1 {
        width: 100%;
    }
}
@media screen and (min-width:349px) and (max-width:499px) {

    .botimg{
        height: 5rem;
        width: 10rem;
    }

    .whatapi{
            height: auto;
            width: 100%;
            padding: 0rem 1rem;
        
    }
    
    .sub-section1{
        height: 20rem;
        width: 20rem;
    }    

    .three-boxe{
        flex-direction: column;
        gap: 0rem;
    }
   
    .sub-section1{
        height: 30rem;
        width: 100%;
       
    }
    .api-boxs {
        width: 100%;
        margin: 0rem;
    }
    .cr1{
        font-size: 18px;
    }
    .message-text  span{
        width: 88vw;
        font-size: 15px;
        padding: 0rem .5rem;
    }
    .message-text {
        padding: 0rem 1rem;
    }
    .apiheading h2 {
        padding: 0rem 0rem 0rem;
        margin-top: .5rem;
    }
    .facingtext span {
        display: flex;
        justify-content: center;
        width: 100vw;
        text-align: center;
        padding: 1rem 0.5rem 0rem;
    }
    .time-headeing h2 {
        margin-bottom: .5rem;
        font-size: 1.5rem;
        padding: 1rem .5rem 0rem;
        width: 100vw;
        text-align: center;
        line-height: 25px
    }
    .message-text p {
        text-align: start;
        width: 88vw;
        font-size: 16px;
        padding: 0rem .5rem;
    }
    .api-buisness {
        padding: 4rem 1rem;
    }
    .Email-text p {
        width: 97vw;
        text-align: center;
        font-size: 16px;
    
    }
    .carousel slide{
        display: none;
    }
    .kick {
        display: flex;
        justify-content: center;
    }
    .rocket-img {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .whats-business {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sub-section1 {
        width: 100%;
        height: auto;
    }
    .sub-section2 iframe {
        width: 100%;
        height: 15rem;
    }
    .free-text p {
        width: 100vw;
        text-align: center;
        padding: 1rem 1rem 0rem;
    }
    .api-buisness h2 {
        margin-top: -3rem;
        font-size: 1.6rem;
    }
    .api-box1 p {
        font-size: 19px;
        line-height: 18px;
    }
    .threebox {
        margin-top:0rem;
    }
    .t1 {
        margin: .5rem 0rem;
    }
    .t1 span{
        padding: 0rem 1rem 0rem 0rem;
        width: 70vw;
    font-size: 17px;
    }
    .api-box1 h3 {
        font-size: 20px;
        line-height: 30px;
    }
    .api-box{
        margin: auto;
        height: auto;
        width: 100%;
    }
    .t2 span {
        font-size: 16px;
    }
    .three-box {
        flex-direction: column;
    }
    .cr2 {
        font-size: 17px;
    }
    .ri1 {
        font-size: 17px;
    }    
    .start-btn{
        display: flex;
        justify-content: center;
        padding: 0rem 2rem;
    }
    .start-btn button {
      
            margin: 1rem 0rem 0rem;
            width: 100%;
            padding: 0rem 10px;
    }
    .mainsection{
       flex-direction: column;
        margin: 0rem 0rem;
        gap: 0rem;
        padding-bottom: 0rem;
    }
    .api-box1 {
        width: 100%;
    }
}

@media screen and (min-width:499px) and (max-width:768px) {
    .botwala-slide{
        display: none;
    }
   
   
    .sub-section1{
        height: 30rem;
        width: 100%;
       
    }
    .api-boxs {
        width: 30rem;
        margin-top: 8rem;
    }
    .cr1{
        font-size: 18px;
    }
    .message-text  span{
        width: 88vw;
        font-size: 18px;
    }
    .message-text {
        padding: 0rem 1rem;
    }
    .apiheading h2 {
        padding: 0rem 0rem 0rem;
        margin-top: .5rem;
    }
    .facingtext span {
        display: flex;
        justify-content: center;
        width: 100vw;
        text-align: center;
        padding: 1rem 0rem 0rem 0rem;
    }
    .whatapi {
        height: auto;
        width: 100%;
        padding: 0rem 1rem;
    }
    .time-headeing h2 {
        margin-bottom: .5rem;
        font-size: 2rem;
        padding: 1rem 0rem 0rem 0rem;
        width: 100vw;
        text-align: center;
        line-height: 34px;
    }
    .message-text p {
        text-align: center;
        font-size: 13px;
    }
    .api-buisness {
        padding: 4rem 1rem;
    }
    .Email-text p {
        width:96vw;
        text-align: center;
        font-size: 16px;
    }
    .carousel slide{
        display: none;
    }
    .kick {
        display: flex;
        justify-content: center;
    }
    .rocket-img {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .whats-business {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sub-section1 {
        width: 100%;
        height: auto;
    }
    .sub-section2 iframe {
        width: 100%;
        height: 15rem;
    }
    .free-text p {
        width: 100vw;
        text-align: center;
        padding: 1rem 1rem 0rem;
    }
    .api-buisness h2 {
        margin-top: -3rem;
        font-size: 1.6rem;
    }
    .api-box1 p {
        font-size: 13px;
        line-height: 18px;
    }
    .threebox {
        margin-top: 1.5rem;
    }
    .t1 {
        margin: .6rem 0rem;
    }
    .t1 span{
        padding: 0rem 1rem 0rem 0rem;
        width: 26vw;
    font-size: 13px;
    }
    .api-box1 h3 {
        font-size: 14px;
        line-height: 17px;
    }
    .t2 {
        margin: .5rem;
    }
    .api-box{
        margin: auto;
        height: auto;
        width: 100%;
    }
    .t2 span {
        font-size: 13px;
    }
    .t0 {
        margin: .5rem;
    }
    .cr2 {
        font-size: 17px;
    }
    .ri1 {
        font-size: 17px;
    }    
    .start-btn{
        display: flex;
        justify-content: center;
        padding: 0rem 2rem;
    }
    .start-btn button {
        margin: 1rem 0rem 0rem;
        width: 100%;
        padding: 0rem .5rem;
        font-size: 19px;
    }
    .mainsection{
        flex-direction: column;
        margin: 0rem 0rem;
        gap: 0rem;
        padding-bottom: 0rem;
    }
    .api-box1 {
        width: 30rem;
    }
}

@media screen and (min-width:769px) and (max-width:1200px) {
    .botwala-slide{
        display: none;
    }
   
   
    .sub-section1{
        height: 30rem;
        width: 100%;
       
    }
    .api-boxs {
        width: 30rem;
        margin-top: 8rem;
    }
    .cr1{
        font-size: 18px;
    }
    .message-text  span{
        width: 90vw;
        font-size: 18px;
    }
    .message-text {
        padding: 0rem 1rem;
    }
    .apiheading h2 {
        padding: 0rem 3rem 0rem;
        margin-top: 1.5em;
    }
    .facingtext span {
        display: flex;
        justify-content: center;
        width: 47vw;
        text-align: center;
        padding: 1rem 0rem 0rem 0rem;
        line-height: 24px;
        font-size: 17px;
    }
    .time-headeing h2 {
        margin-bottom: .5rem;
        font-size: 1.7rem;
        padding: 1rem 0rem 0rem 0rem;
        width: 50vw;
        text-align: center;
        line-height: 25px
    }
    .message-text p {
        text-align: center;
        font-size: 19px;
    }
    .api-buisness {
        padding: 4rem 1rem;
    }
    .Email-text p {
        width:50vw;
        text-align: start;
        font-size: 16px;
    }
    .carousel slide{
        display: none;
    }
    .kick {
        display: flex;
        justify-content: center;
    }
    .rocket-img {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .whats-business {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sub-section1 {
        width: 100%;
        height: auto;
    }
    .sub-section2 iframe {
        width: 100%;
        height: 15rem;
    }
    .free-text p {
        width: 50vw;
        text-align: start;
        padding: 1rem 1rem 0rem;
        font-size: 16px;
    }
    .api-buisness h2 {
        margin-top: -3rem;
        font-size: 1.6rem;
    }
    .api-box1 p {
        font-size: 18px;
        line-height: 18px;
    }
    .threebox {
        margin-top: 1.5rem;
    }
    .t1 {
        margin: .6rem 0rem;
    }
    .t1 span{
        padding: 0rem 1rem 0rem 0rem;
        width: 29vw;
        font-size: 16px;
    }
    .api-box1 h3 {
        font-size: 19px;
        line-height: 30px;
    }
    .t0 span {
        letter-spacing: .4px;
        font-size: 16px;
    }
    .t2 {
        margin: .5rem;
    }
    .api-box{
        margin: auto;
        height: auto;
        width: 100%;
    }
    .t2 span {
        font-size: 16px;
    }
    .apply-btn button {
        height: 3rem;
        width: 12rem;
        font-size: 18px;
    }
    .t0 {
        margin: .5rem;
    }
    .cr2 {
        font-size: 17px;
    }
    .ri1 {
        font-size: 17px;
    }    
    .start-btn{
        display: flex;
        justify-content: center;
        padding: 0rem 5rem;
    }
    .start-btn button {
        margin: 2rem 0rem 0rem;
        width: 16rem;
        padding: 0rem .5rem;
        font-size: 16px;
    }
    .mainsection{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0rem 0rem;
        gap: 1rem;
        padding-bottom: 0rem;
    }
    .api-box1 {
        width: 30rem;
    }
    .whatapi {
        height: auto;
        width: 100%;
        padding: 0rem 1rem;
    }
}