*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

body,html{
    height: 100%;
    width: 100%;
}

.Engagement{
    height: auto;
    width: 100%;
    /* background-color: #fff; */
    /* background-image: linear-gradient(to top, rgb(247, 250, 247),rgb(182, 248, 182),  rgb(247, 250, 247)); */
     background-image: url("./Images/slidebg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    

}

.barriers h1{
    text-align: center;
    font-size: 32px;
    line-height: 2.3rem;
    color: #1d1d1b;
    font-weight: 700;
    padding: 1rem 0rem 0rem;
}

.barriers p{
    text-align: center;
    font-size: 19px;
    color: #1d1d1b;
    font-weight: 500;
    padding: 0rem 0rem 1.5rem;
}

.bairy{
    height: 13rem;
    width: 18rem;   
}

.break p{
    width: 19vw;
    text-align:center;
}

.upscale p{
    width: 19vw;
}


.start-manage p{
    width: 19vw;
}

.break p{
    width: 22vw;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4rem;
    padding: 0rem 0rem 1rem;
}

.break h3{
    padding: 0.8rem 0rem 0rem;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
}


.carousel-control-next, .carousel-control-prev {
    width: 15%;
   
}
/* #mainslid{
 background-image: linear-gradient(to top, rgb(218, 247, 218),rgb(145, 246, 145),  rgb(222, 247, 222));
} */

#slid {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    gap: 1rem;
    /* flex-direction: column; */
}



.carousel-control-next-icon, .carousel-control-prev-icon{
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    background-color: gray;
}

#sliding{
    display: flex;
    flex-direction: row;
}
@media (min-width :769px) and (max-width:1200px){
    .break p {
        width: 37vw;
    }
    #slid {
        gap: 23rem;
    }
    .engage{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .better-way{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

    }
    .thousands{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

    }
    .programs{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

    }
    .barriers{
        padding: 0px 5px;
    }

}
@media (min-width :499px) and (max-width:769px){
    .break p {
        width: 57vw;
    }
    #slid {
        gap: 15rem;
    }
    .engage{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .better-way{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

    }
    .thousands{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

    }
    .programs{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

    }
    .barriers{
        padding: 0px 5px;
    }

}
@media (min-width :349px) and (max-width:499px){
    .break p {
        width: 82vw;
    }
    #slid {
        gap: 15rem;
    }
    .carousel-control-next-icon, .carousel-control-prev-icon {
        display: inline-block;
        width: 1rem;
        height: 1rem;
    }
    .barriers h1 {
        text-align: center;
        font-size: 24px;
    }
    .engage{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .better-way{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

    }
    .thousands{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

    }
    .programs{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

    }
    .barriers{
        padding: 0px 5px;
    }
}
@media (min-width :250px) and (max-width:349px){
    .bairy {
        height: 10rem;
        width: 13rem;
    }
    .break p {
        width: 82vw;
    }
    .barriers{
        padding: 0px 5px;
    }
    #slid {
        gap: 15rem;
    }
    .carousel-control-next-icon, .carousel-control-prev-icon {
        display: inline-block;
        width: 1rem;
        height: 1rem;
    }
    .barriers h1 {
        text-align: center;
        font-size: 24px;
    }
    .engage{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .better-way{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

    }
    .thousands{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

    }
    .programs{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

    }
}