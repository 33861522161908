html.body{
    font-family: "Inter", Sans-serif !important;
}
/* .main-contact{
   padding: 2rem;
} */
.sub-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 1rem 0rem;
}

.contact-head{
    font-weight: 800;
    padding: 1.5rem 0rem 1rem;
    animation: slide 1s ease, beast 2s infinite;
    display: flex;
    justify-content: center;
    align-items: center;

}
.error-message{
    color: red;
    margin-bottom: 0rem;
    margin-top: -15px;

}
.sub-contact h2 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    color: #1D1B21;
    animation: slide 1s ease, beast 2s infinite;
}
.sub-contact span {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    color: #1D1B21;
}
.contact-box{
    height: auto;
    width: 43rem;
    background-color: #ffffff !important;
    box-shadow: 4px 8px 15px 6px #E6EDF3;
    border-radius: 1rem;
    margin: auto;
    border: 1px solid black;
    border-radius: 10px;
   
}
.sub-contact-box {
    background-color:whitesmoke;
   
    height: 10rem;
    width: 100%;
    border-radius: .8rem .8rem 0rem 0rem;
}
.recaptcha{
height: 4rem;
width: 4rem;
}
.sub-contact-box h2{
   display: flex;
   border-radius: 10px ;
   justify-content: center;
  padding: 2.5rem 0rem;
  font-size: 3.5rem;
  animation:  blingAnimation 2s infinite ;
  font-weight: 700;
  color: rgb(13, 158, 13);
  /* border: 1px solid black;
  border-radius: 10px; */
  /* animation: blingAnimation 2s infinite;  */
}
/* @keyframes blingAnimation {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }  
} */
.contact-inputs{
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 1.5rem;
    flex-direction: column;
}
.contact-text {
    padding: 2rem 2rem;
    /* border: 1px solid black;
    border-radius: 10px; */
}
.c-text span{
    color: #666363;
  display: flex;
  justify-content: center;
  align-items: center;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    padding: 0rem 0rem 1rem;
    
}
.contact-inputs input{
   
    border: 1px solid #d7d7d7!important;
    width: 100%;
    height: 3.5rem;
    border-radius: .3rem;
    padding: 0rem .5rem;
    font-size: 15px;
    font-weight: 500;
    color: rgba(112, 112, 112, 0.98);
}
.contact-select{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.contact-select span {
    color: #666363;
    font-size: 16px;
    font-weight: 600;
    text-align: start;
   padding: 1rem 0rem 0rem;
   
}
.contact-textarea textarea{
    border: 1px solid #d7d7d7!important;
    margin-top: 1.5rem;
    height: auto;
    width: 100%;
    border-radius: .3rem;
    padding: 0rem .5rem;
    font-size: 15px;
    color: rgba(112, 112, 112, 0.98);
}
.contact-select select{
    border: 1px solid #d7d7d7!important;
    height: 3.5rem;
    width: 100%;
    border-radius: .3rem;
    padding: 0rem .3rem;
    font-size: 17px;
    color: rgba(112, 112, 112, 0.98);
}
.ob{
    font-size: 17px;
    color: #1D1B21;

}
.not-rob{
    height: 4rem;
    width: 16rem;
    background-color: rgb(241, 239, 239);
    border-radius: .1rem;
}
.capcha{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    padding: 1rem 0rem;
    
}
.check-c {
color: green;
}
.contact-btn button{
    margin-top: 2rem;
    height: 4.5rem;
    width: 100%;
    background-color: rgb(34, 178, 118);
    color: rgb(255, 255, 255);
    font-weight: 400;
    font-size: 24px;
    border-radius: .5rem;
    border: none;
    text-align: center;
    cursor: pointer;
}
@media screen and (min-width:250px) and (max-width:349px) {
    .main-contact{
        padding: 1rem 1rem 0rem;
     }
     .contact-head {
        font-weight: 800;
        padding: 10px 0rem 10px;
    }
     .contact-textarea textarea {
        margin-top: 0rem;
     }
     
     .not-rob{
        height: 4rem;
        width: 100%;
     }
    .sub-contact h2 {
        text-align: center;
    font-size: 1.2rem;
    font-weight: 700;
    color: #1D1B21;
    width: 100vw !important;
    }
    .sub-contact span {
        text-align: center;
        font-size: 15px;
        line-height: 21px;
        font-weight: 600;
        color: #1D1B21;
    }
    
     .sub-contact {
       display: flex;
       justify-content: center;
       align-items: center;
       flex-direction: column;
       padding: 1rem 0rem;
     }
    
     
     .contact-box{
         height: auto;
         width: 100%;
       
         border-radius: 1rem;
         
     }
     .sub-contact-box {
        height: 8rem;
     }
     .sub-contact-box h2{
       padding: 37px 0rem;
       font-size: 2.2rem;
       font-weight: 700;
       
       
     }
    
     .contact-text {
         padding: 2rem 1rem;
     }
    
     .contact-inputs input{
        
         border: 1px solid #d7d7d7!important;
         width: 100%;
         height: 3rem;
         margin: 0px 0px 23px 0px
     }
    
     .contact-select span {
         color: #666363;
         font-size: 12px;
         font-weight: 600;
         text-align: start;
        padding: 1rem 0rem 0rem;
        margin: 0px 0px 23px 0px
        
     }
     .contact-inputs {
        display: flex;
        gap: 0rem;
     }
     .c-text span {
        font-size: 15px;
        line-height: 20px;
     }
     .contact-select select{
         border: 1px solid #d7d7d7!important;
         height: 3rem;
         margin: 0px 0px 23px 0px
      
     }
    .contact-btn button{
         margin-top: 2rem;
         height: 3.5rem;
         font-size: 20px;
     }

}

@media screen and (min-width:349px) and (max-width:499px) {
    .main-contact{
        padding: 1rem 1rem;
     }
     .contact-head {
        font-weight: 800;
        padding: 0rem 0rem 1rem;
     }
    .sub-contact h2 {
        text-align: center;
    font-size: 1.8rem;
    font-weight: 700;
    color: #1D1B21;
    padding: 0rem 0.5rem;
    width: 100vw !important;
    }
    .sub-contact span {
        text-align: center;
        font-size: 1.3rem;
        font-weight: 600;
        color: #1D1B21;
    }
    
     .sub-contact {
       display: flex;
       justify-content: center;
       align-items: center;
       flex-direction: column;
       padding: 1rem 0rem;
     }
    
     
     .contact-box{
         height: auto;
         width: 100%;
    
         border-radius: 1rem;
     }
    
     .sub-contact-box h2{
       padding: 3rem 0rem;
       font-size: 2.5rem;
       font-weight: 700;
     }
    
     .contact-text {
         padding: 2rem 1rem;
     }
    
     .contact-inputs input{
         border: 1px solid #d7d7d7!important;
         width: 100%;
         height: 3rem;
         
     }
    
     .contact-select span {
         color: #666363;
         font-size: 16px;
         font-weight: 600;
         text-align: start;
        padding: 1rem 0rem 0rem;
        
     }
     .contact-inputs{
        gap: 1rem;
     }
     .c-text span {
        font-size: 20px;
     }
     .contact-select select{
         border: 1px solid #d7d7d7!important;
         height: 3rem;
       margin: 1rem 0rem 0rem;
     }
    .contact-btn button{
         margin-top: 2rem;
         height: 3.5rem;
         font-size: 20px;
     }

}
@media screen and (min-width:499px) and (max-width:769px) {
    .main-contact{
        padding: 1rem 1rem;
     }
     .sub-contact {
       display: flex;
       justify-content: center;
       align-items: center;
       flex-direction: column;
       padding: 1rem 0rem;
     }
     .sub-contact h2 {
        text-align: center;
     font-size: 1.5rem;
     }
     .sub-contact span {
        text-align: center;
         font-size: 1.2rem;
     }
     .contact-box{
         height: auto;
         width: 100%;
         border-radius: 1rem;
         margin: auto;
      
        
     }
    
     .sub-contact-box h2{
       padding: 3rem 0rem;
       font-size: 2.5rem;
       font-weight: 700;
     }
    
     .contact-text {
         padding: 2rem 2rem;
     }
    
     .contact-inputs input{
        
         border: 1px solid #d7d7d7!important;
         width: 100%;
         height: 3rem;
     }
    
     .contact-select span {
         color: #666363;
         font-size: 16px;
         font-weight: 600;
         text-align: start;
        padding: 1rem 0rem 0rem;
        
     }
     .c-text span {
        font-size: 20px;
     }
     .contact-select select{
         border: 1px solid #d7d7d7!important;
         height: 3rem;
      
     }
    .contact-btn button{
         margin-top: 2rem;
         height: 4rem;
     }
}
@media screen and (min-width:769px) and (max-width:1200px) {
    .sub-contact span {
        width: 59vw;
    }
}
@media screen and (min-width:1200px) and (max-width:1500px) {
    .sub-contact span {
        width: 59vw;
    }
}