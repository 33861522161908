*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.navbar{
    height: auto;
    width: 100%;
    background-color: aqua;
}
.session1{
    /* height: 50rem;
    width: 100%;
    background-color: pink; */
}
.session2{
    /* height: 50rem;
    width: 100%;
    background-color: pink; */
}