*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.footer-design{
    background-image: url("./Images/footerbackground.gif");
    height: auto;
    width: 100%;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    padding: 5px ;
    margin-top: 2rem;
   
}
.copyright{
    display: flex;
    color:black;
    gap: 1rem;
    align-items: center;
    justify-content: center;
   
}
.copyr{
    color: rgb(148, 144, 144);
    text-align: center;
    font-size: 16px;
    
}
.copyright span{
    font-size: 15px ;
    font-weight: 500;
}
.crl{
    color:black;
}
.ft1{
    text-decoration: none;
    color: rgb(177 237 92);
    font-size: 18px ;
    font-weight: 500;
}
.ft-hr{
    color: azure;
    height: 2px !important;
    background-color: azure;
    margin: 1rem 2rem;
}
.ft-page{
    gap: 1rem;
    margin-top: 2rem;
    display: flex;
}
.crl{
    font-size: 18px;
}
.mainfooter{
    /* background-image: url("./Images/footerbackground.gif");
    height: 4.5rem;
    width: 100%; */
    display: flex;
    
    justify-content: center;
    align-items: center;
    gap: 2rem;
    /* margin-top: 2rem; */
    
}
.misscall p{
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0rem !important;
}
.misscall span{
font-weight: 700;
}
.followus span{
    font-size: 20px;
    font-weight: 600;
    color: #fff;
}

.f1{
    height: 2rem;
    width: 2rem;
    margin: 0rem .1rem;
}
.f2{
    height: 1.8rem;
    width: 1.8rem;
    margin: 0rem .1rem;
}
.f3{
    height: 2.2rem;
    width: 2.2rem;
    margin: 0rem .1rem;
}
.f4{
    height: 1.9rem;
    width: 1.9rem;
    margin: 0rem .1rem;
}
.f5{
    height: 2rem;
    width: 2rem;
    margin: 0rem .1rem;
}
.followfooter{
    text-align: center;
}
.logoicon{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

@media (min-width:1200px) and (max-width:1500px){
    .mainfooter{
        background-image: url("./Images/footerbackground.gif");
        height: 4.5rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        margin-top: 0rem;
    }
    .misscall p{
        font-size: 20px;
        font-weight: 600;
        color: #fff;
    }
    .misscall span{
    font-weight: 800;
    }
    .followus span{
        font-size: 20px;
        font-weight: 600;
        color: #fff;
    }
    
    .f1{
        height: 2rem;
        width: 2rem;
        margin: 0rem .1rem;
    }
    .f2{
        height: 1.8rem;
        width: 1.8rem;
        margin: 0rem .1rem;
    }
    .f3{
        height: 2.2rem;
        width: 2.2rem;
        margin: 0rem .1rem;
    }
    .f4{
        height: 1.9rem;
        width: 1.9rem;
        margin: 0rem .1rem;
    }
    .f5{
        height: 2rem;
        width: 2rem;
        margin: 0rem .1rem;
    }
    .logoicon{
        gap: 0.2rem;
    }

}

@media (min-width:600px) and (max-width:1200px){
    .mainfooter {
        height: 6.5rem;
        width: 100%;
        flex-direction: column;
        gap: 0rem;
        margin-top: 0rem;
    }
    .logoicon{
        gap: 0rem;
    }
    .ft-page {
        gap: 2rem;
        display: flex;
    }

}


@media (min-width:499px) and (max-width:768px){
    .mainfooter {
        height: 6.5rem;
        width: 100%;
        flex-direction: column;
        gap: 0rem;
        margin-top: -1rem;
        
    }
    .ft1 {
       
        font-size: 15px;
        
    }
    .copyr{
        text-align: center;
        font-size: 13px;
    }
    .ft-page{
        display: flex;
        gap: 1rem;
    }
    .logoicon{
        gap: 0rem;
    }
    .footer-design{
        margin-top: 2rem !important;
    }
    

}
@media (min-width:349px) and (max-width:499px){
    .footer-design{
        margin-top: 2rem !important;
    }
    .followus span {
    text-align: center;
    }
    .logoicon{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 0rem;
    }
    .mainfooter {
        height: 12rem;
        width: 100%;
        flex-direction: column;
        gap: 0rem;
        margin-top: -3rem;
        
    }
    .ft1 {
        font-size: 15px;
        text-align: center;
        
    }
    .copyr{
        text-align: center;
        font-size: 13px;
    }
    .ft-page{
        display: flex;
        gap: 1rem;
        flex-direction: column;
    }
    
    .misscall p {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        text-align: center;
    }
    .followus{
        text-align: center;
       
    }

}
@media (min-width:250px) and (max-width:349px){
    .footer-design{
        margin-top: 2rem !important;
    }
    .followus span {
    text-align: center;
    }
    .logoicon{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 0rem;
    }
    .mainfooter {
        height: 12rem;
        width: 100%;
        flex-direction: column;
        gap: 0rem;
        margin-top: -3rem;
        
    }
    .ft1 {
        font-size: 13px;
        text-align: center;
        
    }
    .copyr{
        text-align: center;
        font-size: 13px;
    }
    .ft-page{
        display: flex;
        gap: 1rem;
        flex-direction: column;
    }
    
    .misscall p {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        text-align: center;
    }
    .followus{
        text-align: center;
       
    }

}