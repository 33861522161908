*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html, body{
    font-family: Outfit,sans-serif!important;
}
.backgroundgif{
    background-image: url("./Images/vidogif.gif");
    height: 5rem;
    width: 100%;
}
.navbar{
    /* position: fixed; */
    background: #f8f2f2 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8rem;
    background-color: pink;
    height: 6rem;
    width: 100%;
     z-index: 1000;
    
}
.CaseStudies{
    animation: blingAnimation 2s infinite; 

}
.buttons{
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: .5rem 0rem;
    cursor: pointer;
}
.nav{
    display: flex;
    gap: 2rem;
    align-items: center;
}
.nav p{
    text-decoration: none;
    color: unset;
    font-size: 20px;
    /* font-weight: 600; */
    line-height: 2.43;
    padding: 1rem 0rem;
    cursor: pointer;
    letter-spacing: 1px;

}
.nav p:hover{
    color: #56c726;
}
.btn1 button{
  font-weight: 500;
    height: 2.7rem;
    width: 6rem;
    background-color: #ffffff;
    border: none;
    border-radius: 6px;
    font-size: 18px;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.1);
}
.btn2 button{
    height: 2.7rem;
    width: 6rem;
    font-weight: 500;
    background-color: rgb(37, 224, 37);
    border: none;
    border-radius: 6px;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.1);   
}
.logotexts p {
   
    margin-bottom: 0rem;
}
.btn1 button:hover{
    color: #56c726;
    cursor: pointer;
}
.btn1 button:hover{
    animation: skew-y-shakeing 3s infinite; 
}
.btn2 button:hover{
    animation: skew-y-shakeing 3s infinite; 
}
@keyframes skew-y-shakeing{
    0% { transform: skewY(-15deg); }
    5% { transform: skewY(15deg); }
    10% { transform: skewY(-15deg); }
    15% { transform: skewY(15deg); }
    20% { transform: skewY(0deg); }
    100% { transform: skewY(0deg); }
   }
   
   .logoboxs{
    height: 5rem;
    width: 7.5rem;
    border: 2px solid green;
    text-align: center;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 0.5rem;
    /* animation: blingAnimation 2s infinite;  */
   }
   #CaseStudies{
    animation: blingAnimation 2s infinite;
   }
  

   .messageboxs{
    font-size: 2.4rem;
    color: green;
      animation: blingAnimation 2s infinite; 
   }
   @keyframes blingAnimation {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }  
  }
  .logotexts p{
    font-size: 18px;
    letter-spacing: 1.5px;
    color: green;
    
  }
  .Linking{
    text-decoration: none;
    color: #000;
    font-size: 1.4rem;
  }
  .Linking:hover{
   color: green;
  }
  .lbtn{
   height: 2.7rem;
   width: 6rem;
   background-color: #ffffff;
   border: none;
   border-radius: 6px;
   font-size: 18px;
   box-shadow: 0px 15px 25px rgba(0,0,0,0.1);
   cursor: pointer;
  }
  /* Hamburger Icon Styles */
.hamburger-icon {
    cursor: pointer;
    display: none; /* Hide by default on larger screens */
  }
  
  .line {
    width: 30px;
    height: 3px;
    background-color: #fff;
    margin: 6px 0;
    transition: 0.4s;
  }
  
  /* Navbar Styles */
  /*  */
  
    @media (max-width: 768px) {
      .backgroundgif{
        height: 4rem !important;
      }
      .nav {
        display: none; /* Hide on smaller screens by default */
        flex-direction: column;
        position: absolute;
        width: 100%;
        top: 74px; /* Adjust as needed based on your header height */
      }
      .navbar{
        justify-content: space-between;
        gap: 6rem;
        height: 4rem;
        padding: 0px 0px;
      }
      .logoboxs {
        margin-left: 5px;
        height: 3rem;
        width: 5.5rem;
      }
      .messageboxs {
        font-size: 1.3rem;
        
      }
      .logotexts p {
        font-size: 14px;
        padding: 0px 0px 3px 0px;
      }
      .Linking{
        font-size: 20px;
      }
      
  
      .opens {
        display: flex;
        background-color: rgb(135, 220, 128);
        align-items: center;
        padding: 3rem 0px;
      }
  .buttons{
    margin-top: -2rem;
  }
      .hamburger-icon {
        margin-right: 4px;
        display: block; /* Show on smaller screens */
      }
      .line {
        padding: 0rem 0rem 0.2rem 0rem;
        background-color: black;
      }
  
      /* Adjust styles for better appearance on smaller screens */
      .line.open:nth-child(1) {
        transform: rotate(-45deg) translate(-5px, 6px);
      }
      .line.open:nth-child(2) {
        opacity: 0;
      }
      .line.open:nth-child(3) {
        transform: rotate(47deg) translate(-7px, -7px)
      }
     
    }
  
  
  @media (min-width :768px) and (max-width:1000px){
    .navbar{
        gap: 1rem;
    }
    .Linking {
        text-decoration: none;
        color: #000;
        font-size: 18px;
    }
    .logoboxs {
        height: 3rem;
        width: 4.5rem;
    
    }
    .messageboxs {
        font-size: 1.5rem;
    }
    .logotexts p {
        font-size: 11px;
    }
    .btn1 button {
      height: 2.5rem;
      width: 5rem;
        font-size: 14px;
    }
    .btn2 button {
        height: 2.2rem;
        font-size: 14px;
        width: 4rem;
    }
    .nav{
        align-items: center;
        gap: 1rem;
    }
    .btn2 button {
        height: 2.5rem;
        width: 5rem;
        font-size: 16px;
    }
        

  }
  @media (min-width :1000px) and (max-width:1200px){
    .navbar{
        gap: 2rem;
    }
    .Linking {
        text-decoration: none;
        color: #000;
        font-size: 20px;
    }
    .logoboxs {
        height: 4rem;
        width: 5.5rem;
    }
    .messageboxs {
        font-size: 1.5rem;
    }
    .logotexts p {
        font-size: 15px;
    }
    .nav{
        align-items: center;
        gap: 2.5rem;
    }
    .btn2 button {
      height: 2.7rem;
      width: 6rem;
        font-size: 16px;
    }
        

  }