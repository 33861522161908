*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.term-condition-page{
  padding: 2rem 5rem;
}
.term-condition-box{
    width: 100%;
    height: auto;
    border-left: 4px solid gray;
    border-bottom: 4px solid gray;
    border-right: 4px solid gray;
    border-radius: 10px;
    background-color:whitesmoke;
    padding: 1rem 2rem;
}
.term-head {
    font-weight: 800;
    padding: 1.5rem 0rem 2.5rem;
    animation: slide 1s ease, beast 2s infinite;
    display: flex;
    justify-content: center;
    align-items: center;
}
.term-condition-box:hover{
    border-left: 4px solid rgb(88, 194, 167);
    border-bottom: 4px solid rgb(88, 194, 167);
    border-right: 4px solid rgb(88, 194, 167);
    background-color: azure;
    
}
.terms-content{
    font-size: 18px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.terms-items h5{
    text-decoration: underline;
    position: relative;
    font-size: 18px; /* Adjust the font size as needed */
    color: #0edb0b !important;
    -webkit-text-stroke: 0.3px #383d52; /* Adjust the text stroke as needed */
    text-transform: uppercase;
    white-space: nowrap; /* Prevent line breaks */
    overflow: hidden; /* Hide overflow content */
    display: inline-block; /* Ensures the animation works inline */
    text-decoration:underline rgb(4, 185, 101);
}
.terms-items h5::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #02572f;
    -webkit-text-stroke: 0px #112ea4; /* Adjust the text stroke as needed */
    border-right: 2px solid #09d676;
    overflow: hidden;
    animation: animate 8s linear infinite;
}

@keyframes animate {
    0%, 10%, 100% {
        width: 0;
    }
    70%, 90% {
        width: 100%;
    }
}
.terms-items p{
    font-size: 18px !important ;
    color: #595050;
}
.terms-check{
    color: rgb(56, 111, 182);
}
.terms-content p{
    font-size: 20px;
}
.terms-items h6{
    font-family: inherit;
    font-size: 18px;
}
@media (min-width:768px) and (max-width:1200px){
    .term-condition-page {
        padding: 2rem 1rem 0rem 1rem;
    }

}
@media (min-width:499px) and (max-width:768px){
    .term-condition-page {
        padding: 2rem 1rem 0rem 1rem;
    }
    .term-condition-box{
        padding: 1rem 5px 1rem 11px;

    }
    /* .terms-items h6 {
        font-family: inherit;
        font-size: 18px;
        margin: 28px 0rem -18px 0rem;
    } */

}
@media (min-width:349px) and (max-width:499px){
    .term-condition-page {
        padding: 2rem 1rem 0rem 1rem;
    }
    .term-condition-box{
        padding: 1rem 10px 1rem 10px;

    }
    .term-head {
        font-weight: 800;
        padding: 0rem 0rem 1rem;
    }
    .terms-items p {
        font-size: 18px !important;
        width: 83vw !important;
        margin:20px auto !important;
        
    }
    .terms-content p{
        width: 80vw !important;
        margin-bottom: -0rem !important;
    }
    .terms-items h5{
        margin-bottom: -1rem !important;
    }
    .terms-items h6 {
        font-family: inherit;
        font-size: 18px;
        margin: 28px 0rem -18px 0rem;
    }
    

}
@media (min-width:250px) and (max-width:349px){
    .term-condition-page {
        padding: 2rem 10px 0rem 1rem !important;
    }
    .term-condition-box{
        padding: 1rem 10px 1rem 10px;

    }
    .terms-items p {
        font-size: 17px !important;
        width: 75vw !important;
        margin:20px auto !important;
        
    }
   

    
    .term-head {
        font-weight: 800;
        padding: 10px 0rem 0.5rem;
    }
    .terms-content p{
        width: 77vw !important;
        font-size: 17px !important;
        margin-bottom: -0rem !important;
    }
    .terms-items h5{
        font-size: 13px;
        margin-bottom: -1rem !important;
    }
    .terms-items h6 {
        font-family: inherit;
        font-size: 18px;
        margin: 28px 0rem -18px 0rem;
    }
    
    

}