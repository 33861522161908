/* Login2.css */

.login2 {
    max-width: 400px;
    margin: 2rem auto;
    padding: 15px 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
}
.upper a {
    text-decoration: none;
    color: #4CAF50;
    font-weight: bold;
    font-size: 15px !important;
}


.login2 h1 {
    color: #333;
    margin-top: 0.5rem;
}

.login2 form {
    display: flex;
    flex-direction: column;
}

.login2 input {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.login2 input[type="submit"] {
    background-color: #3498db;
    color: #fff;
    cursor: pointer;
    border-radius: 1.5rem;
    width: 20rem;
}

.login2 input[type="submit"]:hover {
    background-color: #2980b9;
}

.login2 p {
    font-size: 15px;
    color: #333;
}

.login2 a {
    color: #3498db;
    text-decoration: none;
    font-weight: bold;
}

.login2 a:hover {
    text-decoration: underline;
}

.error-message {
    color: red;
    text-align: start;
    font-size: 13px !important;
}
.username{
    border-radius: 5px;
    border: 1px solid gray;
    margin: 10px;
    height: 45px;
    padding: 5px;
    width: 100%;
    
}
.sub-btn{
    margin-top: 0px !important;
}
.upper{
    margin-top: 0px !important;
}
.fg{
    gap: 0.5rem !important;
}
.plat{
    padding: -0.5rem 0rem 0rem 0rem !important;
}
/* .signup{
   box-shadow: 0px 0px 10px 0px black;
   border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 400px;
    padding: 0px 0px 20px;
    margin: 4rem auto;
} */
.upper p{
    margin-bottom: 0rem !important;
}

/* Responsive Styles */

@media screen and (max-width: 600px) {
    .login2 {
        max-width: 100%;
        margin: 2rem;

    }

    .login2 input[type="submit"] {
        width: 100%;
    }
}
.signup {
    text-align: center;
}

.company-logo img {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
}

form {
    display: flex;
    flex-direction: column;
}

.username {
    margin: 10px;
    padding: 5px;
}

.sub-btn {
    margin-top: 20px;
}

.login-btn {
        padding: 10px 30px;
        background-color: #4CAF50;
        color: white;
        border: none;
        margin-top: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.upper {
    margin-top: 20px;
}

.upper a {
    text-decoration: none;
    color: #4CAF50;
    font-weight: bold;
    font-size: 12px !important;
}
