* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

html,
body {
  height: 100%;
  width: 100%;
}
/* .bot-video{
  background-color: pink;
  height: 50rem !important;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 40rem !important;
}
.bt-video{
  width: 70rem !important;
} */

.SeccondContainer {
  text-align: center;
  margin-top: 4rem;
}

.serving {
  font-size: 3rem;
}

.pickIndustry {
  margin: auto;
  width: 48vw;
  font-size: 1.2rem;
  margin-top: -2rem;

}

.Mainclass {
  margin-top: -4rem;

}

.animation h1 {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  animation: typeWriter 3s steps(60) infinite, fadeIn 2s 4s forwards, colorChange 3s 6s infinite;
  color: #89dc12;
  margin-top: -1.2rem;
  font-size: 38px !important;
  padding: 1.5rem 0rem;
}

@keyframes typeWriter {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes colorChange {
  0% {
    color: #086c3a;
  }

  50% {
    color: #058a50; /* Change to your desired color */
  }

  100% {
    color: #12dc19;
  }
}

.groth {
  font-size: 38px !important;
  width: 45vw;
  color: #1d2651;
}

.SuperMainClass {
  /* padding-top: 5rem; */
  padding: 38px 0px 0px 0px ;
  /* border-left: 5px solid #12dc19;
  border-bottom: 5px solid #12dc19; */
  /* border: 5px solid #12dc19; */
  border-radius: 10px;
  flex-direction: row-reverse;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: -2rem; */
  background-image: url('../components//Images/01.png');
  /* /* font-family: "Poppins", sans-serif */
  font-family: "Poppins", sans-serif;
  font-family: "Raleway", sans-serif;
  gap: 7.3rem;


}

@keyframes blingAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.logob {
  width: 7rem !important;
  height: 7rem !important;
  background-color: rgb(243, 242, 236);
  /* Change the background color as needed */
  border-radius: 50%;
  animation: blingAnimation 2s infinite;
  /* Adjust the animation duration as needed */
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}




.wtssrc {
  width: 25rem;
  /* Adjust the width as needed */
  height: auto;
  /* Maintain aspect ratio */
  border-radius: 8px;
  /* Add rounded corners if desired */
  margin-top: -2rem;


}

.Official {
  width: 40vw;
  font-size: 18px;
  color: #1d2651;
  text-align: justify;
  margin-top: -0.5rem;
}

.sub1 {
  /* color: rgb(0, 12, 45) !important;
   font-family: "Google Sans" !important;
   font-size: 1.2rem !important;
   line-height: 1.65rem !important;
   padding-bottom: 10px;
   letter-spacing: -0.1px; */
  /* animation: typeWriter 1.5s steps(60) infinite, fadeIn 2s 4s forwards; */
  /* /* word-spacing: -1.75px;  */

  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  /* animation: typeWriter 3.5s steps(60) infinite, fadeIn 2s 4s forwards; */
  animation: blingAnimation 2s infinite;
  color: #1d2651;
  margin-top: -1.2rem;
  font-size: 16px !important;
  padding: 1.5rem 0rem;
  font-weight: 400;


}

@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes pulseBackground {
  from {
    background-color: #f0f0f0;
  }

  to {
    background-color: #d1e9ff;
    /* Set your desired pulsating color */
  }
}


.demobtn {
  display: inline-block;
  padding: 17px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 8px;
  /* Rounded corners */
  background: rgb(82, 192, 35);
  background: linear-gradient(90deg, rgba(0, 187, 57, 1) 0%, rgba(103, 198, 43, 1) 90%);
  color: #ffffff;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  position: relative;
  overflow: hidden;
  border: none;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.demobtn:hover {
  animation: pulse 2s infinite;
}


.ply {
  position: relative;
  text-transform: uppercase;
  color: #111121;
  font-weight: 500;
  font-size: 18px;


}

.playclass a {
  text-decoration: none;
}

.greenLine {
  width: 50px;
  /* Adjust the width as needed */
  height: 2px;
  /* Adjust the height as needed */
  background-color: green;
}

.JoinDemo {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 2rem;
  margin-top: 1.3rem;

}

.plybtn {
  font-size: 40px;

  border: 2px solid #89dc12;
  padding: 0.7rem;
  border-radius: 30%;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  background-color: #89dc12;
  color: #fff;
}

.plybtn:hover {
  color: #89dc12;
  background-color: #ffffff;
  transform: scale(1.05);
}@keyframes slideInRightToLeft {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}



.animation,
.groth {
  position: relative;
  left: -1000px;
  /* Start off-screen */
  animation: slideIn 1.5s ease-out forwards;
  /* Animation duration and timing function */
 
}


@keyframes slideIn {
  to {
    left: 0;
    /* Move to the original position */
  }
}

.wtssrc {
  position: relative;
  left: 100%;
  width: 25rem;
    height: 35rem;
  border: 3px solid #69E48E;
  border-radius: 10px;
  /* Start off-screen from the right side */
  animation: slideIn 1.5s ease-out forwards;
  /* Animation duration and timing function */
}

@keyframes slideIn {
  to {
    left: 0;
    /* Move to the original position */
  }
}


.whats-api {
  height: auto;
  width: 100%;
  background-color: #fafafa;
}

.marketing h1 {
  color: #000000;
  font-weight: bold;
  font-size: 30px;
  padding: 0rem 0rem 1.1rem;
}

.marketing p {
  width: 37vw;
  line-height: 1.9rem;
  font-size: 18px;
  color: #666666;
  font-family: poppins-extralight, poppins, sans-serif;
}

.Session {
  display: flex;
  justify-content: center;
  gap: 4rem;
  padding: 2rem 0rem;
}

.explore {
  padding: 2rem 0rem 0rem;
}

.explore button {
  cursor: pointer;
  transition: inherit;
  background-color: #000;
  color: #ffffff;
  height: 2.6rem;
  width: 15rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.driven h1 {
  color: #000;
  font-size: 30px;
  padding: 0rem 0rem 1rem;
}

.driven p {
  width: 34vw;
  font-size: 18px;
  line-height: 1.9rem;
  font-size: 18px;
  color: #666666;
  font-family: poppins-extralight, poppins, sans-serif;
}

.retarget {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin: 3rem 0rem 0rem 0rem;
}

.shopify h1 {
  color: #000;
  font-size: 30px;
  padding: 0rem 0rem 1rem;
}

.shopify p {
  width: 38.5vw;
  line-height: 1.9rem;
  font-size: 18px;
  color: #666666;
  font-family: poppins-extralight, poppins, sans-serif;
}

.whatsapp-business {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  margin: 6rem 0rem 0rem 0rem;
}

.setup h1 {
  color: #000;
  font-size: 30px;
  padding: 0rem 0rem 1rem;
}

.setup p {
  width: 35.5vw;
  line-height: 1.9rem;
  font-size: 18px;
  color: #666666;
  font-family: poppins-extralight, poppins, sans-serif;
}

.Automation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: 6rem 0rem 0rem 0rem;
}

.management h1 {
  color: #000;
  font-size: 30px;
  padding: 0rem 0rem 1rem;
}

.management p {
  width: 35vw;
  line-height: 1.9rem;
  font-size: 18px;
  color: #666666;
  font-family: poppins-extralight, poppins, sans-serif;
}

.convert-leads {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3.8rem;
  margin: 6rem 0rem 0rem 0rem;
}

.template h1 {
  color: #000;
  font-size: 30px;
  padding: 0rem 0rem 1rem;
}

.template p {
  line-height: 1.9rem;
  font-size: 18px;
  color: #666666;
  font-family: poppins-extralight, poppins, sans-serif;
}

.try-cherrio button {
  cursor: pointer;
  transition: inherit;
  background-color: #000;
  color: #ffffff;
  height: 2.6rem;
  width: 15rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.try-cherrio {
  padding: 2rem 0rem 0rem;
}

.custom-emails {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: 6rem 0rem 0rem 0rem;
}

.integration h1 {
  padding: 3.5rem 0rem 0.5rem;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}

.integration p {
  text-align: center;
  padding: 0rem 0.5rem 1rem;
  font-size: 18px;
  color: #666666;
  font-family: poppins-extralight, poppins, sans-serif;
}

.wapi {
  display: flex;
  justify-content: center;
  gap: 1rem;
  /* transform: translate3d(-1488px, 0px, 0px);
      transition: all 1s ease 0s;
      width: 3534px; */
  padding: 0rem 0rem 4rem;
}

.ig {
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  /* height: 7rem;
    width: 7rem; */
}

.custom-bot {
  height: auto;
  width: auto;
  background-color: #fff;
  /* border: 1px solid green; */
  padding: 0rem 1rem 2rem;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
  /* box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.2); */
  margin-bottom: 3rem;

}

.custom-bot:hover {
  border: 1px solid #12dc19;
  border-width: 2px 2px 2px 2p
}

.custom-bot2 {
  height: auto;
  width: auto;
  background-color: #fff;
  /* border: 1px solid green; */
  padding: 0rem 0rem 2rem;
  border-radius: 10px 10px 10px 10px;
  border: 3px solid #2785ff;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
  /* box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.2); */
  margin-bottom: 3rem;
  margin-top: -2.8rem;


}

.icons span {
  color: #ff4a00;
  font-weight: 800;
  font-size: 16.5px;
}

.most {
  height: 2.5rem;
  width:100%;
  background-color: #2785ff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;

}

#star {
  font-size: 1.2rem;
  color: #FFFF00;
}

.botwala {
  display: flex;
  justify-content: center;
  gap: 1rem;
}


.platform {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0rem 0rem 0rem;
}

.chats h3 {
  text-align: center;
  margin: 1.5rem 0rem 0.8rem;
  font-size: 1.2rem;
}

.chats p {
  margin: auto;
  text-align: center;
  width: 23.5vw;
  color: rgb(0, 12, 45) !important;
  line-height: 1.5rem;
  font-size: 1.1rem;
  font-family: poppins-extralight, poppins, sans-serif;
}

.chats1 h3 {
  text-align: center;
  margin: 1.5rem 0rem 0.8rem;
  font-size: 1.2rem;
}

.chats1 p {
  margin: auto;
  text-align: center;
  width: 24vw;
  color: rgb(0, 12, 45) !important;
  line-height: 1.5rem;
  font-family: poppins-extralight, poppins, sans-serif;
  font-size: 1.1rem;
}

.chats2 h3 {
  text-align: center;
  margin: 1.5rem 0rem 0.8rem;
  font-size: 1.2rem;
}

.chats2 p {
  margin: auto;
  text-align: center;
  width: 23.5vw;
  line-height: 1.5rem;
  font-size: 1.1rem;
  color: rgb(0, 12, 45) !important;
  font-family: poppins-extralight, poppins, sans-serif;
}


.standard {
  height: 8rem;
  width: 8rem;
}

.pro {
  height: 8rem;
  width: 8rem;
}

.hub {
  color: #54595f;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  padding: 1.5rem 0rem 0rem 0rem;
  letter-spacing: 4px;
}

.icons b {
  font-size: 1rem;
  font-weight: 800;
}


.icons {
  padding: 0.5rem 0rem 0.3rem 4rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  font-weight: 500;

}

.one-time {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16.5px;
}

.time-payment {
  text-align: center;
  height: auto;
  width: 17.5rem;
  border: 1px dashed;
  border-color: cyan;
  background-color: #FBFCFF;
  border-radius: 10px 10px 10px 10px;
  padding: 1rem 0rem;
  margin-top: 1rem;
  /* background-color: #89dc12 !; */
}

.time-payment h1 {
  font-weight: 700;
  font-size: 2.5rem;
}

.time-payment h4 {
  font-size: 1rem;
  margin-top: 0.1rem;
}

.save-charges {
  margin: auto;
  height: 2.5rem;
  width: 11rem;
  background-color: #FFFF00;
  padding: 8px 8px 8px 8px;
  border-radius: 6px 6px 6px 6px;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.save-charges b {
  margin: auto;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  text-align: center;
  align-items: center;

}

.zapier span {
  color: #ff4a00;
  text-align: center;
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding: 0.6rem 0rem 0rem 0rem;
  font-size: 16.5px;
  line-height: 2em;
}

.zapier p {
  text-align: center;
  font-size: 1rem;
  color: #222222;
  font-weight: 700;
}

.buy-standard {
  display: flex;
  justify-content: center;
  margin: 1rem 0rem;
}

.buy-standard button {
  height: 3.8rem;
  width: 17.5rem;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  fill: #2785ff;
  color: #2785ff;
  background-color: #fbfbfb;
  border-style: solid;
  border-color: #2785ff;
  border-radius: 8px 8px 8px 8px;
  padding: 12px 0px 12px 0px;
  cursor: pointer;
}

#buy button {
  height: 3.8rem;
  width: 17.5rem;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  fill: #2785ff;
  color: #fbfbfb;
  background-color: #2785ff;
  border-style: solid;
  border-color: #2785ff;
  border-radius: 8px 8px 8px 8px;
  padding: 12px 0px 12px 0px;
  cursor: pointer;
}



.buy-standard button:hover {
  color: #fff;
  background-color: #2785ff;
}


 

.buy-in {
  text-align: center;
  font-size: 18px;
  fill: #2785ff;
  color: #2785ff;
  font-weight: 600;
  padding: 0rem 0rem 1rem;
  cursor: pointer;
}

.buy-in:hover {
  color: #ff2323;
}


.line2 {
  padding: 0rem 0rem 0rem 0rem;
}

#lines {
  padding: 0rem 0rem 1rem;
  margin: auto;
  width: 18rem;
  color: #212529;

}

.internal {
  font-size: 16px;
  color: #030025;
  transition: color 0.3s;
}

#ic {
  font-size: 0.9rem;
}

#ic1 {
  font-size: 0.9rem;
  color:green;
  animation: blingAnimation 2s infinite; 
  

}

#ic4{
 font-size: 0.9rem;
 color:#ff2323;
 animation: blingAnimation 2s infinite; 
}

#ic3 {
  font-size: 1rem;
  font-weight: bold;
  color: #ff2323;
}

.internal {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  padding: 0.2rem 1rem;
  font-size: 1rem;

}

.logobox1 {
  height: 8.5rem;
  width: 8.5rem;
  border: 2px solid green;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 0.5rem;
  border-radius: 50%;
  /* animation: blingAnimation 2s infinite;  */
}

.logobox {
  height: 8rem;
  width: 10.5rem;
  border: 2px solid green;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 0.5rem;
  /* animation: blingAnimation 2s infinite;  */
}
.iconlist{
 
}

.Unlimited {
  margin: auto;
  height: 4rem;
  width: 4rem;
  border-radius: 1px;
  border: 2px solid green;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  justify-content: center;

}

.Unlimited1 {
  margin: auto;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 1px;
  border: 2px solid green;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  justify-content: center;

}

.messagebox {
  font-size: 2.8rem;
  color: green;
  animation: blingAnimation 2s infinite;
}

.logotext {
  margin: 0rem 0rem .4rem;
}

.logotext p {
  margin: auto;
  text-align: center;
  font-size: 1.5rem;
  letter-spacing: 4px;
  color: green;
}

.logotext1 {
  margin: 0rem 0rem 1.5rem;
}

.logotext1 p {
  margin: auto;
  text-align: center;
  font-size: 15px;
  letter-spacing: 4px;
  color: green;
}


@keyframes blingAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes circularWaveAnimation {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }

  150% {
    transform: scale(1.2);
    opacity: 0.5;
  }

  170% {
    transform: scale(2);
    opacity: 0;
  }
}

.logobox {

  animation: circularWaveAnimation 2s infinite;
}

.logobox1 {

  animation: circularWaveAnimation 2s infinite;
}
.scroll-image {
  height: auto;
  width: 100%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0rem;
  flex-direction: column;
  margin-top: 1rem;
}

.trust {
  font-size: 24px;
  color: #1D1B1B;
}

.fast {
  font-size: 24px;
  color: #1f6648;
}

.sr-image {
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.srl1 {
  height: 8rem;
  width: 8rem;
  margin: 0rem 3rem;
}

.srl4 {
  height: 7rem;
  width: 7rem;
  margin: 0rem 3rem;
}

.marquee-wrapper {
  margin: 0rem 0rem;
  overflow: hidden;
}

.marquee-container {
  display: flex;
  width: 80rem;
  animation: marquee 5s linear infinite;
}

.marquee-container img {
  margin-right: 10px;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-50%);
  }

}

@keyframes slide {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes beast {
  0% {
    transform: scaleX(1) scaleY(1);
  }

  50% {
    transform: scaleX(1.1) scaleY(0.9);
  }

  100% {
    transform: scaleX(1) scaleY(1);
  }
}

/* Apply the animations to the heading */
@keyframes slide {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes beast {
  0% {
    color: initial;
  }

  50% {
    color: #38f53b;
    /* Change color to your preference */
  }

  100% {
    color: initial;
  }
}

.budget h1 {
  font-size: 2.1rem;
  font-weight: 800;
  padding: 1.5rem 0rem 5.5rem;
  animation: slide 1s ease, beast 2s infinite;
  text-align: center;
  /* margin-bottom: 5rem; */
}

.headingcase h1 {
  font-size: 2.1rem;
  font-weight: 800;
  padding: 1.5rem 0rem 0rem;
  animation: slide 1s ease, beast 2s infinite;
  text-align: center;
}

.solution-page {
  height: auto;
  width: 100%;
  background-color: azure;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.solution-mainbox {
  margin: 2rem;
  height: auto;
  width: auto;
  padding: 2rem;
  border-radius: 1rem;
  background-color: #D4FCE9;
  border-left: 5px solid #69E48E;
  ;
  border-bottom: 5px solid #69E48E;
  ;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.solution-content-boxes {
  display: flex;
  gap: 2rem;
}

.solution-subbox {
  height: 11rem;
  width: 32rem;
  background-color: whitesmoke;
  border: 3px solid;
  border-radius: 15px;
  display: flex;
  gap: 10px;
  padding: 1rem;
  justify-content: center;

}

.health-sl::before {
  color: red;
}

.solution-info {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.solution-info span {
  font-weight: 500;
  font-size: 23px;
  color: #1d1d1b;
}

.solution-info p {
  font-size: 17px;
}

.solution-subbox:hover {
  border: 3px solid #0693e3;
}

.health-sl {
  font-size: 30px;
  color: #ed8ec2;
}

.book-sl {
  font-size: 30px;
  color: #276f8d;
}

.ad-sl {
  font-size: 30px;
  color: #0dafc1;
}

.client-sl {
  font-size: 30px;
  color: #f17781;
}

.plane-sl {
  font-size: 30px;
  color: #0a4562;
}

.doller-sl {
  font-size: 30px;
  color: #209a3c;
}

.solution-subbox {
  transition: color 0.3s ease;
}

.solution-subbox:hover {
  border: 3px solid #0693e3;
}

.solution-subbox:hover .health-sl {
  fill: #0a9a65;
}

.solution-subbox:hover .plane-sl {
  fill: #d25c2d;
}

.solution-subbox:hover .ad-sl {
  fill: #a22781;
}

.solution-subbox:hover .book-sl {
  fill: #c72bc2;
}

.solution-subbox:hover .client-sl {
  fill: #b33636;
}

.solution-subbox:hover .doller-sl {
  fill: #d6c343;
}



.scroll-image {
  height: auto;
  width: 100%;
  background-color: #f1eded;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0rem;
  flex-direction: column;
  margin-top: 1rem;
}

.trust {
  font-size: 24px;
  color: #1D1B1B;
}

.fast {
  font-size: 24px;
  color: #38916b;
}

.sr-image {
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.srl1 {
  height: 8rem;
  width: 8rem;
  margin: 0rem 3rem;
}

.srl4 {
  height: 7rem;
  width: 7rem;
  margin: 0rem 3rem;
}

.marquee-wrapper {
  margin: 0rem 0rem;
  overflow: hidden;
}

.marquee-container {
  display: flex;
  width: 80rem;
  animation: marquee 15s linear infinite;
}

.marquee-container img {
  margin-right: 10px;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-50%);
  }

}

.img1 {
  border: 1px solid black;
  width: 12rem;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 0.5rem;
}

.LICImg {
  width: 5rem;
  height: 5rem;
}

.img2 {
  border: none !important;
}

.ImageGenerater {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.img4 {
  width: 4rem;
  height: 4rem;
}



.main-div {

  background-image: url("./Images/background.jpg");
  height: 43rem;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.sub-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 5rem;
}

.manimage img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0rem;
  height: 30rem;
  width: 35rem;
  position: static;
}

.threebox {
  display: flex;
  justify-content: center;
  gap: 6rem;
  padding-top: 6rem;
  padding-bottom: -5rem;

}

.box1 {
  height: 55px;
  width: 190px;
  border-radius: 20px 2px;
  border: #52c023 solid 1px;
  background-color: #fff;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
}

.box1 span {
  font-size: 1.3rem;
  padding: .8rem 0rem;
  /* font-weight: 500; */
  text-align: center;
  display: flex;
  justify-content: center;

}

.box2 {
  height: 55px;
  width: 190px;
  border-radius: 20px 2px;
  border: #52c023 solid 1px;
  background-color: #fff;
  margin-top: -5rem;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
}

.box2 span {
  font-size: 1.3rem;
  padding: .8rem 0rem;
  font-weight: 400;
  text-align: center;
  display: flex;
  justify-content: center;
}

.box3 span {
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: .8rem 0rem;
}

.box3 {
  height: 55px;
  width: 190px;
  border-radius: 20px 2px;
  border: #52c023 solid 1px;
  background-color: #fff;
  float: right;
  font-weight: 500;
  color: #111121;
  margin-top: 8rem !important;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
}

.box4 span {
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: .8rem 0rem;
}

.box4 {
  float: left;
  height: 55px;
  width: 190px;
  border-radius: 20px 2px;
  border: #52c023 solid 1px;
  background-color: #fff;
  font-weight: 500;
  color: #111121;
  margin-top: 5rem;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
}

.box5 span {
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: .8rem 0rem;
}

.box5 {
  float: right;
  height: 55px;
  width: 190px;
  border-radius: 20px 2px;
  border: #52c023 solid 1px;
  background-color: #fff;
  font-weight: 500;
  color: #111121;
  margin-top: 5rem;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
}

.box6 span {
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: .8rem 0rem;
}

.box6 {
  height: 55px;
  width: 190px;
  border-radius: 20px 2px;
  border: #52c023 solid 1px;
  background-color: #fff;
  float: left;
  font-weight: 500;
  color: #111121;
  margin-top: 8rem;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
}

.box7 span {
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: .8rem 0rem;
}

.box7 {
  float: right;
  height: 55px;
  width: 190px;
  border-radius: 20px 2px;
  border: #52c023 solid 1px;
  background-color: #fff;
  font-weight: 500;
  color: #111121;
  margin-top: 5rem;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);

}

.box8 span {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: .8rem 0rem;
}

.box8 {
  float: left;
  height: 55px;
  width: 190px;
  border-radius: 20px 2px;
  border: #52c023 solid 1px;
  background-color: #fff;
  font-weight: 500;
  color: #111121;
  margin-top: 5rem;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
  cursor: pointer;

}

.i1 {
  font-size: 22px;
  color: rgb(14, 179, 14);
  margin: 0rem .5rem;
}

.box1:hover {
  cursor: pointer;
  animation: tilt-shaking 120ms 2 linear;
  -moz-animation: tilt-shaking 120ms 2 linear;
  -webkit-animation: tilt-shaking 120ms 2 linear;
  -o-animation: tilt-shaking 120ms 2 linear;
}

.box2:hover {
  cursor: pointer;
  animation: tilt-shaking 120ms 2 linear;
  -moz-animation: tilt-shaking 120ms 2 linear;
  -webkit-animation: tilt-shaking 120ms 2 linear;
  -o-animation: tilt-shaking 120ms 2 linear;
}

.box3:hover {
  cursor: pointer;
  animation: tilt-shaking 120ms 2 linear;
  -moz-animation: tilt-shaking 120ms 2 linear;
  -webkit-animation: tilt-shaking 120ms 2 linear;
  -o-animation: tilt-shaking 120ms 2 linear;
}

.box4:hover {
  cursor: pointer;
  animation: tilt-shaking 120ms 2 linear;
  -moz-animation: tilt-shaking 120ms 2 linear;
  -webkit-animation: tilt-shaking 120ms 2 linear;
  -o-animation: tilt-shaking 120ms 2 linear;
}

.box5:hover {
  cursor: pointer;
  animation: tilt-shaking 120ms 2 linear;
  -moz-animation: tilt-shaking 120ms 2 linear;
  -webkit-animation: tilt-shaking 120ms 2 linear;
  -o-animation: tilt-shaking 120ms 2 linear;
}

.box6:hover {
  cursor: pointer;
  animation: tilt-shaking 120ms 2 linear;
  -moz-animation: tilt-shaking 120ms 2 linear;
  -webkit-animation: tilt-shaking 120ms 2 linear;
  -o-animation: tilt-shaking 120ms 2 linear;
}

.box7:hover {
  cursor: pointer;
  animation: tilt-shaking 120ms 2 linear;
  -moz-animation: tilt-shaking 120ms 2 linear;
  -webkit-animation: tilt-shaking 120ms 2 linear;
  -o-animation: tilt-shaking 120ms 2 linear;
}

.box8:hover {
  cursor: pointer;
  animation: tilt-shaking 120ms 2 linear;
  -moz-animation: tilt-shaking 120ms 2 linear;
  -webkit-animation: tilt-shaking 120ms 2 linear;
  -o-animation: tilt-shaking 120ms 2 linear;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(0eg);
  }

  75% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

#slider {
  margin-top: -12rem;
  padding: 0rem 2rem;
  /* animation: 3s slide-left; */
}

#slider1 {
  margin-top: -12rem;
  padding: 0rem 2rem;
  /* animation: 3s slide-right; */
}

@keyframes slide-left {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0%;
  }
}

@keyframes slide-right {
  from {
    margin-left: -100%;
  }

  to {
    margin-left: 0%;
  }
}

.feature-heading {
  margin: 2rem 0rem;
}

.feature-heading h2 {
  text-align: center;
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
  font-size: 40px;
  line-height: 55px;
  font-weight: 700;
  color: #2c2c51;
  animation: slide 1s ease, beast 2s infinite;
}

.feature-heading span {
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  color: #4b505e;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.Teamchart {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 4rem;
  padding: 0rem 6rem 3rem;

}

.Teamcharttext {
  height: auto;
  width: 170rem;
}

.Teamimg {
  margin-top: 6.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Teamimg1 {
  margin-top: -3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Teamchat h2 {
  font-size: 34px;
  font-weight: 500;
  color: #2c2c51;
  margin-bottom: 25px;
  line-height: 1.2;
  letter-spacing: 1px;
}

.Teamtext p {
  font-size: 19px;
  color: #44444e;
  line-height: 28px;
  font-weight: 400;
  width: 44vw;
  padding: 1rem 0rem;
}

.Checktext p {

  font-size: 16px;
  line-height: 28px;
  color: #4d4d5c;
  font-weight: 400;
  padding-left: 8px;
  letter-spacing: .5px;
  width: 38vw;
  padding: 0.5rem 0rem;

}

.itext span {
  font-weight: 600;
  color: #2c2c51;
}

.checki {
  font-size: 18px;
  color: #6ce73b;
  margin: 1rem 0.5rem;
}

.checkic {
  font-size: 30px;
  color: #6ce73b;
}

.checkic1 {
  font-size: 20px;
  color: #6ce73b;
  margin: 1rem 0.5rem;

}

.checki3 {
  font-size: 33px;
  color: #6ce73b;

}

.checki4 {
  font-size: 26px;
  color: #6ce73b;
}

.checki5 {
  font-size: 36px;
  color: #6ce73b;

}

.itext {
  display: flex;
  justify-content: start;
}

.Lineone {
  width: 80rem;
  display: flex;
  justify-content: center;
  margin: auto;
}

.Linetwo {
  width: 80rem;
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 2rem 0rem;
}

.screen1 {
  height: auto;
  width: 40rem;

}


@media (min-width :1200px) and (max-width:1500px) {
  .Lineone {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 2rem 1rem 0rem;
  }

  .Linetwo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 0rem 1rem;
  }

  .Main-feature {
    padding: 0rem 1rem;
  }

  .trust {
    text-align: center;
  }

  .marquee-wrapper {
    margin: 0rem 0rem;
    overflow: hidden;
    width: 65rem;
  }

  .api-form1 {
    width: 30rem !important;
  }

  .api-form3 {

    width: 30rem !important;
  }

  .form-fields-d {
    width: 28rem;
  }

  .msgman-img {
    height: 26rem;
    width: 23rem;
  }

  .get-top-row span {
    font-size: 21px;
    width: 65vw;
  }

  .get-form-content {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  .imgs1{
    border: 1px solid black;
    width: 11rem;
    padding: 10px 2rem;
    height: 8rem;
    margin-top: 10px;
    border-radius: 10px;
  }




}

@media (min-width :769px) and (max-width:1200px) {

  .Main-feature {
    padding: 0rem 1rem;
  }

  .Teamchart {
    flex-direction: column;
    gap: 2rem;
    margin-top: 1rem;
    padding: 0rem 1rem 0rem;
  }

  .Lineone {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 2rem 1rem 0rem;
  }

  .Linetwo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 0rem 1rem;
  }

  .screen1 {
    height: auto;
    width: 100%;
    margin-top: 1rem;
  }

  .Teamcharttext {
    height: auto;
    width: 100%;
  }

  .Teamtext p {
    text-align: center;
    width: 100%;
  }

  .Teamchat h2 {
    text-align: center;
    margin-bottom: 0px;
  }

  .Teamimg {
    margin-top: 0.5rem;
    padding: 0rem 0rem 2rem;
  }

  .Teamimg1 {
    margin-top: 0rem;
    padding: 0rem 0rem 2rem;
  }

  .Checktext p {
    width: 89vw;
  }

  .custom-bot {
    height: auto;
    width: 100% !important;
    background-color: #fff;
    /* border: 1px solid green; */
    /* padding: 0rem 1rem 2rem; */
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
    /* box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.2); */
    margin-bottom: 2rem;

  }

  .custom-bot:hover {
    border: 1px solid #2785ff;
    border-width: 2px 2px 2px 2p
  }

  .custom-bot2 {
    height: auto;
    width: 100%;
    background-color: #fff;
    /* border: 1px solid green; */
    /* padding: 0rem 0rem 2rem; */
    border-radius: 10px 10px 10px 10px;
    border: 3px solid #2785ff;
    box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
    /* box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.2); */
    margin-bottom: 2rem;
    margin-top: -2.8rem;


  }

  .icons span {
    color: #ff4a00;
    font-weight: 800;
    font-size: 16.5px;
  }

  .most {
    height: 2.5rem;
    width: 100%;
    background-color: #2785ff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    color: #fff;
    font-weight: 600;
    font-size: 1rem;

  }

  #star {
    font-size: 1.2rem;
    color: #FFFF00;

  }

  .botwala {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 0rem 1rem;
  }


  .platform {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0rem 0rem 0rem;
  }

  .chats h3 {
    text-align: center;
    margin: 1.5rem 0rem 0.8rem;
    font-size: 1.2rem;
  }

  .chats p {
    margin: auto;
    text-align: center;
    width: 23.5vw;
    color: rgb(0, 12, 45) !important;
    line-height: 1.5rem;
    font-size: 1.1rem;
    font-family: poppins-extralight, poppins, sans-serif;
  }

  .chats1 h3 {
    text-align: center;
    margin: 1.5rem 0rem 0.8rem;
    font-size: 1.2rem;
  }

  .chats1 p {
    margin: auto;
    text-align: center;
    width: 24vw;
    color: rgb(0, 12, 45) !important;
    line-height: 1.5rem;
    font-family: poppins-extralight, poppins, sans-serif;
    font-size: 1.1rem;
  }

  .chats2 h3 {
    text-align: center;
    margin: 1.5rem 0rem 0.8rem;
    font-size: 1.2rem;
  }

  .chats2 p {
    margin: auto;
    text-align: center;
    width: 23.5vw;
    line-height: 1.5rem;
    font-size: 1.1rem;
    color: rgb(0, 12, 45) !important;
    font-family: poppins-extralight, poppins, sans-serif;
  }


  .standard {
    height: 8rem;
    width: 8rem;
  }

  .pro {
    height: 8rem;
    width: 8rem;
  }

  .hub {
    color: #54595f;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 1.5rem 0rem 0rem 0rem;
    letter-spacing: 4px;
  }

  .icons b {
    font-size: 1rem;
    font-weight: 800;
  }


  .icons {
    padding: 0.5rem 0rem 0.3rem 4rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    font-weight: 500;

  }

  .one-time {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 16.5px;
  }

  .time-payment {
    text-align: center;
    height: auto;
    width: 100%;
    border: 1px dashed;
    border-color: cyan;
    background-color: #FBFCFF;
    border-radius: 10px 10px 10px 10px;
    padding: 1rem 1rem;
    margin-top: 1rem;
  }

  .time-payment h1 {
    font-weight: 700;
    font-size: 2.5rem;
  }

  .time-payment h4 {
    font-size: 1rem;
    margin-top: 0.1rem;
  }

  .save-charges {
    margin: auto;
    height: 2.5rem;
    width: 100%;
    background-color: #FFFF00;
    padding: 8px 8px 8px 8px;
    border-radius: 6px 6px 6px 6px;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .save-charges b {
    margin: auto;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    text-align: center;
    align-items: center;

  }

  .zapier span {
    color: #ff4a00;
    text-align: center;
    font-weight: 600;
    display: flex;
    justify-content: center;
    padding: 0.6rem 0rem 0rem 0rem;
    font-size: 16.5px;
    line-height: 2em;
  }

  .zapier p {
    text-align: center;
    font-size: 1rem;
    color: #222222;
    font-weight: 700;
  }

  .buy-standard {
    display: flex;
    justify-content: center;
    margin: 1rem 0rem;
  }

  .buy-standard button {
    padding: 0rem 2rem !important;
    height: 3.8rem;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;
    fill: #2785ff;
    color: #2785ff;
    background-color: #fbfbfb;
    border-style: solid;
    border-color: #2785ff;
    border-radius: 8px 8px 8px 8px;
    padding: 12px 0px 12px 0px;
    cursor: pointer;
  }

  #buy {
    padding: 0rem 1rem;
  }

  #buy button {
    height: 3.8rem;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;
    fill: #2785ff;
    color: #fbfbfb;
    background-color: #2785ff;
    border-style: solid;
    border-color: #2785ff;
    border-radius: 8px 8px 8px 8px;
    padding: 12px 0px 12px 0px;
    cursor: pointer;
  }



  .buy-standard button:hover {
    color: #fff;
    background-color: #2785ff;
  }





  .buy-in {
    text-align: center;
    font-size: 18px;
    fill: #2785ff;
    color: #2785ff;
    font-weight: 600;
    padding: 0rem 0rem 2rem;
    cursor: pointer;
  }

  .buy-in:hover {
    color: #ff2323;
  }


  .line2 {
    padding: 0rem 0rem 1.5rem 0rem;
  }

  #lines {
    margin: auto;
    width: 18rem;
    color: #212529;

  }

  .internal {
    font-size: 16px;
    color: #030025;
    transition: color 0.3s;
  }

  #ic {
    font-size: 0.9rem;
  }

  #ic1 {
    font-size: 0.9rem;
    color: green;
  }


  #ic3 {
    font-size: 1rem;
    font-weight: bold;
    color: #ff2323;
  }

  .internal {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1rem;
    padding: 0.2rem 1rem;
    font-size: 1rem;

  }

  .logobox1 {
    height: 8.5rem;
    width: 8.5rem;
    border: 2px solid green;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 0.5rem;
    border-radius: 50%;
    /* animation: blingAnimation 2s infinite;  */
  }

  .logobox {
    height: 8rem;
    width: 10.5rem;
    border: 2px solid green;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 0.5rem;
    /* animation: blingAnimation 2s infinite;  */
  }

  .Unlimited {
    margin: auto;
    height: 4rem;
    width: 4rem;
    border-radius: 1px;
    border: 2px solid green;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    justify-content: center;

  }

  .Unlimited1 {
    margin: auto;
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 1px;
    border: 2px solid green;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    justify-content: center;

  }

  .messagebox {
    font-size: 2.8rem;
    color: green;
    animation: blingAnimation 2s infinite;
  }

  .logotext {
    margin: 0rem 0rem .4rem;
  }

  .logotext p {
    margin: auto;
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 4px;
    color: green;
  }

  .logotext1 {
    margin: 0rem 0rem 1.5rem;
  }

  .logotext1 p {
    margin: auto;
    text-align: center;
    font-size: 15px;
    letter-spacing: 4px;
    color: green;
  }


  @keyframes blingAnimation {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    50% {
      transform: scale(1.2);
      opacity: 0.7;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes circularWaveAnimation {
    0% {
      transform: scale(0.5);
      opacity: 1;
    }

    150% {
      transform: scale(1.2);
      opacity: 0.5;
    }

    170% {
      transform: scale(2);
      opacity: 0;
    }
  }

  .logobox {

    animation: circularWaveAnimation 2s infinite;
  }

  .logobox1 {

    animation: circularWaveAnimation 2s infinite;
  }











  




























































  .scroll-image {
    height: auto;
    width: 100%;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0rem;
    flex-direction: column;
    margin-top: 1rem;
  }

  .manimage img {
    height: 30rem;
    width: 13rem;
    padding: 4rem 0rem;
  }

  .sub-div {
    padding: 1rem 4rem;
  }

  .main-div {
    background-image: url("./Images/background.jpg");
    height: auto;
    width: 100%;
  }

  .threebox {
    gap: 0rem;
  }

  .trust {
    text-align: center;
  }

  .marquee-wrapper {
    margin: 0rem 0rem;
    overflow: hidden;
    width: 45rem;
  }

  .solution-subbox {
    height: auto;
    width: 100%;
  }

  .solution-main {
    width: 100%;
    margin: 0rem 2rem;

  }

  .get-form-content {
    width: 100%;
    flex-direction: column;
  }

  .gets-form {
    width: 100% !important;
    height: auto;
    padding: 0rem 5rem;
  }

  .get-top-row span {
    line-height: 1.5rem;
    font-size: 20px !important;
  }

  .get-top-row {
    padding: 1rem 10px;
  }

  .api-form3 {
    height: 15rem !important;
    width: 100% !important;
    padding: 1rem;
    margin: 1rem 0rem;
  }

  .sub-msg {
    width: 100% !important;
  }

  .api-form1 {
    width: 100% !important;
    height: auto !important;
  }

  .get-api-session {
    height: auto;
    width: 100%;
    margin: 1rem auto;

  }

  .form-fields-d {

    height: 40px;
    width: 100%;
  }

  .get-api-main {
    margin: 2rem 1rem;

  }

  .api-form1-content {
    margin: 0rem;
    padding: 20px;
  }

  .get-form-content {
    padding: 0rem 1rem;
    justify-content: center !important;
    gap: 1rem;
    margin-top: 1rem;

  }

  .get-top-row span {
    font-size: 18px;
    text-align: center;
    width: 100vw;
  }

  .get-api-form {
    margin-top: 0rem;
    gap: 1rem;
    padding: 0rem 14rem;
  }

  .gets-main {
    width: 100%;
    height: auto;
  }

  .msgman-img {
    height: auto;
    width: 100%;
  }

  .faq-item {
    margin: 1rem 3.7rem;
  }

  .break p {
    width: 37vw;
  }

  #slid {
    gap: 23rem;
  }

  .engage {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .better-way {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

  }

  .thousands {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

  }

  .programs {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

  }

  .barriers {
    padding: 0px 5px;
  }

  .ig {
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    height: 7rem;
    width: 7rem;
  }
  .imgs1{
    border: 1px solid black;
    width: 12rem;
    padding: 10px 2rem;
    height: 8rem;
    margin-top: 10px;
    border-radius: 10px;
  }


}

@media (min-width :499px) and (max-width:769px) {
  .Main-feature {
    padding: 0rem 1rem;
  }

  .feature-heading h2 {
    line-height: 40px;
  }

  .Teamchart {
    flex-direction: column;
    gap: 2rem;
    margin-top: 1rem;
    padding: 0rem 1rem 0rem;
  }

  .Lineone {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 2rem 1rem 0rem;
  }

  .Linetwo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 0rem 1rem;
  }

  .screen1 {
    height: auto;
    width: 100%;
    margin-top: 1rem;
  }

  .Teamcharttext {
    height: auto;
    width: 100%;
  }

  .Teamtext p {
    text-align: center;
    width: 100%;
  }

  .Teamchat h2 {
    text-align: center;
    margin-bottom: 0px;
  }

  .Teamimg {
    margin-top: 0.5rem;
    padding: 0rem 0rem 2rem;
  }

  .Teamimg1 {
    margin-top: 0rem;
    padding: 0rem 0rem 2rem;
  }

  .Checktext p {
    width: 89vw;
  }

  .budget h1 {
    font-size: 2.5rem;
    font-weight: 800;
    padding: 2rem 0rem 2.5rem;
    animation: slide 1s ease, beast 2s infinite;
    text-align: center;
    /* margin-bottom: 5rem; */
  }

  .custom-bot {
    height: auto;
    width: 100%;

    /* border: 1px solid green; */
    /* padding: 0rem 1rem 2rem; */
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
    /* box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.2); */
    margin-bottom: 1.5rem;
  }

  .custom-bot:hover {
    border: 1px solid #2785ff;
    border-width: 2px 2px 2px 2px;
  }

  .custom-bot2 {
    height: auto;
    width: 100%;
    background-color: #fff;
    /* border: 1px solid green; */
    /* padding: 0rem 0rem 2rem; */
    margin: 0rem 1rem;
    border-radius: 10px 10px 10px 10px;
    border: 3px solid #2785ff;
    box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
    /* box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.2); */
    margin-bottom: 1.5rem;
    margin-top: 0rem;
  }

  .icons span {
    color: #ff4a00;
    font-weight: 800;
    font-size: 16.5px;
  }

  .most {
    height: 4rem;
    width: 100%;
    background-color: #2785ff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    color: #fff;
    font-weight: 600;
    font-size: 1.4rem;

  }

  #star {
    font-size: 1.7rem;
    color: #FFFF00;

  }

  .botwala {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0rem;
    flex-direction: column;

    padding: 0rem 1rem;
  }


  .platform {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0rem 0rem 0rem;
  }

  .chats h3 {
    text-align: center;
    margin: 1.5rem 0rem 0.8rem;
    font-size: 2rem;
  }

  .chats p {
    margin: auto;
    text-align: center;
    width: 50.5vw;
    color: rgb(0, 12, 45) !important;
    line-height: 1.5rem;
    font-size: 1.1rem;
    font-family: poppins-extralight, poppins, sans-serif;
  }

  .chats1 h3 {
    text-align: center;
    margin: 1.5rem 0rem 0.8rem;
    font-size: 2rem;
  }

  .chats1 p {
    margin: auto;
    text-align: center;
    width: 24vw;
    color: rgb(0, 12, 45) !important;
    line-height: 1.5rem;
    font-family: poppins-extralight, poppins, sans-serif;
    font-size: 1.1rem;
  }

  .chats2 h3 {
    text-align: center;
    margin: 1.5rem 0rem 0.8rem;
    font-size: 2rem;
  }

  .chats2 p {
    margin: auto;
    text-align: center;
    width: 23.5vw;
    line-height: 1.5rem;
    font-size: 1.1rem;
    color: rgb(0, 12, 45) !important;
    font-family: poppins-extralight, poppins, sans-serif;
  }


  .standard {
    height: 8rem;
    width: 8rem;
  }

  .pro {
    height: 8rem;
    width: 8rem;
  }

  .hub {
    color: #54595f;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 1.5rem 0rem 0rem 0rem;
    letter-spacing: 4px;
  }

  .icons b {
    font-size: 1.5rem;
    font-weight: 800;

  }


  .icons {
    padding: 0.5rem 0rem 0.3rem 8rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.5rem;
    font-weight: 500;

  }

  .one-time {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
  }

  .time-payment {
    text-align: center;
    height: auto;
    width: 100%;
    border: 1px dashed;
    border-color: cyan;
    background-color: #FBFCFF;
    border-radius: 10px 10px 10px 10px;
    padding: 1rem 0rem;
    margin-top: 1rem;
  }

  .time-payment h1 {
    font-weight: 700;
    font-size: 3rem;
  }

  .time-payment h4 {
    font-size: 1rem;
    margin-top: 0.1rem;
  }

  .save-charges {
    margin: auto;
    height: 2.5rem;
    width: 10rem;
    background-color: #FFFF00;
    padding: 8px 8px 8px 8px;
    border-radius: 6px 6px 6px 6px;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .save-charges b {
    margin: auto;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    text-align: center;
    align-items: center;

  }
  .zapier span {
    color: #ff4a00;
    text-align: center;
    font-weight: 600;
    display: flex;
    justify-content: center;
    padding: 0.6rem 0rem 0rem 0rem;
    font-size: 18px;
    line-height: 2em;
  }

  .zapier p {
    text-align: center;
    font-size: 1.3rem;
    color: #222222;
    font-weight: 700;
  }

  .buy-standard {
    display: flex;
    justify-content: center;
    margin: 1rem 0rem;
    padding: 0rem 3rem;
  }

  .icontext {
    margin: auto;
  }

  .buy-standard button {
    padding: 0rem 2rem !important;
    height: 3.8rem;
    width: 100%;
    font-size: 25px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;
    fill: #2785ff;
    color: #2785ff;
    background-color: #fbfbfb;
    border-style: solid;
    border-color: #2785ff;
    border-radius: 8px 8px 8px 8px;
    padding: 12px 0px 12px 0px;
    cursor: pointer;
  }

  #buy button {
    height: 3.8rem;
    width: 100%;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;
    fill: #2785ff;
    color: #fbfbfb;
    background-color: #2785ff;
    border-style: solid;
    border-color: #2785ff;
    border-radius: 8px 8px 8px 8px;
    padding: 12px 0px 12px 0px;
    cursor: pointer;
  }



  .buy-standard button:hover {
    color: #fff;
    background-color: #2785ff;
  }

  .buy-in {
    text-align: center;
    font-size: 22px;
    fill: #2785ff;
    color: #2785ff;
    font-weight: 600;
    padding: 0rem 0rem 1.5rem;
    cursor: pointer;
  }

  .buy-in:hover {
    color: #ff2323;
  }


  .line2 {
    padding: 0rem 1rem .5rem;
  }

  #lines {
    margin: auto;
    width: 100%;
    color: #212529;

  }

  .internal {
    font-size: 16px;
    color: #030025;
    transition: color 0.3s;
  }

  #ic {
    font-size: 1.5rem;
  }

  #ic1 {
    font-size: 1.5rem;
    color: green;
  }


  #ic3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ff2323;
  }

  .internal {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1rem;
    padding: 0.2rem 2rem;
    font-size: 1.6rem;


  }

  .logobox1 {
    height: 8.5rem;
    width: 8.5rem;
    border: 2px solid green;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 0.5rem;
    border-radius: 50%;
    /* animation: blingAnimation 2s infinite;  */
  }

  .logobox {
    height: 8rem;
    width: 10.5rem;
    border: 2px solid green;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 0.5rem;
    /* animation: blingAnimation 2s infinite;  */
  }

  .Unlimited {
    margin: auto;
    height: 4rem;
    width: 4rem;
    border-radius: 1px;
    border: 2px solid green;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    justify-content: center;

  }

  .Unlimited1 {
    margin: auto;
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 1px;
    border: 2px solid green;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    justify-content: center;

  }

  .messagebox {
    font-size: 2.8rem;
    color: green;
    animation: blingAnimation 2s infinite;
  }

  .logotext {
    margin: 0rem 0rem .4rem;
  }

  .logotext p {
    margin: auto;
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 4px;
    color: green;
  }

  .logotext1 {
    margin: 0rem 0rem 1.5rem;
  }

  .logotext1 p {
    margin: auto;
    text-align: center;
    font-size: 15px;
    letter-spacing: 4px;
    color: green;
  }


  @keyframes blingAnimation {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    50% {
      transform: scale(1.2);
      opacity: 0.7;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes circularWaveAnimation {
    0% {
      transform: scale(0.5);
      opacity: 1;
    }

    150% {
      transform: scale(1.2);
      opacity: 0.5;
    }

    170% {
      transform: scale(2);
      opacity: 0;
    }
  }

  .logobox {

    animation: circularWaveAnimation 2s infinite;
  }

  .logobox1 {

    animation: circularWaveAnimation 2s infinite;
  }

  .scroll-image {
    height: auto;
    width: 100%;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0rem;
    flex-direction: column;
    margin-top: 1rem;
  }

  .sub-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 1rem;

  }

  .manimage img {

    justify-content: center;
    align-items: center;
    padding: 1rem 1rem;
    height: 22rem;
    width: 100% !important;
    position: unset;
    flex-direction: column-reverse;
    margin-top: -2.5rem;

  }

  .threebox {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 2rem 1rem;
    flex-direction: column;

  }

  .box1 {
    height: auto;
    width: 100%;

  }

  .box3 {
    margin-top: 12rem !important;
    padding-top: 0rem;
    width: 100% !important;
    height: auto;
  }

  .box2 {
    margin-top: 0rem;
    width: 100%;

  }

  .box5 {
    margin-top: 3rem;
    width: 100% !important;
    height: auto;
    padding: 0rem 5rem;

  }

  .box4 {
    margin-top: 3rem;
    width: 100%;
  }

  .box6 {
    margin-top: 12rem;
    height: auto;
    width: 100%;
  }

  .box7 {
    margin-top: 2rem;
    width: 100%;
    height: auto;
  }

  .box8 {
    margin-top: 2rem;
    width: 100%;
    height: auto;
  }

  #slider {
    margin-top: -13rem;
    padding: 0rem 0rem;
  }

  #slider1 {
    padding: 0rem 0rem !important;
  }

  .slider3 {
    padding: 0rem 2rem;
  }

  .main-div {
    background-image: url("./Images/background.jpg");
    height: auto;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .api-form3 {
    height: 15rem !important;
    width: 100% !important;
    padding: 1rem;
    margin: 1rem 0rem;
  }

  .sub-msg {
    width: 100% !important;
  }

  .get-form-content {
    width: 100%;
    flex-direction: column;
  }

  .gets-form {
    width: 100% !important;
    height: auto;
  }

  .get-top-row span {
    line-height: 1.5rem;
    font-size: 16px !important;
  }

  .get-top-row {
    padding: 1rem 10px;
  }

  .api-form1 {
    width: 100% !important;
    height: auto !important;
  }

  .get-api-session {
    height: auto;
    width: 100%;
    margin: 1rem auto;

  }

  .form-fields-d {

    height: 40px;
    width: 100%;
  }

  .get-api-main {
    margin: 2rem 1rem;

  }

  .api-form1-content {
    margin: 0rem;
    padding: 10px;
  }

  .get-form-content {
    padding: 0rem 1rem;
    gap: 1rem;
  }

  .get-top-row span {
    font-size: 18px;
    text-align: center;
    width: 100vw;
  }

  .get-api-form {
    margin-top: -5rem;
    gap: 1rem;
    padding: 0rem 6rem;
  }

  .gets-main {
    width: 100%;
    height: auto;
  }

  .msgman-img {
    height: auto;
    width: 100%;
  }

  .trust {
    text-align: center;
  }

  .marquee-wrapper {
    margin: 0rem 0rem;
    overflow: hidden;
    width: 25rem;
  }

  .srl1 {
    height: 4rem;
    width: 5rem;
    margin: 0rem 1rem;
  }

  .srl4 {
    height: 4rem;
    width: 5rem;
    margin: 0rem 1rem;
  }

  .img1 {
    border: 1px solid black;
    width: 10rem;
    padding: 1rem 0rem;
    height: 6rem;
  }
  .imgs1{
    border: 1px solid black;
    width: 10rem;
    padding: 10px 2rem;
    height: 6rem;
    margin-top: 9px;
    border-radius: 10px;
  }

  .LICImg {
    width: 3rem;
    height: 4rem;
  }

  .img4 {
    width: 3rem;
    height: 4rem;
  }

  .solution-subbox {
    height: auto;
    width: 100%;
  }

  .solution-main {
    width: 100%;
    margin: 0rem 2rem;

  }

  .solution-content-boxes {
    display: flex;
    gap: 2rem;
    flex-direction: column;
  }

  .faq-item {
    margin: 1rem 1.7rem;
  }

  .break p {
    width: 57vw;
  }

  #slid {
    gap: 15rem;
  }

  .engage {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .better-way {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

  }

  .thousands {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

  }

  .programs {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

  }

  .barriers {
    padding: 0px 5px;
  }

  .ig {
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    height: 4rem;
    width: 4rem;
  }
}

@media (min-width :349px) and (max-width:499px) {
  .Main-feature {
    padding: 0rem 1rem;
  }

  .Teamchart {
    flex-direction: column;
    gap: 1rem;
    margin-top: -2rem;
    padding: 0rem 1rem 0rem;
  }

  .feature-heading span {
    text-align: center;
  }

  .feature-heading h2 {
    line-height: 37px;
    font-size: 30px;
  }

  .Lineone {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 2rem 1rem 0rem;
  }

  .Linetwo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 0rem 1rem;
  }

  .screen1 {
    height: auto;
    width: 100%;
    margin-top: 1rem;
  }

  .Teamcharttext {
    height: auto;
    width: 100%;
  }

  .Teamtext p {
    text-align: center;
    width: 100%;
  }

  .Teamchat h2 {
    margin-top: 1rem;
    font-size: 27px;
    text-align: center;
    margin-bottom: 0px;
  }

  .Teamimg {
    margin-top: 0.5rem;
    padding: 0rem 0rem 1rem;
  }

  .Teamimg1 {
    margin-top: 0rem;
    padding: 0rem 0rem 2rem;
  }

  .Checktext p {
    width: 100vw;
    line-height: 23px;
  }

  .budget h1 {
    font-size: 2.1rem;
    font-weight: 800;
    padding: 2rem 0rem 2.5rem;
    animation: slide 1s ease, beast 2s infinite;
    text-align: center;
    /* margin-bottom: 5rem; */
  }

  .custom-bot {
    height: auto;
    width: 100%;

    /* border: 1px solid green; */
    /* padding: 0rem 1rem 2rem; */
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
    /* box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.2); */
    margin-bottom: 1.5rem;
  }

  .custom-bot:hover {
    border: 1px solid #12dc19;
    border-width: 2px 2px 2px 2px;
  }

  .custom-bot2 {
    height: auto;
    width: 100%;
    background-color: #fff;
    /* border: 1px solid green; */
    /* padding: 0rem 0rem 2rem; */
    border-radius: 10px 10px 10px 10px;
    border: 3px solid #12dc19;
    box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
    /* box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.2); */
    margin-bottom: 1.5rem;
    margin-top: 0rem;
  }

  .icons span {
    color: #ff4a00;
    font-weight: 800;
    font-size: 16.5px;
  }

  .most {
    height: 2.5rem;
    width: 100%;
    background-color: #2785ff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    color: #fff;
    font-weight: 600;
    font-size: 1.1rem;

  }

  #star {
    font-size: 1.5rem;
    color: #FFFF00;

  }

  .botwala {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0rem;
    flex-direction: column;

    padding: 0rem 1rem;
  }


  .platform {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0rem 0rem 0rem;
  }

  .chats h3 {
    text-align: center;
    margin: 1.5rem 0rem 0.8rem;
    font-size: 1.3rem;
  }

  .chats p {
    margin: auto;
    text-align: center;
    width: 50.5vw;
    color: rgb(0, 12, 45) !important;
    line-height: 1.5rem;
    font-size: 1.1rem;
    font-family: poppins-extralight, poppins, sans-serif;
  }

  .chats1 h3 {
    text-align: center;
    margin: 1.5rem 0rem 0.8rem;
    font-size: 1.2rem;
  }

  .chats1 p {
    margin: auto;
    text-align: center;
    width: 24vw;
    color: rgb(0, 12, 45) !important;
    line-height: 1.5rem;
    font-family: poppins-extralight, poppins, sans-serif;
    font-size: 1.1rem;
  }

  .chats2 h3 {
    text-align: center;
    margin: 1.5rem 0rem 0.8rem;
    font-size: 1.2rem;
  }

  .chats2 p {
    margin: auto;
    text-align: center;
    width: 23.5vw;
    line-height: 1.5rem;
    font-size: 1.1rem;
    color: rgb(0, 12, 45) !important;
    font-family: poppins-extralight, poppins, sans-serif;
  }


  .standard {
    height: 8rem;
    width: 8rem;
  }

  .pro {
    height: 8rem;
    width: 8rem;
  }

  .hub {
    color: #54595f;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 1.5rem 0rem 0rem 0rem;
    letter-spacing: 4px;
  }

  .icons b {
    font-size: 1rem;
    font-weight: 800;

  }


  .icons {
    padding: 0.5rem 0rem 0.3rem 4rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    font-weight: 500;

  }

  .one-time {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
  }

  .time-payment {
    text-align: center;
    height: auto;
    width: 100%;
    border: 1px dashed;
    border-color: cyan;
    background-color: #FBFCFF;
    border-radius: 10px 10px 10px 10px;
    padding: 1rem 0rem;
    margin-top: 1rem;
  }

  .time-payment h1 {
    font-weight: 700;
    font-size: 2.5rem;
  }

  .time-payment h4 {
    font-size: 1rem;
    margin-top: 0.1rem;
  }

  .save-charges {
    margin: auto;
    height: 2.5rem;
    width: 11rem;
    background-color: #FFFF00;
    padding: 8px 8px 8px 8px;
    border-radius: 6px 6px 6px 6px;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .save-charges b {
    margin: auto;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    text-align: center;
    align-items: center;

  }

  .zapier span {
    color: #ff4a00;
    text-align: center;
    font-weight: 600;
    display: flex;
    justify-content: center;
    padding: 0.6rem 0rem 0rem 0rem;
    font-size: 16.5px;
    line-height: 2em;
  }

  .zapier p {
    text-align: center;
    font-size: 1rem;
    color: #222222;
    font-weight: 700;
  }

  .buy-standard {
    display: flex;
    justify-content: center;
    margin: 1rem 0rem;
    padding: 0rem 1rem;
  }

  .icontext {
    margin: auto;
  }

  .buy-standard button {
    padding: 0rem 2rem !important;
    height: 3.8rem;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;
    fill: #2785ff;
    color: #2785ff;
    background-color: #fbfbfb;
    border-style: solid;
    border-color: #2785ff;
    border-radius: 8px 8px 8px 8px;
    padding: 12px 0px 12px 0px;
    cursor: pointer;
  }

  #buy button {
    height: 3.8rem;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;
    fill: #2785ff;
    color: #fbfbfb;
    background-color: #2785ff;
    border-style: solid;
    border-color: #2785ff;
    border-radius: 8px 8px 8px 8px;
    padding: 12px 0px 12px 0px;
    cursor: pointer;
  }



  .buy-standard button:hover {
    color: #fff;
    background-color: #2785ff;
  }

  .buy-in {
    text-align: center;
    font-size: 18px;
    fill: #2785ff;
    color: #2785ff;
    font-weight: 600;
    padding: 0rem 0rem 1rem;
    cursor: pointer;
  }

  .buy-in:hover {
    color: #ff2323;
  }


  .line2 {
    padding: 0rem 1rem 0.5rem;
  }

  #lines {
    margin: auto;
    width: 100%;
    color: #212529;
    padding: 0rem 1rem 0.5rem;
  }

  .internal {
    font-size: 16px;
    color: #030025;
    transition: color 0.3s;
  }

  #ic {
    font-size: 0.9rem;
  }

  #ic1 {
    font-size: 0.9rem;
    color: green;
  }


  #ic3 {
    font-size: 1rem;
    font-weight: bold;
    color: #ff2323;
  }

  .internal {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1rem;
    padding: 0.2rem 2rem;
    font-size: 1rem;


  }

  .logobox1 {
    height: 8.5rem;
    width: 8.5rem;
    border: 2px solid green;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 0.5rem;
    border-radius: 50%;
    /* animation: blingAnimation 2s infinite;  */
  }

  .logobox {
    height: 8rem;
    width: 10.5rem;
    border: 2px solid green;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 0.5rem;
    /* animation: blingAnimation 2s infinite;  */
  }

  .Unlimited {
    margin: auto;
    height: 4rem;
    width: 4rem;
    border-radius: 1px;
    border: 2px solid green;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    justify-content: center;

  }

  .Unlimited1 {
    margin: auto;
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 1px;
    border: 2px solid green;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    justify-content: center;

  }

  .messagebox {
    font-size: 2.8rem;
    color: green;
    animation: blingAnimation 2s infinite;
  }

  .logotext {
    margin: 0rem 0rem .4rem;
  }

  .logotext p {
    margin: auto;
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 4px;
    color: green;
  }

  .logotext1 {
    margin: 0rem 0rem 1.5rem;
  }

  .logotext1 p {
    margin: auto;
    text-align: center;
    font-size: 15px;
    letter-spacing: 4px;
    color: green;
  }


  @keyframes blingAnimation {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    50% {
      transform: scale(1.2);
      opacity: 0.7;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes circularWaveAnimation {
    0% {
      transform: scale(0.5);
      opacity: 1;
    }

    150% {
      transform: scale(1.2);
      opacity: 0.5;
    }

    170% {
      transform: scale(2);
      opacity: 0;
    }
  }

  .logobox {

    animation: circularWaveAnimation 2s infinite;
  }

  .logobox1 {

    animation: circularWaveAnimation 2s infinite;
  }

  .scroll-image {
    height: auto;
    width: 100%;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0rem;
    flex-direction: column;
    margin-top: 1rem;
  }

  .sub-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 1rem;

  }

  .manimage img {

    justify-content: center;
    align-items: center;
    padding: 1rem 1rem;
    height: 17rem;
    width: 100% !important;
    position: unset;
    flex-direction: column-reverse;
    margin-top: -2.3rem;

  }

  .threebox {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 2rem 1rem;
    flex-direction: column;

  }

  .box1 {
    height: auto;
    width: 100%;

  }

  .box3 {
    margin-top: 12rem !important;
    padding-top: 0rem;
    width: 100% !important;
    height: auto;
  }

  .box2 {
    margin-top: 0rem;
    width: 100%;

  }

  .box5 {
    margin-top: 2rem;
    width: 100% !important;
    height: auto;
    padding: 0rem 5rem;

  }

  .box4 {
    margin-top: 2rem;
    width: 100%;
  }

  .box6 {
    margin-top: 12rem;
    height: auto;
    width: 100%;
  }

  .box7 {
    margin-top: 2rem;
    width: 100%;
    height: auto;
  }

  .box8 {
    margin-top: 2rem;
    width: 100%;
    height: auto;
  }

  #slider {
    margin-top: -13rem;
    padding: 0rem 0rem;
  }

  #slider1 {
    padding: 0rem 0rem !important;
  }

  .main-div {
    background-image: url("./Images/background.jpg");
    height: auto;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .sub-msg span {
    font-size: 10px;
  }

  .api-form3 {
    height: 9rem !important;
    width: 100% !important;
    padding: 1rem;
    margin: 1rem 0rem;
  }

  .sub-msg {
    width: 100% !important;
    height: 7rem;
  }

  .get-form-content {
    width: 100%;
    flex-direction: column;
  }

  .gets-form {
    width: 100% !important;
    height: auto;
  }

  .get-top-row span {
    line-height: 1.5rem;
    font-size: 16px !important;
  }

  .get-top-row {
    padding: 1rem 10px;
  }

  .api-form1 {
    width: 100% !important;
    height: auto !important;
  }

  .get-api-session {
    height: auto;
    width: 100%;
    margin: 1rem auto;

  }

  .form-fields-d {

    height: 40px;
    width: 100%;
  }

  .get-api-main {
    margin: 2rem 1rem;

  }

  .api-form1-content {
    margin: 0rem;
    padding: 10px;
  }

  .get-form-content {
    padding: 0rem 1rem;
    gap: 1rem;
  }

  .get-top-row span {
    font-size: 18px;
    text-align: center;
    width: 100vw;
  }

  .get-api-form {
    margin-top: -5rem;
    padding: 0px 40px;
    gap: 1rem;
  }

  .gets-main {
    width: 100%;
    height: auto;
  }

  .msgman-img {
    height: auto;
    width: 100%;
  }

  .trust {
    text-align: center;
    font-size: 18px;
    padding: 0px 10px ;
  }
  .solution-page {
    margin-top: 1rem;
  }

  .fast {
    font-size: 18px;
  }

  .marquee-wrapper {
    margin: 0rem 0rem;
    overflow: hidden;
    width: 20rem;
  }

  .srl1 {
    height: 4rem;
    width: 5rem;
    margin: 0rem 1rem;
  }

  .srl4 {
    height: 4rem;
    width: 5rem;
    margin: 0rem 1rem;
  }

  .img1 {
    border: 1px solid black;
    width: 10rem;
    padding: 1rem 0rem;
    height: 6rem;

  }

  .LICImg {
    width: 3rem;
    height: 4rem;
  }

  .img4 {
    width: 3rem;
    height: 4rem;
  }

  .solution-subbox {
    height: auto;
    width: 100%;
  }

  .solution-main {
    width: 100%;
    margin: 0rem 2rem;
  }

  .solution-mainbox {
    margin: 1rem 10px;
    height: auto;
    width: auto;
    padding: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }

  .solution-content-boxes {
    display: flex;
    gap: 1rem;
    flex-direction: column;
  }

  .headingcase h1 {
    font-size: 23px;
  }

  .solution-info span {
    font-size: 22px;
  }

  .faq-item {
    margin: 1rem 1.7rem;
  }

  .faqtext h2 {
    padding: 1rem 0rem;
  }

  .break p {
    width: 82vw;
  }

  #slid {
    gap: 15rem;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: inline-block;
    width: 1rem;
    height: 1rem;
  }

  .barriers h1 {
    text-align: center;
    font-size: 24px;
  }

  .engage {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .better-way {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

  }

  .thousands {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

  }

  .programs {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

  }

  .barriers {
    padding: 0px 5px;
  }

  .ig {
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    height: auto;
    width: 100%;
    margin: 0px !important;
  }

  .wapi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0rem 5rem 0rem;
  }
  .imgs1{
    border: 1px solid black;
    width: 9rem;
    padding: 10px 2rem;
    height: 6rem;
    margin-top: 10px;
    border-radius: 10px;
  }




}

@media (min-width :250px) and (max-width:349px) {
  .imgs1{
    border: 1px solid black;
    width: 9rem;
    padding: 10px 2rem;
    height: 6rem;
    margin-top: 10px;
    border-radius: 10px;
  }

  .Main-feature {
    padding: 0rem 1rem;
  }

  .Teamchart {
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    padding: 0rem 1rem 0rem;
  }

  .feature-heading {
    margin-top: 1rem;
  }

  .feature-heading span {
    text-align: center;
    font-size: 14px;
  }

  .feature-heading h2 {
    line-height: 27px;
    font-size: 22px;

  }

  .Lineone {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 2rem 1rem 0rem;
  }

  .Linetwo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 0rem 1rem;
  }

  .screen1 {
    height: auto;
    width: 100%;
    margin-top: -1rem;
  }

  .Teamcharttext {
    height: auto;
    width: 100%;
  }

  .Teamtext p {
    text-align: center;
    width: 100%;
    font-size: 16px;
  }

  .Teamchat h2 {
    text-align: center;
    margin-bottom: 0px;
    font-size: 20px;
  }

  .Teamimg {
    margin-top: 0.5rem;
    padding: 0rem 0rem 2rem;
  }

  .Teamimg1 {
    margin-top: 0rem;
    padding: 0rem 0rem 2rem;
  }

  .Checktext p {
    width: 89vw;
    font-size: 13px;
  }

  .budget h1 {
    font-size: 1.7rem;
    font-weight: 800;
    padding: 2rem 0rem 1.5rem;
    animation: slide 1s ease, beast 2s infinite;
    text-align: center;
    /* margin-bottom: 5rem; */
  }

  .custom-bot {
    height: auto;
    width: 100%;

    /* border: 1px solid green; */
    /* padding: 0rem 1rem 2rem; */
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
    /* box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.2); */
    margin-bottom: 1.5rem;
  }

  .custom-bot:hover {
    border: 1px solid #2785ff;
    border-width: 2px 2px 2px 2px;
  }

  .custom-bot2 {
    height: auto;
    width: 100%;
    background-color: #fff;
    /* border: 1px solid green; */
    /* padding: 0rem 0rem 2rem; */
    border-radius: 10px 10px 10px 10px;
    border: 3px solid #2785ff;
    box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
    /* box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.2); */
    margin-bottom: 1.5rem;
    margin-top: 0rem;
  }

  .icons span {
    color: #ff4a00;
    font-weight: 800;
    font-size: 16.5px;
  }

  .most {
    height: 2.5rem;
    width: 100%;
    background-color: #2785ff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    color: #fff;
    font-weight: 600;
    font-size: 0.8rem;

  }

  #star {
    font-size: 0.9rem;
    color: #FFFF00;

  }

  .botwala {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0rem;
    flex-direction: column;
    padding: 0rem 1rem;
  }


  .platform {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0rem 0rem 0rem;
  }

  .chats h3 {
    text-align: center;
    margin: 1.5rem 0rem 0.8rem;
    font-size: 0.9rem;
  }

  .chats p {
    margin: auto;
    text-align: center;
    width: 60.5vw;
    color: rgb(0, 12, 45) !important;

    font-size: 0.8rem;
    font-family: poppins-extralight, poppins, sans-serif;
  }

  .chats1 h3 {
    text-align: center;
    margin: 1.5rem 0rem 0.8rem;
    font-size: 0.9rem;
  }

  .chats1 p {
    margin: auto;
    text-align: center;
    width: 24vw;
    color: rgb(0, 12, 45) !important;
    line-height: 1.5rem;
    font-family: poppins-extralight, poppins, sans-serif;
    font-size: 1.1rem;
  }

  .chats2 h3 {
    text-align: center;
    margin: 1.5rem 0rem 0.8rem;
    font-size: 0.9rem;
  }

  .chats2 p {
    margin: auto;
    text-align: center;
    width: 23.5vw;
    line-height: 1.5rem;
    font-size: 1.1rem;
    color: rgb(0, 12, 45) !important;
    font-family: poppins-extralight, poppins, sans-serif;
  }


  .standard {
    height: 8rem;
    width: 8rem;
  }

  .pro {
    height: 8rem;
    width: 8rem;
  }

  .hub {
    color: #54595f;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 1.5rem 0rem 0rem 0rem;
    letter-spacing: 4px;
  }

  .icons b {
    font-size: 0.8rem;
    font-weight: 800;

  }


  .icons {
    padding: 0.5rem 0rem 0.3rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.8rem;
    font-weight: 500;

  }

  .one-time {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
  }

  .time-payment {
    text-align: center;
    height: auto;
    width: 100%;
    border: 1px dashed;
    border-color: cyan;
    background-color: #FBFCFF;
    border-radius: 10px 10px 10px 10px;
    padding: 1rem 0.5rem;
    margin-top: 1rem;
  }

  .time-payment h1 {
    font-weight: 700;
    font-size: 2rem;
  }

  .time-payment h4 {
    font-size: 1rem;
    margin-top: 0.1rem;
  }

  .save-charges {
    margin: auto;
    height: 2.5rem;
    width: 100%;
    background-color: #FFFF00;
    padding: 8px 8px 8px 8px;
    border-radius: 6px 6px 6px 6px;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .save-charges b {
    margin: auto;
    display: flex;
    justify-content: center;
    font-size: 0.8rem;
    text-align: center;
    align-items: center;

  }

  .zapier span {
    color: #ff4a00;
    text-align: center;
    font-weight: 600;
    display: flex;
    justify-content: center;
    padding: 0.6rem 0rem 0rem 0rem;
    font-size: 16.5px;
    line-height: 2em;
  }

  .zapier p {
    text-align: center;
    font-size: 0.8rem;
    color: #222222;
    font-weight: 700;
  }

  .buy-standard {
    display: flex;
    justify-content: center;
    margin: 1rem 0rem;
    padding: 0rem 0.5rem;
  }

  .icontext {
    margin: auto;
  }

  .buy-standard button {
    padding: 0rem 2rem !important;
    height: 3rem;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;
    fill: #2785ff;
    color: #2785ff;
    background-color: #fbfbfb;
    border-style: solid;
    border-color: #2785ff;
    border-radius: 8px 8px 8px 8px;
    padding: 12px 0px 12px 0px;
    cursor: pointer;
  }

  #buy button {
    height: 2.8rem;
    width: 11rem;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;
    fill: #2785ff;
    color: #fbfbfb;
    background-color: #2785ff;
    border-style: solid;
    border-color: #2785ff;
    border-radius: 8px 8px 8px 8px;
    padding: 12px 0px 12px 0px;
    cursor: pointer;
  }



  .buy-standard button:hover {
    color: #fff;
    background-color: #2785ff;
  }

  .buy-in {
    text-align: center;
    font-size: 16px;
    fill: #2785ff;
    color: #2785ff;
    font-weight: 600;
    padding: 0rem 0rem 1rem;
    cursor: pointer;
  }

  .buy-in:hover {
    color: #ff2323;
  }


  .line2 {
    padding: 0rem 1rem 0.5rem;
  }

  #lines {
    margin: auto;
    width: 100%;
    color: #212529;

  }

  .internal {
    font-size: 16px;
    color: #030025;
    transition: color 0.3s;
  }

  #ic {
    font-size: 0.9rem;
  }

  #ic1 {
    font-size: 0.7rem;
    color:green;
  }


  #ic3 {
    font-size: 0.7rem;
    font-weight: bold;
    color: #ff2323;
  }

  .internal {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1rem;
    padding: 0.2rem 1rem;
    font-size: 0.6rem;


  }

  .logobox1 {
    height: 8.5rem;
    width: 8.5rem;
    border: 2px solid green;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 0.5rem;
    border-radius: 50%;
    /* animation: blingAnimation 2s infinite;  */
  }

  .logobox {
    height: 8rem;
    width: 10.5rem;
    border: 2px solid green;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 0.5rem;
    /* animation: blingAnimation 2s infinite;  */
  }

  .Unlimited {
    margin: auto;
    height: 4rem;
    width: 4rem;
    border-radius: 1px;
    border: 2px solid green;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    justify-content: center;

  }

  .Unlimited1 {
    margin: auto;
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 1px;
    border: 2px solid green;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    justify-content: center;

  }

  .messagebox {
    font-size: 2.8rem;
    color: green;
    animation: blingAnimation 2s infinite;
  }

  .logotext {
    margin: 0rem 0rem .4rem;
  }

  .logotext p {
    margin: auto;
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 4px;
    color: green;
  }

  .logotext1 {
    margin: 0rem 0rem 1.5rem;
  }

  .logotext1 p {
    margin: auto;
    text-align: center;
    font-size: 15px;
    letter-spacing: 4px;
    color: green;
  }


  @keyframes blingAnimation {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    50% {
      transform: scale(1.2);
      opacity: 0.7;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes circularWaveAnimation {
    0% {
      transform: scale(0.5);
      opacity: 1;
    }

    150% {
      transform: scale(1.2);
      opacity: 0.5;
    }

    170% {
      transform: scale(2);
      opacity: 0;
    }
  }

  .logobox {

    animation: circularWaveAnimation 2s infinite;
  }

  .logobox1 {

    animation: circularWaveAnimation 2s infinite;
  }

  .scroll-image {
    height: auto;
    width: 100%;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0rem;
    flex-direction: column;
    margin-top: 1rem;
  }

  .sub-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0rem;
  }

  .manimage img {

    justify-content: center;
    align-items: center;
    padding: 0rem 1rem 1rem;
    height: 11rem;
    width: 100%;
    position: unset;
    flex-direction: column-reverse;
    margin-top: -1rem;

  }

  .threebox {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 2rem 2rem;
    flex-direction: column-reverse;

  }

  .box1 {
    width: 100%;
  }

  .box3 {
    margin-top: 12rem !important;
    padding-top: 0rem;
    float: none;
    width: 100%;
  }

  .box2 {
    margin-top: 0rem;
    width: 100%;
  }

  .box5 {
    margin-bottom: 1rem ;
    margin-top: 2rem;
    width: 100%;
    padding: 0rem 3rem;

  }

  .box4 {
    margin-top: 2rem;
    width: 100%;
  }

  .box6 {
    margin-top: 12rem;
    width: 100%;
  }

  .box7 {
    margin-top: 2rem;
    width: 100%;
  }

  .box8 {
    margin-top: 2rem;
    width: 100%;
  }

  #slider {
    margin-top: -13rem;
    padding: 0rem 2rem;
  }

  #slider1 {
    padding: 0rem 2rem;
  }

  .main-div {
    background-image: url("./Images/background.jpg");
    height: auto;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .ig {
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    height: auto;
    width: 100%;
    margin: 0px !important;
  }

  .wapi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0rem 3rem 0rem;
  }

  .trust {
    text-align: center;
    font-size: 16px;
    padding: 0rem 0.5rem;
  }

  .fast {
    font-size: 16px;
  }

  .marquee-wrapper {
    margin: 0rem 0rem;
    overflow: hidden;
    width: 10rem;
  }

  .srl1 {
    height: 4rem;
    width: 5rem;
    margin: 0rem 1rem;
  }

  .srl4 {
    height: 4rem;
    width: 5rem;
    margin: 0rem 1rem;
  }

  .img1 {
    border: 1px solid black;
    width: 10rem;
    padding: 1rem 0rem;
    height: 6rem;
  }

  .LICImg {
    width: 3rem;
    height: 4rem;
  }

  .img4 {
    width: 3rem;
    height: 4rem;
  }

  .solution-subbox {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem 5px;
  }

  .solution-main {
    width: 100%;
    margin: 0rem 2rem;
  }

  .solution-info p {
    font-size: 15px;
  }

  .solution-mainbox {
    margin: 1rem 7px;
    height: auto;
    width: auto;
    padding: 1rem 8px;
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }
  .integration h1 {
    padding: 2rem 0rem 0.5rem;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
}

  .solution-content-boxes {
    display: flex;
    gap: 1rem;
    flex-direction: column;
  }

  .headingcase h1 {
    font-size: 18px;
    padding: 2rem 0rem 0rem;
  }

  .solution-info span {
    font-size: 17px;
  }

  .health-sl {
    font-size: 20px;
  }

  .sub-msg h1 {
    position: relative;
    font-size: 25px;
  }

  .api-form3 {
    height: 9rem !important;
    width: 100% !important;
    padding: 1rem;
    margin: 1rem 0rem;
  }

  .recieve-msg {
    font-size: 10px !important;
  }

  .sub-msg {
    width: 100% !important;
    height: 7rem;
  }

  .get-form-content {
    width: 100%;
    flex-direction: column;
  }

  .gets-form {
    width: 100% !important;
    height: auto;
  }

  .get-top-row span {
    line-height: 1.5rem;
    font-size: 16px !important;
  }

  .get-top-row {
    padding: 1rem 10px;
  }

  .api-form1 {
    width: 100% !important;
    height: auto !important;
    margin: 1rem 0rem;
  }

  .get-api-session {
    height: auto;
    width: 100%;
    margin: 1rem auto;

  }

  .form-fields-d {
    font-size: 10px !important;
    height: 34px;
    width: 100%;
  }

  .get-api-main {
    margin: 2rem 1rem;

  }

  .api-form1-content {
    margin: 0rem;
    padding: 10px 5px;
    gap: 12px !important;
  }

  .get-form-content {
    padding: 0rem 3px;
    gap: 0rem;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .next-btn {
    border: none;
    width: 5rem !important;
    height: 2rem !important;
  }

  .get-top-row span {
    font-size: 12px !important;
    line-height: 1rem;
    text-align: center;
    width: 100vw;
  }

  .api-form1-content span {
    font-size: 12px;
  }

  .in-with {
    display: flex;
    font-size: 15px;
  }

  .get-api-form {
    margin-top: -5rem;
    padding: 0px 12px;
    gap: 1rem;
  }

  .gets-main {
    width: 100%;
    height: auto;
  }

  .msgman-img {
    height: auto;
    width: 100%;
  }

  .faq-item {
    margin: 0rem 0.5rem;
  }

  .faqtext h2 {
    margin-top: 1rem;
    padding: 0rem 0rem;
  }

  .question {
    font-size: 18px;
  }

  .answer {
    padding: 0.5rem;
    font-size: 18px;
  }

  .mainfaq {
    margin-top: 0rem;
    flex-direction: column;
    display: flex;
    gap: 1rem;
  }

  .bairy {
    height: 10rem;
    width: 13rem;
  }

  .break p {
    width: 82vw;
  }

  .barriers {
    padding: 0px 5px;
  }

  #slid {
    gap: 15rem;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: inline-block;
    width: 1rem;
    height: 1rem;
  }

  .barriers h1 {
    text-align: center;
    font-size: 24px;
  }

  .engage {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .better-way {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

  }

  .thousands {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

  }

  .programs {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

  }

}

@media(min-width:1200px) and (max-width:1500px) {

  .SeccondContainer {
    text-align: center;
    margin-top: 4rem;
  }

  .serving {
    font-size: 3rem;
  }

  .pickIndustry {
    margin: auto;
    width: 48vw;
    font-size: 1.2rem;
    margin-top: -2rem;

  }

  .Mainclass {
    margin-top: -4rem;

  }

  .animation h1 {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    animation: typeWriter 1.5s steps(60) infinite, fadeIn 2s 4s forwards;
    color: #89dc12;
    margin-top: -1.2rem;
    font-size: 38px !important;
    padding: 1.5rem 0rem;
  }

  .purches {

    color: green;
    font-size: 16px;
  }

  .SubscriptionInfo {
    display: flex;
    gap: 15px;
    margin-top: 1.5rem;
  }

  @keyframes typeWriter {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .groth {
    font-size: 38px !important;
    width: 45vw;
    color: #1d2651;
    margin: 3rem 0rem;
  }

  .SuperMainClass {
    padding-top: 38px 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: -2rem; */
    background-image: url(./Images/01.png);
    /* /* font-family: "Poppins", sans-serif */
    font-family: "Poppins", sans-serif;
    font-family: "Raleway", sans-serif;
    gap: 7.3rem;


  }

  @keyframes blingAnimation {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    50% {
      transform: scale(1.2);
      opacity: 0.7;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .logob {
    width: 7rem !important;
    height: 7rem !important;
    background-color: rgb(243, 242, 236);
    /* Change the background color as needed */
    border-radius: 50%;
    animation: blingAnimation 2s infinite;
    /* Adjust the animation duration as needed */
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
  }




  .wtssrc {
    width: 25rem;
    /* Adjust the width as needed */
    height: auto;
    /* Maintain aspect ratio */
    border-radius: 15px;
    /* Add rounded corners if desired */
    margin-top: -2rem;


  }

  .Official {
    width: 40vw;
    font-size: 18px;
    color: #1d2651;
    text-align: justify;
    margin-top: -0.5rem;
  }

  .sub1 {
    /* color: rgb(0, 12, 45) !important;
              font-family: "Google Sans" !important;
              font-size: 1.2rem !important;
              line-height: 1.65rem !important;
              padding-bottom: 10px;
              letter-spacing: -0.1px; */
    /* animation: typeWriter 1.5s steps(60) infinite, fadeIn 2s 4s forwards; */
    /* /* word-spacing: -1.75px;  */

    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    /* animation: typeWriter 3.5s steps(60) infinite, fadeIn 2s 4s forwards; */
    animation: blingAnimation 2s infinite;
    color: #1d2651;
    margin-top: -1.2rem;
    font-size: 16px !important;
    padding: 1.5rem 0rem;
    font-weight: 400;


  }

  @keyframes fadeInScale {
    from {
      opacity: 0;
      transform: scale(0.8);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes pulseBackground {
    from {
      background-color: #f0f0f0;
    }

    to {
      background-color: #d1e9ff;
      /* Set your desired pulsating color */
    }
  }

  .demobtn {
    display: inline-block;
    padding: 17px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border-radius: 8px;
    /* Rounded corners */
    background: rgb(82, 192, 35);
    background: linear-gradient(90deg, rgba(0, 187, 57, 1) 0%, rgba(103, 198, 43, 1) 90%);
    color: #ffffff;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
    position: relative;
    overflow: hidden;
    border: none;
  }

  .demobtn:hover {
    background: linear-gradient(90deg, rgba(103, 198, 43, 1) 90%, rgba(0, 187, 57, 1) 0%);
    color: #fff;
    /* Change text color on hover */
    transform: scale(1.05) translateX(5px) translateY(5px);
    /* Add scale and movement effect on hover */
    animation: moveButton 1s infinite alternate;
    /* Add movement animation */
  }

  @keyframes moveButton {
    to {
      transform: translateX(0) translateY(0);
      /* Reset the movement on each iteration */
    }
  }

  .ply {
    position: relative;
    text-transform: uppercase;
    color: #111121;
    font-weight: 500;
    font-size: 18px;


  }

  .playclass a {
    text-decoration: none;
  }

  .greenLine {
    width: 50px;
    /* Adjust the width as needed */
    height: 2px;
    /* Adjust the height as needed */
    background-color: green;
  }

  .JoinDemo {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 2rem;
    margin-top: 1.3rem;

  }

  .plybtn {
    font-size: 40px;

    border: 2px solid #89dc12;
    padding: 0.7rem;
    border-radius: 30%;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
    background-color: #89dc12;
    color: #fff;
  }

  .plybtn:hover {
    color: #89dc12;
    background-color: #ffffff;
    transform: scale(1.05);
  }

  .animation,
  .groth {
    position: relative;
    left: -1000px;
    /* Start off-screen */
    animation: slideIn 1.5s ease-out forwards;
    /* Animation duration and timing function */
  }

  @keyframes slideIn {
    to {
      left: 0;
      /* Move to the original position */
    }
  }

  .wtssrc {
    position: relative;
    left: 100%;
    /* Start off-screen from the right side */
    animation: slideIn 1.5s ease-out forwards;
    /* Animation duration and timing function */
  }

  @keyframes slideIn {
    to {
      left: 0;
      /* Move to the original position */
    }
  }


  .whats-api {
    height: auto;
    width: 100%;
    background-color: #fafafa;
  }

  .marketing h1 {
    color: #000000;
    font-weight: bold;
    font-size: 30px;
    padding: 0rem 0rem 1.1rem;
  }

  .marketing p {
    width: 37vw;
    line-height: 1.9rem;
    font-size: 18px;
    color: #666666;
    font-family: poppins-extralight, poppins, sans-serif;
  }

  .Session {
    display: flex;
    justify-content: center;
    gap: 4rem;
    padding: 2rem 0rem;
  }

  .explore {
    padding: 2rem 0rem 0rem;
  }

  .explore button {
    cursor: pointer;
    transition: inherit;
    background-color: #000;
    color: #ffffff;
    height: 2.6rem;
    width: 15rem;
    font-size: 1.1rem;
    border: none;
    border-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

@media (min-width:600px) and (max-width:1200px) {

  .animation h1 {
    text-align: center;
  }


  .groth {
    font-size: 35px !important;
    width: 98vw;
    color: #1d2651;
    text-align: center;
  }

  .SuperMainClass {
    padding-top: 0rem;
    padding: 2rem !important;
    display: flex;
    gap: 7.3rem;
    flex-direction: column-reverse;


  }


  .wtssrc {
    margin-top: 1rem;
    position: relative;
    width: 100%;
    padding: 0rem 0rem;
    height: auto;
    left: 100%;
    animation: slideIn 1.5s ease-out forwards;


  }

  .Official {
    padding: 10px;
    width: 100vw;
    font-size: 25px;
    text-align: center !important;
  }

  .sub1 {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    /* animation: typeWriter 3.5s steps(60) infinite, fadeIn 2s 4s forwards; */
    animation: blingAnimation 2s infinite;
    color: #1d2651;
    margin-top: -1.2rem;
    font-size: 16px !important;
    padding: 1.5rem 0rem;
    font-weight: 400;


  }

  @keyframes pulseBackground {
    from {
      background-color: #f0f0f0;
    }

    to {
      background-color: #d1e9ff;
      /* Set your desired pulsating color */
    }
  }





  .greenLine {
    width: 50px;
    /* Adjust the width as needed */
    height: 2px;
    /* Adjust the height as needed */
    background-color: green;
  }

  .JoinDemo {

    gap: 2rem;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;

  }



  .wtssrc {
    position: relative;
    left: 100%;
    /* Start off-screen from the right side */
    animation: slideIn 1.5s ease-out forwards;
    /* Animation duration and timing function */
  }

  @keyframes slideIn {
    to {
      left: 0;
      /* Move to the original position */
    }
  }

  .SubscriptionInfo {
    justify-content: center;
    align-items: center;
    display: flex;
  }

}

@media (min-width:469px) and (max-width:600px) {
  .Images{
    padding: 0rem 1rem !important;
  }

  .SeccondContainer {
    text-align: center;
    margin-top: 4rem;
  }



  .pickIndustry {
    margin: auto;
    width: 48vw;
    font-size: 1.2rem;
    margin-top: -2rem;

  }

  .Mainclass {
    margin-top: -4rem;

  }

  .animation h1 {
    /* display: inline-block;
             overflow: hidden;
             white-space: nowrap;
             animation: none;
             color: #89dc12;
             margin-top: -1.2rem;
             font-size: 38px !important;
             padding: 1.5rem 0rem; */
    text-align: center !important;
    animation: none;
    display: flex;
    justify-content: center;
    font-size: 35px !important;
  }

  .purches {

    color: green;
    font-size: 16px;
  }

  .SubscriptionInfo {
    display: flex;
    gap: 15px;
    margin-top: 1.5rem;
  }

  @keyframes typeWriter {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .groth {
    font-size: 35px !important;
    width: 98vw;
    color: #1d2651;
    text-align: center;
  }

  .SuperMainClass {
    padding-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: -2rem; */
    background-image: url(./Images/01.png);
    /* /* font-family: "Poppins", sans-serif */
    font-family: "Poppins", sans-serif;
    font-family: "Raleway", sans-serif;
    gap: 7.3rem;
    flex-direction: column-reverse;


  }

  @keyframes blingAnimation {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    50% {
      transform: scale(1.2);
      opacity: 0.7;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .logob {
    width: 7rem !important;
    height: 7rem !important;
    background-color: rgb(243, 242, 236);
    /* Change the background color as needed */
    border-radius: 50%;
    animation: blingAnimation 2s infinite;
    /* Adjust the animation duration as needed */
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
  }




  .wtssrc {
    position: relative;
    width: 100%;
    padding: 0rem 0rem;
    height: auto;
    left: 100%;
    animation: slideIn 1.5s ease-out forwards;


  }

  .Official {
    width: 100vw;
    font-size: 25px;
    text-align: center !important;
    padding: 0rem 1rem;
  }

  .sub1 {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    color: #1d2651;
    margin-top: -1.2rem;
    font-size: 16px !important;
    padding: 1.5rem 0rem;
    font-weight: 400;
    animation: unset;
    display: none;
  }



  @keyframes fadeInScale {
    from {
      opacity: 0;
      transform: scale(0.8);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes pulseBackground {
    from {
      background-color: #f0f0f0;
    }

    to {
      background-color: #d1e9ff;
      /* Set your desired pulsating color */
    }
  }

  .demobtn {
    display: inline-block;
    padding: 17px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border-radius: 8px;
    /* Rounded corners */
    background: rgb(82, 192, 35);
    background: linear-gradient(90deg, rgba(0, 187, 57, 1) 0%, rgba(103, 198, 43, 1) 90%);
    color: #ffffff;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
    position: relative;
    overflow: hidden;
    border: none;
  }

  .demobtn:hover {
    background: linear-gradient(90deg, rgba(103, 198, 43, 1) 90%, rgba(0, 187, 57, 1) 0%);
    color: #fff;
    /* Change text color on hover */
    transform: scale(1.05) translateX(5px) translateY(5px);
    /* Add scale and movement effect on hover */
    animation: moveButton 1s infinite alternate;
    /* Add movement animation */
  }

  @keyframes moveButton {
    to {
      transform: translateX(0) translateY(0);
      /* Reset the movement on each iteration */
    }
  }

  .ply {
    position: relative;
    text-transform: uppercase;
    color: #111121;
    font-weight: 500;
    font-size: 18px;


  }

  .playclass a {
    text-decoration: none;
  }

  .greenLine {
    width: 50px;
    /* Adjust the width as needed */
    height: 2px;
    /* Adjust the height as needed */
    background-color: green;
    display: none;
  }

  .JoinDemo {

    gap: 1rem;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;

  }

  .plybtn {
    font-size: 40px;

    border: 2px solid #89dc12;
    padding: 0.7rem;
    border-radius: 30%;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
    background-color: #89dc12;
    color: #fff;
  }

  .plybtn:hover {
    color: #89dc12;
    background-color: #ffffff;
    transform: scale(1.05);
  }


  @keyframes slideIn {
    to {
      left: 0;
      /* Move to the original position */
    }
  }

  .wtssrc {
    position: relative;
    left: 100%;
    /* Start off-screen from the right side */
    animation: slideIn 1.5s ease-out forwards;
    /* Animation duration and timing function */
  }

  @keyframes slideIn {
    to {
      left: 0;
      /* Move to the original position */
    }
  }


  .whats-api {
    height: auto;
    width: 100%;
    background-color: #fafafa;
  }

  .SubscriptionInfo {
    justify-content: center;
    align-items: center;
  }

}

@media (min-width:250px) and (max-width:469px) {
  .Images{
    padding: 0rem 2rem !important;
  }

  .SeccondContainer {
    text-align: center;
    margin-top: 4rem;
  }



  .pickIndustry {
    margin: auto;
    width: 48vw;
    font-size: 1.2rem;
    margin-top: -2rem;

  }

  .Mainclass {
    margin-top: -4rem;

  }

  .animation h1 {
    text-align: center !important;
    /* animation: none; */
    display: flex;
    justify-content: center;
    font-size: 22px !important;
    animation: typeWriter 2.5s steps(60) infinite, fadeIn 2s 4s forwards;

  }


  .SubscriptionInfo {
    display: flex;
    gap: 15px;
    margin-top: 1.5rem;
  }

  .groth {
    font-size: 25px !important;
    width: 100vw;
    padding: 5px;
    text-align: center !important;
    color: #1d2651;
    text-align: center;
    margin-top: -2rem;
  }

  .SuperMainClass {

    background-image: url(./Images/01.png);
padding-top: 4rem;
    gap: 7.3rem;
    flex-direction: column-reverse;


  }

  .wtssrc {
    position: relative;
    width: 100%;
    padding: 0rem 0rem;
    height: auto;
    left: 100%;
    animation: slideIn 1.5s ease-out forwards;



  }

  .Official {
    width: 100vw;
    font-size: 17px;
    text-align: center !important;
    padding: 0rem 1rem;
    margin-top: -1rem;
  }

  .sub1 {
    animation: unset;
    display: none;
  }



  @keyframes fadeInScale {
    from {
      opacity: 0;
      transform: scale(0.8);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes pulseBackground {
    from {
      background-color: #f0f0f0;
    }

    to {
      background-color: #d1e9ff;
      /* Set your desired pulsating color */
    }
  }



  @keyframes moveButton {
    to {
      transform: translateX(0) translateY(0);
      /* Reset the movement on each iteration */
    }
  }

  .ply {
    position: relative;
    text-transform: uppercase;
    color: #111121;
    font-weight: 500;
    font-size: 18px;


  }

  .playclass a {
    text-decoration: none;
  }

  .greenLine {
    width: 50px;
    /* Adjust the width as needed */
    height: 2px;
    /* Adjust the height as needed */
    background-color: green;

  }

  .JoinDemo {

    gap: 1rem;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    margin-top: -0.5rem;

  }

  .plybtn {
    font-size: 40px;

    border: 2px solid #89dc12;
    padding: 0.7rem;
    border-radius: 30%;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
    background-color: #89dc12;
    color: #fff;
  }

  .plybtn:hover {
    color: #89dc12;
    background-color: #ffffff;
    transform: scale(1.05);
  }


  @keyframes slideIn {
    to {
      left: 0;
      /* Move to the original position */
    }
  }

  .wtssrc {
    position: relative;
    border-radius: 10px !important;
    left: 100%;
    
    /* Start off-screen from the right side */
    animation: slideIn 1.5s ease-out forwards;
    /* Animation duration and timing function */
  }

  @keyframes slideIn {
    to {
      left: 0;
      /* Move to the original position */
    }
  }


  .whats-api {
    height: auto;
    width: 100%;
    background-color: #fafafa;
  }

  .SubscriptionInfo {
    justify-content: center;
    align-items: center;
  }

  .demobtn {
    padding: 12px 20px;
  }
}

@media (min-width:768px) and (max-width:997px){
 .botwala{
   flex-direction: column !important;
   width: 100% !important;
 }
}