/* Login.css */

.login-main{
   
}
.login1 {
    max-width: 400px;
    margin: 2rem auto;
    padding: 20px 20px 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    text-align: center;
    
}
.lg1{
    margin: 4rem  auto!important;
}

.login1 h1 {
    color: #333;
}

.login1 form {
    display: flex;
    flex-direction: column;
}

.login1 input {
    margin: 7px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 16px;
}

/* Add style for input focus */
.login1 input:focus {
    border-color: #3498db;
    box-shadow: 0 0 8px rgba(52, 152, 219, 0.6);
}

/* Style for invalid input */
.login1 input:invalid {
    border-color: red;
}

/* Style for error message */
.login1 .error-messages {
    color: red;
    margin-top: 5px;
   
}

.login1 input[type="submit"] {
    background-color: #3498db;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 1.5rem;
    width: 100%; /* Full width on smaller screens */
}

.upper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.login1 input[type="submit"]:hover {
    background-color: #2980b9;
}

/* Style for 'Don't have an account' section */
.login1 p {
    font-size: 14px;
    color: #555;
    margin-top: -6px;

}
.plat{
    margin-top: -2rem !important;
}

.login1 a {
    color: #3498db;
    text-decoration: none;
    font-weight: bold;
    margin-top: -7px;
}

.login1 a:hover {
    text-decoration: underline;
}
.lg-box1{
    height: 5.5rem !important;
    width: 5.5rem !important;
    animation: unset !important;
}
.mg{
    font-size: 17px !important;
}
.un{
    height: 1.7rem !important;
    width: 1.7rem !important;
}
.lgtp{
    font-size: 9px !important;
margin: 0px !important;
}

.pacematic {
    height: 4.5rem;
    width: 4.5rem;
}
.login-btn {
    border: none;
    width: auto !important;
    height: auto !important;
    padding: 10px 20px;
    border-radius: 5px !important;
    font-weight: 500;
    color: #ffffff;
    font-size: 1.05rem !important;
    line-height: 1.65rem !important;
    letter-spacing: 0px;
    word-spacing: -0.5px;
    background: linear-gradient(90deg, rgba(0, 187, 57, 1) 0%, rgba(103, 198, 43, 1) 90%);
    transition: background-color 0.3s ease, transform 0.3s ease; /* Added transform property for animation */
  
    &:hover {
      animation: shake 0.5s ease-in-out infinite; /* Shake animation */
    }
  }
  @keyframes shake {
    0%, 100% {
      transform: translateX(0);
    }
    25%, 75% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
  }
/* Responsive Styles */

@media (min-width :400px) and (max-width:768px){
    .login1 {
        max-width: 100%;
        margin: 2rem 3.5rem !important;
        padding: 20px 16px;
    }

    .login1 input[type="submit"] {
        width: 100%; /* Auto width on smaller screens */
    }
    .lg-box1{
        height: 5.5rem !important;
        width: 5.5rem !important;
        animation: unset !important;
    }
    .mg{
        font-size: 17px !important;
    }
    .un{
        height: 1.7rem !important;
        width: 1.7rem !important;
    }
    .lgtp{
        font-size: 9px !important;
 margin: 0px !important;
    }
}
@media screen and (max-width: 399px) {
    .login1 {
        max-width: 100%;
        margin: 2rem 1.5rem !important;
        padding: 20px 8px;
    }

    .login1 input[type="submit"] {
        width: 100%; /* Auto width on smaller screens */
    }
    .lg-box1{
        height: 5.5rem !important;
        width: 5.5rem !important;
        animation: unset !important;
    }
    .mg{
        font-size: 17px !important;
    }
    .un{
        height: 1.7rem !important;
        width: 1.7rem !important;
    }
    .lgtp{
        font-size: 9px !important;
 margin: 0px !important;
    }
    .login1 p{
        font-size: 11px;
    }
}