*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* background-color: pink; */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.get-api-session {
    position: relative;
    height: 45rem;
    padding: 2rem 0rem !important;
    width: 100%;
    margin: -1rem auto;
    overflow: hidden;
    box-shadow: 0px 0px 5px 0px rgb(98, 96, 96);
    border-radius: 10px;
}
.in-with{
    display: flex;
    align-items: center;
    justify-content: center;
}
.get-top-row{
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    align-items: center;
    text-align: center;
    padding: 1rem 31px;
}
.get-top-row h1{
    color:#1c2045;
    font-size: 38px;
}
.get-top-row span{
    font-size: 20px;
    width: 48.4vw;
    font-weight: 500;
    color:#1c2045;
    line-height:  1.95rem;
    font-style: normal;
}
.get-api-session::before {
    content: '';
    background-image: url("./Images/move-icon-bg.png");
    height: 100%;
    width: 100%;
    background-size: cover;
    background-blend-mode: darken;
    position: absolute;
    /* top: 0;
    left: 0; */
    animation: moveBackground 40s linear infinite;
    pointer-events: none; 
}
@keyframes moveBackground {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}
.get-form-content{
    width: 100%;
    flex-direction: row-reverse;
    height: auto;
    /* padding: 0rem 2rem 0rem 2rem; */
    display: flex;
    justify-content: center !important;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    margin-top: 1rem;
}
.msgman-img{
  height: 26rem;
    width: 25rem;
}
  .api-form1{
    display: flex;
    align-items: start;
    justify-content: start;
    margin:  0rem;
    flex-direction: column;
    height: auto !important;
    border: 1px solid #e0d5d5 !important;
    border-radius: 10px !important;
    width: 45rem !important
  }
  .api-form3{
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 2.5rem 3rem 3rem 3rem; */
    flex-direction: column;
    margin:  0rem;
    height: 28rem !important;
    border: 1px solid #e0d5d5 !important;
      border-radius: 10px !important;
      width: 45rem !important;
      
  }
  #field-content{
    flex-direction: column !important;
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
  }
  .form-control{
    color: #3f4347 !important;
    font-size: 13px !important; 
  }
  .form-fields-d{
    margin-bottom: 23px;
    color: #635f5f !important;
    border: 1px solid #aba5a5;
    border-radius: 4px;
    height: 40px;
    width: 43rem;
  }
  .api-form1-content{
    height: auto;
    width: 100%;
    font-size: 18px;
    margin: 1rem;
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
  }
  .api-form1-content span{
    font-size: 17px;

  }
  .get-api-form{
    margin-top: -1rem;
    display: flex;
    flex-direction: column;
    gap: 0rem;
  }
  .get-api-main{
    margin: 2rem 7rem;
    /* background-color: pink; */
  }
  .form-group{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .select-cn{
     color: rgb(224, 217, 217) !important;
  }
  .cn{
    font-size: 16px;
    padding: 8px;
  }
  .form-btns{
    display: flex;
    gap: 25px;
  }
  .next-btn {
    border: none;
    width: 6rem !important;
    height: 3rem !important;
    border-radius: 5px !important;
    font-weight: 500;
    color: #ffffff;
    font-size: 1.05rem !important;
    line-height: 1.65rem !important;
    letter-spacing: 0px;
    word-spacing: -0.5px;
    background: linear-gradient(90deg, rgba(0, 187, 57, 1) 0%, rgba(103, 198, 43, 1) 90%);
    transition: background-color 0.3s ease, transform 0.3s ease; /* Added transform property for animation */
  
    &:hover {
      animation: shake 0.5s ease-in-out infinite; /* Shake animation */
    }
  }
  @keyframes shake {
    0%, 100% {
      transform: translateX(0);
    }
    25%, 75% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
  }
  .thank-you-page{
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color:azure; */
    margin: auto;
    height: auto;
    padding: 15rem;
  }
 
  .sub-msg{
    display: flex;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px black;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 15rem;
    /* background-color: #0fef39; */
    border: 1px solid black;
    width: 28rem;
    flex-direction: column;
    gap: 5px !important;
    background-image: url("./Images/surprise-bg.gif");
    background-position: center;
  }
  .sub-msg {
    text-align: center;
}

.sub-msg h1 {
    position: relative;
    font-size: 50px;
    font-weight: 500;
    color: #0fef39 /* Change the color as needed */
}

.sub-msg h1::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.8), transparent);
    animation: shimmer 1.5s infinite;
}
.thank-you span{
  font-size: 18px;
}
.sub-link{
  text-decoration: none;
  color: #ffffff;
}

@keyframes shimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}
/* .thank-you-page{
  height: 30rem;
  width: 80rem;
  background-color: #0fef39;

} */

@media (min-width :769px) and (max-width:1200px){
  .get-form-content {
    width: 100%;
    flex-direction: column;
  }
  .gets-form{
    width: 100% !important;
    height: auto;
    padding: 0rem 5rem;
  }
  .get-top-row span {
    line-height: 1.5rem;
    font-size: 20px !important;
  }
  .get-top-row {
    padding: 1rem 10px;
}
.api-form3{
  height: 15rem !important;
  width: 100% !important;
  padding: 1rem;
  margin: 1rem 0rem;
}
.sub-msg {
  width: 100% !important;
}
  .api-form1{
    width: 100% !important;
    height: auto !important;
  }
  .get-api-session {
    height: auto;
    width: 100%;
    margin: 1rem auto;
   
}
.form-fields-d{
 
  height: 40px;
  width: 100%;
}
.get-api-main{
  margin: 2rem 1rem;
 
}
.api-form1-content {
  margin: 0rem;
  padding: 20px;
}
.get-form-content {
  padding: 0rem 1rem;
  justify-content: center !important;
  gap: 1rem;
  margin-top: 1rem;

}

.get-top-row span {
  font-size: 18px;
  text-align: center;
  width: 100vw;
}
.get-api-form {
  margin-top: 0rem;
  gap: 1rem;
  padding: 0rem 14rem;
}
.gets-main{
  width: 100%;
  height: auto;
  /* background-color: rgb(184, 23, 50); */
  /* margin: 0rem 6rem; */
}
.msgman-img {
  height: auto;
  width: 100%;
}


}
@media (min-width :1201px) and (max-width:1500px){
.api-form1 {
  width: 30rem !important;
}
.api-form3{
 
  width: 30rem !important;
}
.get-api-session {
  position: relative;
  height: 48rem;
  padding: 2rem 0rem !important;
  width: 100%;
  margin: -1rem auto;
  overflow: hidden;
  box-shadow: 0px 0px 5px 0px rgb(98, 96, 96);
  border-radius: 10px;
}

.form-fields-d {
  width: 28rem;
 
}
.msgman-img {
  height: 26rem;
  width: 23rem;
}
.get-top-row span {
  font-size: 21px;
  width: 65vw;
}
.get-form-content{
  margin-top: 3rem;
    margin-bottom: 1rem;
}
}


  @media (min-width :499px) and (max-width:769px){
    .api-form3{
      height: 15rem !important;
      width: 100% !important;
      padding: 1rem;
      margin: 1rem 0rem;
    }
    .sub-msg {
      width: 100% !important;
    }
    .get-form-content {
      width: 100%;
      flex-direction: column;
    }
    .gets-form{
      width: 100% !important;
      height: auto;
    }
    .get-top-row span {
      line-height: 1.5rem;
      font-size: 16px !important;
    }
    .get-top-row {
      padding: 1rem 10px;
  }
    .api-form1{
      width: 100% !important;
      height: auto !important;
    }
    .get-api-session {
      height: auto;
      width: 100%;
      margin: 1rem auto;
     
  }
  .form-fields-d{
   
    height: 40px;
    width: 100%;
  }
  .get-api-main{
    margin: 2rem 1rem;
   
  }
  .api-form1-content {
    margin: 0rem;
    padding: 10px;
  }
  .get-form-content {
    padding: 0rem 1rem;
    gap: 1rem;
  }
  .get-top-row span {
    font-size: 18px;
    text-align: center;
    width: 100vw;
  }
  .get-api-form {
    margin-top: -1rem;
    gap: 1rem;
    padding: 0rem 6rem;
  }
  .gets-main{
    width: 100%;
    height: auto;
    /* background-color: rgb(184, 23, 50); */
    /* margin: 0rem 6rem; */
  }
  .msgman-img {
    height: auto;
    width: 100%;
  }
  
  }  
  @media (min-width :349px) and (max-width:499px){
    .sub-msg span{
      font-size: 10px;
    }
    .sub-msg h1 {
      position: relative;
      font-size: 49px;
    }
    
    .api-form3{
      height: 9rem !important;
      width: 100% !important;
      padding: 1rem;
      margin: 1rem 0rem;
    }
    .sub-msg {
      width: 100% !important;
      height: 7rem;
    }
    .get-form-content {
      width: 100%;
      flex-direction: column;
    }
    .gets-form{
      width: 100% !important;
      height: auto;
    }
    .get-top-row span {
      line-height: 1.5rem;
      font-size: 16px !important;
    }
    .get-top-row {
      padding: 1rem 10px;
  }
    .api-form1{
      width: 100% !important;
      height: auto !important;
    }
    .get-api-session {
      height: auto;
      width: 100%;
      margin: 1rem auto;
     
  }
  .form-fields-d{
   
    height: 40px;
    width: 100%;
  }
  .get-api-main{
    margin: 2rem 1rem;
   
  }
  .api-form1-content {
    margin: 0rem;
    padding: 10px;
  }
  .get-form-content {
    padding: 0rem 1rem 1rem;
    gap: 1rem;
  }
  .get-top-row span {
    font-size: 18px;
    text-align: center;
    width: 100vw;
  }
  .get-api-form {
    margin-top: -1rem;
    padding: 0px 40px;
    gap: 1rem;
  }
  .gets-main{
    width: 100%;
    height: auto;
    /* background-color: rgb(184, 23, 50); */
    /* margin: 0rem 6rem; */
  }
  .msgman-img {
    height: auto;
    width: 100%;
  }
  
  
  }  
  @media (min-width :200px) and (max-width:349px){
    .sub-msg h1 {
      position: relative;
      font-size: 25px;
    }
    .api-form3{
      height: 9rem !important;
      width: 100% !important;
      padding: 1rem;
      margin: 1rem 0rem;
    }
    .recieve-msg{
      font-size: 10px !important;
    }
    .sub-msg {
      width: 100% !important;
      height: 7rem;
    }
    .get-form-content {
      width: 100%;
      flex-direction: column;
    }
    .gets-form{
      width: 100% !important;
      height: auto;
    }
    .get-top-row span {
      line-height: 1.5rem;
      font-size: 16px !important;
    }
    .get-top-row {
      padding: 1rem 10px;
  }
    .api-form1{
      width: 100% !important;
      height: auto !important;
      margin: 1rem 0rem;
    }
    .get-api-session {
      height: auto;
      width: 100%;
      padding: 0rem 0rem 1rem 0rem !important;
      margin: 1rem auto;
     
  }
  .form-fields-d{
    font-size: 10px !important;
    height: 34px;
    width: 100%;
  }
  .get-api-main{
    margin: 2rem 1rem;
   
  }
  .api-form1-content {
    margin: 0rem;
    padding: 10px 5px;
    gap: 12px !important;
  }
  .get-form-content {
    padding: 0rem 3px;
    gap: 0rem;
  }
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
  .next-btn {
    border: none;
    width: 5rem !important;
    height: 2rem !important;
  }
  .get-top-row span {
    font-size: 12px !important;
    line-height: 1rem;
    text-align: center;
    width: 100vw;
  }
  .api-form1-content span {
    font-size: 12px;
}
  .in-with {
    display: flex;
    font-size: 15px;
  }
  .get-api-form {
    margin-top: -1rem;
    padding: 0px 12px;
    gap: 1rem;
  }
  .gets-main{
    width: 100%;
    height: auto;
    /* background-color: rgb(184, 23, 50); */
    /* margin: 0rem 6rem; */
  }
  .msgman-img {
    height: auto;
    width: 100%;
  }
  
  
  }  