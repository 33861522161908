*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .login-visit{
    background-color: azure;
    text-align: center;justify-content: center;
    align-items: center;
    display: flex;
    height: auto;
    padding: 2rem 0rem ;
  }