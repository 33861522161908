/* Updated class names */
.CustomPartnerSrc {
    width: 25rem;
    height: auto;
    animation: slideIn 5s ease-out;
}
.CustomPartnershipPage{
  margin-top: -5rem;
}
.MainPartnershipPage{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7rem;
    margin-top: 0rem;
}

.CustomPartnershipPage h2{
    font-size: 38px;
    color: #1c2045;
    line-height: 50px;
    font-weight: 700;
    margin-bottom: 30px;
    width: 50vw;
    animation: slideIn 5s ease-out infinite;
}
/* @keyframes slideIn {
    0% {
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      transform: translateY(-50);
      opacity: 1;
    }
  } */
.CustomPartnershipPage p{
    font-size: 23px;
    font-style: normal;
    line-height: 1.95rem !important;
    margin-bottom: 0px;
    color: #1c2045;
    letter-spacing: 0px !important;
    width: 50vw;
}

/* Updated class names */
.customPtnrBtn {
    display: inline-block;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    background-color: #1c2045;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.2s ease-out;
    position: relative;
    overflow: hidden;
    margin-top: 2rem;
}

.customPtnrBtn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #1c2045, transparent);
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition: transform 0.5s ease-in-out;
}

.customPtnrBtn:hover::before {
    transform: scaleX(1);
}

.customPtnrBtn:hover {
    background-color: #2c2f63;
    transform: translateY(-5px);
}

@keyframes pulse {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

.customPtnrBtn {
    animation: pulse 1s infinite;
}

@keyframes typeWriter {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

@keyframes slide {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes beast {
    0% {
      color: initial;
    }
    50% {
      color: #41f538; /* Change color to your preference */
    }
    100% {
      color: initial;
    }
  }
.partner h1{
    font-size: 2.1rem;
    font-weight: 700;
    padding: 2rem 0rem 0rem;
    text-align: center;
    font-weight: 800;
    animation: slide 1s ease, beast 2s infinite;
}
.customBotwalaP {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    animation: typeWriter 1.5s steps(60) infinite, fadeIn 2s 4s forwards;
    color: #89dc12 !important;
    margin-top: -1.5rem;
    font-size: 36px !important;
    font-weight: 600;
}

.CustomPartnershipPage span{
    color:  #89dc12;
    font-weight: bold;
}
/* Updated class names */
.CustomPartnerSrc {
  width: 25rem;
  height: auto;
  animation: slideIn 5s ease-out;
 
}

.MainPartnershipPage{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7rem;
  margin-top: -1.5rem;
}

.CustomPartnershipPage h2{
  padding-top: 1rem;
  font-size: 38px;
  color: #1c2045;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 30px;
  width: 50vw;
  animation: slideIn 5s ease-out infinite;
}
/* @keyframes slideIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(-50);
    opacity: 1;
  }
} */
.CustomPartnershipPage p{
  font-size: 23px;
  font-style: normal;
  line-height: 1.95rem !important;
  margin-bottom: 0px;
  color: #1c2045;
  letter-spacing: 0px !important;
  width: 50vw;
}

/* Updated class names */
.customPtnrBtn {
  display: inline-block;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background-color: #1c2045;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, transform 0.2s ease-out;
  position: relative;
  overflow: hidden;
  margin-top: 2rem;
}

.customPtnrBtn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #1c2045, transparent);
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: transform 0.5s ease-in-out;
}

.customPtnrBtn:hover::before {
  transform: scaleX(1);
}

.customPtnrBtn:hover {
  background-color: #2c2f63;
  transform: translateY(-5px);
}

@keyframes pulse {
  0%, 100% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.1);
  }
}

.customPtnrBtn {
  animation: pulse 1s infinite;
}

@keyframes typeWriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes beast {
  0% {
    color: initial;
  }
  50% {
    color: #41f538; /* Change color to your preference */
  }
  100% {
    color: initial;
  }
}
.partner h1{
  font-size: 2.1rem;
  font-weight: 700;
  padding: 1.5rem 0rem 1rem;
  text-align: center;
  font-weight: 800;
  animation: slide 1s ease, beast 2s infinite;
}
.customBotwalaP {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  animation: typeWriter 1.5s steps(60) infinite, fadeIn 2s 4s forwards;
  color: #89dc12 !important;
  margin-top: -1.5rem;
  font-size: 36px !important;
  font-weight: 600;
}

.CustomPartnershipPage span{
  color:  #89dc12;
  font-weight: bold;
}


@media(min-width:1200px) and (max-width:1500px) {

.CustomPartnerSrc {
  width: 25rem;
  height: auto;
  animation: slideIn 5s ease-out;
}

.MainPartnershipPage{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7rem;
  margin-top: 0.5rem;
}

.CustomPartnershipPage h2{
  font-size: 38px;
  color: #1c2045;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 30px;
  width: 50vw;
  animation: slideIn 5s ease-out infinite;
}
/* @keyframes slideIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(-50);
    opacity: 1;
  }
} */
.CustomPartnershipPage p{
  font-size: 23px;
  font-style: normal;
  line-height: 1.95rem !important;
  margin-bottom: 0px;
  color: #1c2045;
  letter-spacing: 0px !important;
  width: 50vw;
}

/* Updated class names */
.customPtnrBtn {
  display: inline-block;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background-color: #1c2045;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, transform 0.2s ease-out;
  position: relative;
  overflow: hidden;
  margin-top: 2rem;
}

.customPtnrBtn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #1c2045, transparent);
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: transform 0.5s ease-in-out;
}

.customPtnrBtn:hover::before {
  transform: scaleX(1);
}

.customPtnrBtn:hover {
  background-color: #2c2f63;
  transform: translateY(-5px);
}

@keyframes pulse {
  0%, 100% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.1);
  }
}

.customPtnrBtn {
  animation: pulse 1s infinite;
}

@keyframes typeWriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes beast {
  0% {
    color: initial;
  }
  50% {
    color: #41f538; /* Change color to your preference */
  }
  100% {
    color: initial;
  }
}
.partner h1{
  font-size: 2.1rem;
  font-weight: 700;
  padding: 2.5rem 0rem 1rem;
  text-align: center;
  font-weight: 800;
  animation: slide 1s ease, beast 2s infinite;
}
.customBotwalaP {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  animation: typeWriter 1.5s steps(60) infinite, fadeIn 2s 4s forwards;
  color: #89dc12 !important;
  margin-top: -1.5rem;
  font-size: 36px !important;
  font-weight: 600;
}

.CustomPartnershipPage span{
  color:  #89dc12;
  font-weight: bold;
}

}

@media (min-width:600px) and (max-width:1200px) {
  .CustomPartnershipPage{
    margin-top: 1rem;
  }
.MainPartnershipPage{
  flex-direction: column;
  gap: 2rem;
}
.CustomPartnerSrc {
  margin: auto;
  width: 70%;
  height: auto;
  display: flex;
}
.customBotwalaP {
margin: auto;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
}
.CustomPartnershipPage h2 {
font-size: 34px;
color: #1c2045;
line-height: 50px;
font-weight: 700;
margin-bottom: 30px;
width: 80vw;
text-align: center;
animation: slideIn 5s ease-out infinite;
}
.CustomPartnershipPage p{
width: 80vw;
text-align: center;
}
.customPtnrBtn{
margin: auto;
display: flex;
margin-top: 2rem;
}

}

@media (min-width:469px) and (max-width:600px){
  .CustomPartnershipPage{
    margin-top: 1rem;
  }

.MainPartnershipPage{
  flex-direction: column;
  gap: 2rem;
}
.CustomPartnerSrc {
  margin: auto;
  width: 70%;
  height: auto;
  display: flex;
}
.customBotwalaP {
margin: auto;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
}
.CustomPartnershipPage h2 {
font-size: 30px;
color: #1c2045;
line-height: 41px;
font-weight: 700;
margin-bottom: 30px;
width: 80vw;
text-align: center;
animation: slideIn 5s ease-out infinite;
}
.CustomPartnershipPage p{
width: 80vw;
text-align: center;
}
.customPtnrBtn{
margin: auto;
display: flex;
margin-top: 2rem;
}

}

@media (min-width:250px) and (max-width:469px){
  .CustomPartnershipPage{
    margin-top: 1rem;
  }

.partner h1{
  font-size: 1.8rem;
  padding: 2.5rem 5px 1rem;
}

.MainPartnershipPage{
  flex-direction: column;
  gap: 2rem;
}
.CustomPartnerSrc {
  margin: auto;
  width: 70%;
  height: auto;
  display: flex;
}
.customBotwalaP {
margin: auto;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
}
.CustomPartnershipPage h2 {
font-size: 25px;
color: #1c2045;
line-height: 32px;
font-weight: 700;
margin-bottom: 30px;
width: 100vw;
padding: 0rem 0.7rem;
text-align: center;
animation: slideIn 5s ease-out infinite;
}
.CustomPartnershipPage p {
width: 100vw;
text-align: center;
font-size: 1.3rem !important;
padding: 0rem 0.5rem;
margin-top: -1.5rem;
}
.customPtnrBtn{
margin: auto;
display: flex;
margin-top: 2rem;
}
.customPtnrBtn {
  margin: 0rem auto 1rem;
  display: flex;
  margin-top: 2rem;
  padding: 0.5rem 1rem;
}
}
