.mainfaq{
    margin-top: 0rem;
  }
  .faqtext h2{
      font-size: 2.5rem;
      text-align: center;
      padding: 2rem 0rem;
      animation: slide 1s ease, beast 2s infinite;
  }
  .faq-item {
      position: relative;
      border: 1px solid #ccc;
      margin-bottom: 10px;
      /* width: 100%; */
      cursor: pointer;
      margin: 0.8rem 6.7rem;
      border-radius: 1rem;
      box-shadow: 0px 0px 5px 0px rgb(202, 198, 198);
      padding: 0.5rem 0rem;
      background-image: linear-gradient(to top, rgb(244, 248, 244),rgb(189, 250, 189));
  
    } 
    .question {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      font-size: 1.2rem;
      font-weight: 500;
      color: rgb(64, 62, 62); 
    }
    .arrow {
      margin-left: 10px;
    }
    
    .arrow.open {
      margin-left: auto;
    }
    
    .answer {
      padding: 0.5rem ;
      font-size: 1.2rem;
  
    }
    @media (min-width :769px) and (max-width:1200px){
      .faq-item{
        margin: 1rem 3.7rem;
        flex-direction: column;
      }

    }
    @media (min-width :499px) and (max-width:769px){
      .faq-item{
        margin: 1rem 1.7rem;
        flex-direction: column;
      }
      .answer {
        text-align: start;
      }
    }
      @media (min-width :349px) and (max-width:499px){
        .faq-item{
          margin: 1rem 1.7rem;
          flex-direction: column;
        }
        .faqtext h2 {
          padding: 2rem 0rem 1rem;
        }
      
    }
    @media (min-width :250px) and (max-width:349px){
      .faq-item{
        margin: 0rem 0.5rem;
        flex-direction: column;
      }
      .faqtext h2 {
        margin-top: 1rem;
        padding: 1rem 0rem 0rem;
      }
      .question {
        font-size: 18px;
      }
      .answer {
        padding: 0.5rem;
        font-size: 18px;
    }
    .mainfaq{
      margin-top: 0rem;
    flex-direction: column;
    display: flex;
    gap: 1rem;
    }
    
  }