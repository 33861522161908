*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.privacy-head{
    font-weight: 800;
    padding: 1.5rem 0rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

}

.privacy-policy{
    height: auto;
    width: 100%;
    background-color: #fff;
    margin-top: -6rem;
}

.purpose{
    margin: 5rem 0rem 0rem 7rem;
   
}

.maintains p{
   margin: 0rem 7rem;
}

.personal-info h1{
    font-size: 3rem;
    padding: 3rem 0rem;
    font-weight: 700;
    color: #1c2045;
    animation: slide 1s ease, beast 2s infinite;
}

.personal-info p{
    width: 80vw;
    font-size: 1rem;
    color: #000000;
}

.maintains p{
    width: 80vw;
    color: #000000;
}

.what-information h3{
    margin: 2rem 7rem;
    color: #1c2045;
}

.our-website h3{
    margin: 2rem 7rem;
    font-size: 1.5rem;
    color: #1c2045;
}

.our-website p{
    margin: 0rem 7rem;
    color: #000000;
    width: 75vw;
}

.varies-between p{
      width: 80vw;
      margin: 1rem 7rem 1.5rem;
      color: #000000;
}

#client{
    font-size: 1.2rem;
    color: rgb(83, 235, 83);
}

.client-data{
margin: 0.8rem 7rem;
 
}

.client-data span{
    color: #4a5568;
    padding: 0rem 0.5rem;
}

.information-provide h3{
    margin:2rem 7rem;
    color: #1c2045;
    font-size: 1.5rem;
}

.information-provide p{
    margin: 0rem 7rem;
}

.client-info {
  margin: 0.8rem 7rem;
}

.client-info span{
    color: #4a5568;
    padding: 0rem 0.5rem;   
}

.third-party p{
  color: #000000;
    width: 75vw;
    padding: 1rem 0rem;
}

.inventory {
    margin: 0rem 7rem 0.8rem;
}

.inventory span{
    padding: 0rem 0.5rem;
    color: #4a5568;
}


.payment-links p{
    color: #000000;
      width: 80vw;
      padding: 1rem 0rem;
  }
  
  .inventory-process{
      margin: 0rem 7rem 0.8rem;
  }
  
  .inventory-process span{
      padding: 0rem 0.5rem;
      color: #4a5568;
  }



  .book-demo p{
    color: #000000;
      width: 80vw;
      padding: 1rem 0rem;
  }
  
  .may-collect{
      margin: 0rem 7rem 0.8rem;
  }
  
  .may-collect span{
      padding: 0rem 0.5rem;
      color: #4a5568;
  }

  .your-rights{
    margin: 2rem 7rem;
  }

  .your-rights h3{
     font-size: 24px;
     color: #1c2045;
  }

  .request-access {
    display: flex;
    margin: 1.5rem 0rem;
  }

  .request-access span{
    color: #000000;
   padding: 0rem 0.5rem;
   width: 80vw;
  }

 .access-text{
    margin: 0rem 7rem;
 }

 .access-heading{
    margin: 1rem 7rem;
 }

 .your-customer h3{
    font-size: 24px;
    color: #1c2045;
    margin: 2rem 7rem;
 }

 .request-dainy{
   margin: 1.5rem 0rem;
 }

 .contact-us{
    margin: 0rem 7rem;
 }

 .contact-us h3{
    font-size: 24px;
    color: #1c2045;
    padding: 1.5rem 0rem;
 }

 .contact-us p{
    color: #000000;
 }




 @media(min-width:250px) and (max-width:349px){
    .privacy-policy{
        height: auto;
        width: 100%;
        background-color: #fff;
        margin-top: -4rem;
    }
    
    .purpose{
        margin: 5rem 0rem 0rem 0rem;
         padding: 0rem 0rem;
    }
    
    .maintains p{
       margin: 0rem 0rem;
       padding: 0rem 0.5rem;
    }
    
    .personal-info h1{
        font-size: 22px;
        padding: 2rem 0rem;
        font-weight: 700;
        color: #1c2045;
       padding: 1rem 0.5rem 0.5rem;
    }
    
    .personal-info p{
        width: 100vw;
        font-size: 1rem;
        color: #000000;
         padding: 0rem 0.5rem;
    }
    
    .maintains p{
        width: 100vw;
        color: #000000;
    }
    
    .what-information h3{
        margin: 2rem 0rem;
        padding: 0rem 0.5rem;
        color: #1c2045;
    }
    
    .our-website h3{
        margin: 2rem 0rem;
        padding: 0rem 0.5rem;
        font-size: 1.5rem;
        color: #1c2045;
    }
    
    .our-website p{
        margin: 0rem 0rem;
        padding: 0rem 0.5rem;
        color: #000000;
        width: 100vw;
    }
    
    .varies-between p{
          width: 100vw;
          padding: 1rem 0.5rem;
          margin: 1rem 0rem 1.5rem;
          color: #000000;
    }
    
    
    #client{
        font-size: 1.2rem;
        color: rgb(83, 235, 83);
    }
    
    .client-data{
        padding: 0rem 0.5rem;
    margin: 0.8rem 0rem;
     
    }
    
    .client-data span{
        color: #4a5568;
        padding: 0rem 0.5rem;
    }
    
    .information-provide h3{
        margin:2rem 0rem;
        padding: 0rem 0.5rem;
        color: #1c2045;
        font-size: 1.5rem;
    }
    
    .information-provide p{
        margin: 0rem 0rem;
        padding: 0rem 0.5rem;
    }
    
    .client-info {
        padding: 0rem 0.5rem;
      margin: 0.8rem 0rem;
    }
    
    .client-info span{
        color: #4a5568;
        padding: 0rem 0.5rem;   
    }
    
    .third-party p{
      color: #000000;
        width: 100vw;
        padding: 1rem 0.5rem;
    }
    
    .inventory {
        padding: 0rem 0.5rem;
        margin: 0rem 0rem 0.8rem;
    }
    
    .inventory span{
        padding: 0rem 0.5rem;
        color: #4a5568;
    }
    
    
    .payment-links p{
        color: #000000;
          width: 100vw;
          padding: 1rem 0.5rem;
      }
      
      .inventory-process{
          margin: 0rem 0rem 0.8rem;
          padding: 0rem 0.5rem;
      }
      
      .inventory-process span{
          padding: 0rem 0.5rem;
          color: #4a5568;
      }
    
    
    
      .book-demo p{
        color: #000000;
          width: 100vw;
          padding: 1rem 0.5rem;
      }
      
      .may-collect{
        padding: 0rem 0.5rem;
          margin: 0rem 0rem 0.8rem;
      }
      
      .may-collect span{
          padding: 0rem 0.5rem;
          color: #4a5568;
      }
    
      .your-rights{
        padding: 0rem 0.5rem;
        margin: 2rem 0rem;
      }
    
      .your-rights h3{
         font-size: 24px;
         color: #1c2045;
      }
    
      .request-access {
        display: flex;
        margin: 1.5rem 0rem;
      }
    
      .request-access span{
        color: #000000;
       padding: 0rem 0.5rem;
       width: 100vw;
      }
    
     .access-text{
        padding: 0rem 0.5rem;
        margin: 0rem 0rem;
     }
    
     .access-heading{
        padding: 0rem 0.5rem;
        margin: 1rem 0rem;
     }
    
     .your-customer{
        padding: 0rem 0.5rem;
     }
    
     .your-customer h3{
        font-size: 24px;
        color: #1c2045;
        margin: 2rem 0rem;
     }
    
     .request-dainy{
       margin: 1.5rem 0rem;
     }
    
     .contact-us{
        padding: 0rem 0.5rem;
        margin: 0rem 0rem;
     }
    
     .contact-us h3{
        font-size: 24px;
        color: #1c2045;
        padding: 1.5rem 0rem;
     }
    
     .contact-us p{
        color: #000000;
     } 
 }




 @media(min-width:349px) and (max-width:499px){

.privacy-policy{
    height: auto;
    width: 100%;
    background-color: #fff;
    margin-top: -4rem;
}
.privacy-head {
    font-weight: 800;
    padding: 0rem 0rem 1rem;
}
.purpose{
    margin: 5rem 0rem 0rem 0rem;
     padding: 0rem 0rem;
}

.maintains p{
   margin: 0rem 0rem;
   padding: 0rem 0.5rem;
}

.personal-info h1{
    font-size: 2rem;
    padding: 2rem 0rem;
    font-weight: 700;
    color: #1c2045;
   padding: 1rem 0.5rem 0rem;
}

.personal-info p{
    width: 100vw;
    font-size: 1rem;
    color: #000000;
     padding: 0rem 0.5rem;
}

.maintains p{
    width: 100vw;
    color: #000000;
}

.what-information h3{
    margin: 2rem 0rem;
    padding: 0rem 0.5rem;
    color: #1c2045;
}

.our-website h3{
    margin: 2rem 0rem;
    padding: 0rem 0.5rem;
    font-size: 1.5rem;
    color: #1c2045;
}

.our-website p{
    margin: 0rem 0rem;
    padding: 0rem 0.5rem;
    color: #000000;
    width: 100vw;
}

.varies-between p{
      width: 100vw;
      padding: 1rem 0.5rem;
      margin: 1rem 0rem 1.5rem;
      color: #000000;
}


#client{
    font-size: 1.2rem;
    color: rgb(83, 235, 83);
}

.client-data{
    padding: 0rem 0.5rem;
margin: 0.8rem 0rem;
 
}

.client-data span{
    color: #4a5568;
    padding: 0rem 0.5rem;
}

.information-provide h3{
    margin:2rem 0rem;
    padding: 0rem 0.5rem;
    color: #1c2045;
    font-size: 1.5rem;
}

.information-provide p{
    margin: 0rem 0rem;
    padding: 0rem 0.5rem;
}

.client-info {
    padding: 0rem 0.5rem;
  margin: 0.8rem 0rem;
}

.client-info span{
    color: #4a5568;
    padding: 0rem 0.5rem;   
}

.third-party p{
  color: #000000;
    width: 100vw;
    padding: 1rem 0.5rem;
}

.inventory {
    padding: 0rem 0.5rem;
    margin: 0rem 0rem 0.8rem;
}

.inventory span{
    padding: 0rem 0.5rem;
    color: #4a5568;
}


.payment-links p{
    color: #000000;
      width: 100vw;
      padding: 1rem 0.5rem;
  }
  
  .inventory-process{
      margin: 0rem 0rem 0.8rem;
      padding: 0rem 0.5rem;
  }
  
  .inventory-process span{
      padding: 0rem 0.5rem;
      color: #4a5568;
  }



  .book-demo p{
    color: #000000;
      width: 100vw;
      padding: 1rem 0.5rem;
  }
  
  .may-collect{
    padding: 0rem 0.5rem;
      margin: 0rem 0rem 0.8rem;
  }
  
  .may-collect span{
      padding: 0rem 0.5rem;
      color: #4a5568;
  }

  .your-rights{
    padding: 0rem 0.5rem;
    margin: 2rem 0rem;
  }

  .your-rights h3{
     font-size: 24px;
     color: #1c2045;
  }

  .request-access {
    display: flex;
    margin: 1.5rem 0rem;
  }

  .request-access span{
    color: #000000;
   padding: 0rem 0.5rem;
   width: 100vw;
  }

 .access-text{
    padding: 0rem 0.5rem;
    margin: 0rem 0rem;
 }

 .access-heading{
    padding: 0rem 0.5rem;
    margin: 1rem 0rem;
 }

 .your-customer{
    padding: 0rem 0.5rem;
 }

 .your-customer h3{
    font-size: 24px;
    color: #1c2045;
    margin: 2rem 0rem;
 }

 .request-dainy{
   margin: 1.5rem 0rem;
 }

 .contact-us{
    padding: 0rem 0.5rem;
    margin: 0rem 0rem;
 }

 .contact-us h3{
    font-size: 24px;
    color: #1c2045;
    padding: 1.5rem 0rem;
 }

 .contact-us p{
    color: #000000;
 }


 }


 @media(min-width:500px) and (max-width:768px){
    .privacy-policy{
        height: auto;
        width: 100%;
        background-color: #fff;
        margin-top: -4rem;
    }
    
    .purpose{
        margin: 5rem 0rem 0rem 0rem;
         padding: 0rem 0rem;
    }
    
    .maintains p{
       margin: 0rem 0rem;
       padding: 0rem 0.5rem;
    }
    
    .personal-info h1{
        font-size: 3rem;
        padding: 2rem 0rem;
        font-weight: 700;
        color: #1c2045;
       padding: 0rem 0.5rem;
    }
    
    .personal-info p{
       
        width: 100vw;
        font-size: 1.3rem;
        color: #000000;
         padding: 0rem 0.5rem;
    }
    
    .maintains p{
        width: 100vw;
        color: #000000;
        font-size: 1.3rem;
    }
    
    .what-information h3{
        margin: 2rem 0rem;
        padding: 0rem 0.5rem;
        color: #1c2045;
        font-size: 28px;
    }
    
    .our-website h3{
        margin: 2rem 0rem;
        padding: 0rem 0.5rem;
        font-size: 1.5rem;
        color: #1c2045;
    }
    
    .our-website p{
        margin: 0rem 0rem;
        padding: 0rem 0.5rem;
        color: #000000;
        width: 100vw;
        font-size: 1.3rem;
    }
    
    .varies-between p{
          width: 100vw;
          padding: 1rem 0.5rem;
          margin: 1rem 0rem 1.5rem;
          color: #000000;
          font-size: 1.3rem;
    }
    
    
    #client{
        font-size: 1.2rem;
        color: rgb(83, 235, 83);
        
    font-size: 1.3rem;
    }
    
    .client-data{
        padding: 0rem 0.5rem;
    margin: 0.8rem 0rem;
    font-size: 1.3rem;
     
    }
    
    .client-data span{
        color: #4a5568;
        padding: 0rem 0.5rem;
        font-size: 1.3rem;
    }
    
    .information-provide h3{
        margin:2rem 0rem;
        padding: 0rem 0.5rem;
        color: #1c2045;
        font-size: 1.5rem;
    }
    
    .information-provide p{
        margin: 0rem 0rem;
        padding: 0rem 0.5rem;
        font-size: 1.3rem;
    }
    
    .client-info {
        padding: 0rem 0.5rem;
      margin: 0.8rem 0rem;
    }
    
    .client-info span{
        color: #4a5568;
        padding: 0rem 0.5rem; 
        font-size: 1.3rem;  
    }
    
    .third-party p{
      color: #000000;
        width: 100vw;
        padding: 1rem 0.5rem;
        font-size: 1.3rem;
    }
    
    .inventory {
        padding: 0rem 0.5rem;
        margin: 0rem 0rem 0.8rem;
    }
    
    .inventory span{
        padding: 0rem 0.5rem;
        color: #4a5568;
        font-size: 1.3rem;
    }
    
    
    .payment-links p{
        color: #000000;
          width: 100vw;
          padding: 1rem 0.5rem;
          font-size: 1.3rem;
      }
      
      .inventory-process{
          margin: 0rem 0rem 0.8rem;
          padding: 0rem 0.5rem;
      }
      
      .inventory-process span{
          padding: 0rem 0.5rem;
          color: #4a5568;
          font-size: 1.3rem;
      }
    
    
    
      .book-demo p{
        color: #000000;
          width: 100vw;
          padding: 1rem 0.5rem;
          font-size: 1.3rem;
      }
      
      .may-collect{
        padding: 0rem 0.5rem;
          margin: 0rem 0rem 0.8rem;
      }
      
      .may-collect span{
          padding: 0rem 0.5rem;
          color: #4a5568;
          font-size: 1.3rem;
      }
    
      .your-rights{
        padding: 0rem 0.5rem;
        margin: 2rem 0rem;
      }
    
      .your-rights h3{
         font-size: 28px;
         color: #1c2045;
      }
    
      .request-access {
        display: flex;
        margin: 1.5rem 0rem;
      }
    
      .request-access span{
        color: #000000;
       padding: 0rem 0.5rem;
       width: 100vw;
       font-size: 1.3rem;
      }
    
     .access-text{
        padding: 0rem 0.5rem;
        margin: 0rem 0rem;
     }
    
     .access-heading{
        padding: 0rem 0.5rem;
        margin: 1rem 0rem;
     }
    
     .your-customer{
        padding: 0rem 0.5rem;
     }
    
     .your-customer h3{
        font-size: 28px;
        color: #1c2045;
        margin: 2rem 0rem;
     }
    
     .request-dainy{
       margin: 1.5rem 0rem;
       font-size: 1.3rem;
     }
    
     .contact-us{
        padding: 0rem 0.5rem;
        margin: 0rem 0rem;
     }
    
     .contact-us h3{
        font-size: 28px;
        color: #1c2045;
        padding: 1.5rem 0rem;
     }
    
     .contact-us p{
        color: #000000;
        font-size: 1.3rem;
     }
    
    
 }



 @media(min-width:769px) and (max-width:1200px){
    .privacy-policy{
        height: auto;
        width: 100%;
        background-color: #fff;
        margin-top: -4rem;
    }
    
    .purpose{
        margin: 5rem 0rem 0rem 0rem;
         padding: 0rem 0rem;
    }
    
    .maintains p{
       margin: 0rem 0rem;
       padding: 0rem 0.5rem;
    }
    
    .personal-info h1{
        font-size: 3.5rem;
        padding: 2rem 0rem;
        font-weight: 700;
        color: #1c2045;
       padding: 0rem 0.5rem;
    }
    
    .personal-info p{
       
        width: 100vw;
        font-size: 1.5rem;
        color: #000000;
         padding: 0rem 0.5rem;
    }
    
    .maintains p{
        width: 100vw;
        color: #000000;
        font-size: 1.5rem;
    }
    
    .what-information h3{
        margin: 2rem 0rem;
        padding: 0rem 0.5rem;
        color: #1c2045;
        font-size: 30px;
    }
    
    .our-website h3{
        margin: 2rem 0rem;
        padding: 0rem 0.5rem;
        font-size: 1.7rem;
        color: #1c2045;
    }
    
    .our-website p{
        margin: 0rem 0rem;
        padding: 0rem 0.5rem;
        color: #000000;
        width: 100vw;
        font-size: 1.5rem;
    }
    
    .varies-between p{
          width: 100vw;
          padding: 1rem 0.5rem;
          margin: 1rem 0rem 1.5rem;
          color: #000000;
          font-size: 1.5rem;
    }
    
    
    #client{
        font-size: 1.2rem;
        color: rgb(83, 235, 83);
        
    font-size: 1.5rem;
    }
    
    .client-data{
        padding: 0rem 0.5rem;
    margin: 0.8rem 0rem;
    font-size: 1.5rem;
     
    }
    
    .client-data span{
        color: #4a5568;
        padding: 0rem 0.5rem;
        font-size: 1.5rem;
    }
    
    .information-provide h3{
        margin:2rem 0rem;
        padding: 0rem 0.5rem;
        color: #1c2045;
        font-size: 1.5rem;
    }
    
    .information-provide p{
        margin: 0rem 0rem;
        padding: 0rem 0.5rem;
        font-size: 1.5rem;
    }
    
    .client-info {
        padding: 0rem 0.5rem;
      margin: 0.8rem 0rem;
    }
    
    .client-info span{
        color: #4a5568;
        padding: 0rem 0.5rem; 
        font-size: 1.5rem;  
    }
    
    .third-party p{
      color: #000000;
        width: 100vw;
        padding: 1rem 0.5rem;
        font-size: 1.5rem;
    }
    
    .inventory {
        padding: 0rem 0.5rem;
        margin: 0rem 0rem 0.8rem;
    }
    
    .inventory span{
        padding: 0rem 0.5rem;
        color: #4a5568;
        font-size: 1.5rem;
    }
    
    
    .payment-links p{
        color: #000000;
          width: 100vw;
          padding: 1rem 0.5rem;
          font-size: 1.5rem;
      }
      
      .inventory-process{
          margin: 0rem 0rem 0.8rem;
          padding: 0rem 0.5rem;
      }
      
      .inventory-process span{
          padding: 0rem 0.5rem;
          color: #4a5568;
          font-size: 1.5rem;
      }
    
    
    
      .book-demo p{
        color: #000000;
          width: 100vw;
          padding: 1rem 0.5rem;
          font-size: 1.5rem;
      }
      
      .may-collect{
        padding: 0rem 0.5rem;
          margin: 0rem 0rem 0.8rem;
      }
      
      .may-collect span{
          padding: 0rem 0.5rem;
          color: #4a5568;
          font-size: 1.5rem;
      }
    
      .your-rights{
        padding: 0rem 0.5rem;
        margin: 2rem 0rem;
      }
    
      .your-rights h3{
         font-size: 30px;
         color: #1c2045;
      }
    
      .request-access {
        display: flex;
        margin: 1.5rem 0rem;
      }
    
      .request-access span{
        color: #000000;
       padding: 0rem 0.5rem;
       width: 100vw;
       font-size: 1.5rem;
      }
    
     .access-text{
        padding: 0rem 0.5rem;
        margin: 0rem 0rem;
     }
    
     .access-heading{
        padding: 0rem 0.5rem;
        margin: 1rem 0rem;
     }
    
     .your-customer{
        padding: 0rem 0.5rem;
     }
    
     .your-customer h3{
        font-size: 30px;
        color: #1c2045;
        margin: 2rem 0rem;
     }
    
     .request-dainy{
       margin: 1.5rem 0rem;
       font-size: 1.5rem;
     }
    
     .contact-us{
        padding: 0rem 0.5rem;
        margin: 0rem 0rem;
     }
    
     .contact-us h3{
        font-size: 30px;
        color: #1c2045;
        padding: 1.5rem 0rem;
     }
    
     .contact-us p{
        color: #000000;
        font-size: 1.5rem;
     }
    
 }